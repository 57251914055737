import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IvrVoiceSelectComponent } from './ivr-voice-select.component';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppClickOutDirectiveModule } from '../../directive/clickoutside.directive';



@NgModule({
  declarations: [
    IvrVoiceSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    AppClickOutDirectiveModule
  ],
  exports : [IvrVoiceSelectComponent]
})
export class IvrVoiceSelectModule { }
