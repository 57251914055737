import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IContactCreateUpdate, IContactFieldCreate, IContactFieldsData, IContactFieldSort, IContactsResponse } from '../models/contacts.models';
import { ISearch } from '../models/search.models';
import { IVipBlackFromContacts } from '../models/vipblack.models';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(
        private http: HttpClient
    ) { }

    onGetContacts(search: ISearch) : Observable<IContactsResponse> {
        return this.http.post<IContactsResponse>(CommandURL.API_CONTACTS_GET_ALL, search).pipe(catchError(errorMgmt));
    }

    onCreateContacts(data: IContactCreateUpdate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_CONTACTS_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateContacts(data: any) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_CONTACTS_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteContacts(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_CONTACTS_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

    onGetViewContact(id: string) : Observable<any> {
        return this.http.get<any>(`${CommandURL.API_CONTACTS_VIEW_BYID}/${id}`).pipe(catchError(errorMgmt));
    }


    // Contact Field
    onGetAllContactFields() : Observable<IContactFieldsData[]> {
        return this.http.get<IContactFieldsData[]>(CommandURL.API_CONTACT_FIELD_GETALL).pipe(catchError(errorMgmt));
    }
    onGetDefaultContactFields() : Observable<IContactFieldsData[]> {
        return this.http.get<IContactFieldsData[]>(CommandURL.API_CONTACT_FIELD_DEFAULT).pipe(catchError(errorMgmt));
    }
    onGetDetailContactFields() : Observable<IContactFieldsData[]> {
        return this.http.get<IContactFieldsData[]>(CommandURL.API_CONTACT_FIELD_DETAIL).pipe(catchError(errorMgmt));
    }

    onOrderContactFields(data: IContactFieldSort[]) : Observable<any> {
        return this.http.post<any>(CommandURL.API_CONTACT_FIELD_ORDER, data).pipe(catchError(errorMgmt));
    }

    onCreateContactField(data: IContactFieldCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_CONTACT_FIELD_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateContactField(data: IContactFieldCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_CONTACT_FIELD_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteContactField(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_CONTACT_FIELD_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

}
