import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IResponseAgent } from '../models/agent.models';
import { Scripts } from '../models/file.models';
import { IIVR } from '../models/ivr.models';

declare var document: any;

@Injectable({
    providedIn: 'root'
})
export class EventService {
    public requestDataAny$ = new BehaviorSubject<any>(null);
    public requestDataTime$ = new BehaviorSubject<any>(null);
    public requestDataLanguage$ = new BehaviorSubject<any>(null);
    public requestDataModule$ = new BehaviorSubject<any>(null);
    public requestDataMenu$ = new BehaviorSubject<any>(null);
    public requestDataIVR$ = new BehaviorSubject<IIVR>({} as IIVR);
    public requestDataForm$ = new BehaviorSubject<any>(null);
    public requestDataAudio$ = new BehaviorSubject<any>(null);
    public requestDataScript$ = new BehaviorSubject<any>(null);
    public requestLogout$ = new BehaviorSubject<any>(null);
    public requestLogoutRouter$ = new BehaviorSubject<any>(null);
    public requestLogin$ = new BehaviorSubject<any>(null);
    public requestMenuTitle$ = new BehaviorSubject<any>(null);
    public requestMenuData$ = new BehaviorSubject<any>(null);
    public requestLoadLogin$ = new BehaviorSubject<any>(null);
    public requestMessageAccept$ = new BehaviorSubject<any>(null);
    private scripts: Scripts[] = [];

    constructor() {
    }


    loadScript(_script: Scripts) {
        return new Promise((resolve, reject) => {
            //resolve if already loaded
            if (_script.loaded) {
                resolve({script: _script.name, loaded: true, status: 'Already Loaded'});
            }
            else {
                //load script
                let scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.src = _script.src;
                if (scriptElement.readyState) {  //IE
                    scriptElement.onreadystatechange = () => {
                        if (scriptElement.readyState === "loaded" || scriptElement.readyState === "complete") {
                            scriptElement.onreadystatechange = null;
                            _script.loaded = true;
                            resolve({script: _script.name, loaded: true, status: 'Loaded'});
                        }
                    };
                } else {  //Others
                    scriptElement.onload = () => {
                        _script.loaded = true;
                        resolve({script: _script.name, loaded: true, status: 'Loaded'});
                    };
                }
                scriptElement.onerror = (error: any) => resolve({script: _script.name, loaded: false, status: 'Loaded'});
                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}
