import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthGuardDefault, AuthGuardPermission } from './shared/guards/auth.guard';
import { TokenRefreshResolve } from './shared/guards/token.resolve';
import { AgentRouteResolve } from './views/agent/agent-routing.resolve';
import { DefaultLayoutRouteResolve } from './views/default-layout/default-layout-routing.resolve';
import { SupervisorRouteResolve } from './views/supervisor/supervisor-routing.resolve';
import { OutboundOmsRouteResolve } from './views/outbound-oms/outbound-oms-routing.resolve';
import { KMSRouteResolve } from './views/kms/kms-routing.resolve';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        resolve: {
            action: DefaultLayoutRouteResolve
        },
        data: {
            permission: 'DASHBOARD'
        },
        loadChildren: () => import('./views/default-layout/default-layout.module').then(d => d.DefaultLayoutModule),
        canActivate: [AuthGuardDefault]
    },
    {
        path: 'agent',
        resolve: {
            action: AgentRouteResolve
        },
        data: {
            permission: 'AGENT'
        },
        loadChildren: () => import('./views/agent/agent.module').then(d => d.AgentModule),
        canActivate: [AuthGuardPermission]
    },
    {
        path: 'supervisor',
        resolve: {
            action: SupervisorRouteResolve
        },
        data: {
            permission: 'SUP'
        },
        loadChildren: () => import('./views/supervisor/supervisor.module').then(d => d.SupervisorModule),
        canActivate: [AuthGuardPermission]
    },
    {
        path: 'oms',
        resolve: {
            action: OutboundOmsRouteResolve
        },
        data: {
            permission: 'OB'
        },
        loadChildren: () => import('./views/outbound-oms/outbound-oms.module').then(d => d.OutboundOmsModule),
        canActivate: [AuthGuardPermission]
    },
    {
      path: 'mailling',
      resolve: {
          action: OutboundOmsRouteResolve
      },
      data: {
          permission: 'MAILLING_ALLOW'
      },
      loadChildren: () => import('./views/mailling/mailling.module').then(d => d.MaillingModule),
      canActivate: [AuthGuardPermission]
    },
    {
      path: 'kms',
      resolve: {
          action: KMSRouteResolve
      },
      data: {
          permission: 'KMS_ALLOW'
      },
      loadChildren: () => import('./views/kms/kms.module').then(d => d.KMSModule),
      canActivate: [AuthGuardPermission]
    },
    {
      path: 'oms-agent',
      resolve: {
          action: OutboundOmsRouteResolve
      },
      data: {
          permission: 'OMS_AGENTOMS_ALLOWOMS'
      },
      loadChildren: () => import('./views/outbound-oms-agent/outbound-oms-agent.module').then(d => d.OutboundOmsModule),
      canActivate: [AuthGuardPermission]
    },
    {
        path: 'config/callflow/:id',
        data: {
            permission: 'MANAGER'
        },
        resolve: {
            TokenRefreshResolve
        },
        loadChildren: () => import('./views/callflow/callflow.module').then(d => d.CallflowModule),
        canActivate: [AuthGuardPermission]
    },

    {
        path: 'config/callflow/:id/:edit',
        data: {
            permission: 'MANAGER'
        },
        resolve: {
            TokenRefreshResolve
        },
        loadChildren: () => import('./views/callflow/callflow.module').then(d => d.CallflowModule),
        canActivate: [AuthGuardPermission]
    },

    {
        path: 'login',
        loadChildren: () => import('./views/login/login.module').then(d => d.LoginModule)
    },
    {
        path: 'nhap-so-noi-bo',
        loadChildren: () => import('./views/sso-login/sso-login.module').then(d => d.SsoLoginModule)
    },
    {
        path: 'zalo-authorize',
        loadChildren: () => import('./views/zalo-authorize/zalo-authorize.module').then(d => d.ZaloAuthorizeModule)
    },
    {
        path: 'facebook-authorize',
        loadChildren: () => import('./views/facebook-authorize/facebook-authorize.module').then(d => d.FacebookAuthorizeModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./views/forgot-password/forgot-password.module').then(d => d.ForgotPasswordModule)
    },
    {
        path: 'error',
        loadChildren: () => import('./views/error/error.module').then(e => e.ErrorModule)
    },
    {
        path: 'thuan-hieu-test',
        loadChildren: () => import('./views/thuan-hieu-test/thuan-hieu-test-routing.module').then(d => d.ThuanHieuTestRoutingModule)
    },
    {
        path: '**',
        loadChildren: () => import('./views/error/error.module').then(e => e.ErrorModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
