<div class="card mb-0">
    <div class="card-header border-bottom pb-3 pt-3 d-flex justify-content-start align-items-center">
        <mat-icon class="material-icons-outlined m-r-10">information</mat-icon>
        <h6>{{data?.title}}</h6>
    </div>
    <div class="card-body p-3">
        <div class="text-center fs-20 mb-0" style="font-weight: 400; font-size: 15px;" [innerHTML]="data?.message || 'Are you sure?' | translate | HTMLSafe:'html'"></div>
    </div>
    <div class="card-footer pb-1 pt-1 pr-2 pl-2 d-flex justify-content-end align-items-center">
        <button class="m-10 btn btn-secondary" (click)="onCancel()">Đóng</button>
    </div>
</div>