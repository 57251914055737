import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuComponent } from './header-menu.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { HeaderMenuRightModule } from '../header-menu-right/header-menu-right.module';



@NgModule({
  declarations: [
    HeaderMenuComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    HeaderMenuRightModule,
    RouterModule
  ],
  exports: [
    HeaderMenuComponent
  ]
})
export class HeaderMenuModule { }
