import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IHotlineCreate, IResponseHotline } from '../models/hotline.models';
import { ISearch } from '../models/search.models';
import { ITagsCreateUpdate, ITagsData } from '../models/tags.models';
import { ContactSearchPhoneDataModel, ITicketFieldCreate, ITicketFieldData, ITicketFieldSort, SelectModel, TicketHistoryByPhoneModel } from '../models/ticket-field.models';
import { IContactByPhoneData, ICreateTicketTag, ITicketByIdReponse, ITicketCreateUp, ITicketResponse, UpdateTicketNewModel } from '../models/ticket.models';

@Injectable({
    providedIn: 'root'
})
export class TicketService {


    constructor(
        private http: HttpClient
    ) { }

    onGetAllTicket(search: ISearch) : Observable<ITicketResponse> {
        return this.http.post<ITicketResponse>(CommandURL.API_TICKET_GETALL, search).pipe(catchError(errorMgmt));
    }

    onGetAllField(): Observable<ITicketFieldData[]> {
        return this.http.get<ITicketFieldData[]>(CommandURL.API_TICKET_GETALL_FIELD).pipe(catchError(errorMgmt));
    }

    onGetTicketById(id: string) : Observable<ITicketByIdReponse>{
        return this.http.get<ITicketByIdReponse>(`${CommandURL.API_TICKET_GET_BYID}/${id}`).pipe(catchError(errorMgmt));
    }

    onGetAllTags(): Observable<ITagsData[]> {
        return this.http.get<ITagsData[]>(CommandURL.API_TICKET_TAG_ALL).pipe(catchError(errorMgmt));
    }
    GetTicketHistoryByPhone(phone: string) : Observable<TicketHistoryByPhoneModel[]> {
        return this.http.get<TicketHistoryByPhoneModel[]>(CommandURL.API_TICKET_GET_TICKET_HISTORY_BY_PHONE + `/${phone}`).pipe(catchError(errorMgmt));
    }
    onGetAllTicketTypes() : Observable<SelectModel[]> {
        return this.http.get<SelectModel[]>(CommandURL.API_TICKET_GET_ALL_TICKET_TYPE).pipe(catchError(errorMgmt));
    }
    onGetTicketCategory(code : string) : Observable<SelectModel[]> {
        return this.http.get<SelectModel[]>(CommandURL.API_TICKET_GET_CATEGORY + `/${code}`).pipe(catchError(errorMgmt));
    }
    onGetTicketCode() : Observable<any> {
        return this.http.get<any>(CommandURL.API_TICKET_GET_CODE).pipe(catchError(errorMgmt));
    }

    onCreateTicket(data: UpdateTicketNewModel) : Observable<any> {
        return this.http.post<any>(CommandURL.API_TICKET_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateTicket(data: any) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_TICKET_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteTicket(tId: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_TICKET_DELETE}/${tId}`).pipe(catchError(errorMgmt));
    }


    onDeleteTicketTag(tId: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_TICKET_DELETE_TAG}/${tId}`).pipe(catchError(errorMgmt));
    }

    onGetContactByPhone(phone: string) : Observable<[IContactByPhoneData[]]> {
        return this.http.get<[IContactByPhoneData[]]>(`${CommandURL.API_TICKET_GET_CONTACT_BYPHONE}?phone=${phone}`).pipe(catchError(errorMgmt));
    }
    onGetTicketContactByPhone(phone: string) : Observable<ContactSearchPhoneDataModel> {
        return this.http.get<ContactSearchPhoneDataModel>(`${CommandURL.API_TICKET_GET_TICKET_CONTACT_BYPHONE}?phone=${phone}`).pipe(catchError(errorMgmt));
    }
    onGetTicketByCallId(callId: string) : Observable<UpdateTicketNewModel> {
        return this.http.get<UpdateTicketNewModel>(`${CommandURL.API_TICKET_GET_TICKET_BY_CALLID}/${callId}`).pipe(catchError(errorMgmt));
    }
    onGetHandlerTag(tId: string, hand: boolean) : Observable<any> {
        return this.http.get<any>(`${CommandURL.API_TICKET_HANDLE_TAG}/${tId}/${hand}`).pipe(catchError(errorMgmt));
    }

    onCreateTicketTag(data: ICreateTicketTag) : Observable<any> {
        return this.http.post<any>(CommandURL.API_TICKET_CREATE_TAG, data).pipe(catchError(errorMgmt));
    }
}
