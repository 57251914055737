import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { ISearch } from '../models/search.models';
import { IVipblackCreate, IVipBlackFromContacts, IVipblackResponse } from '../models/vipblack.models';

@Injectable({
    providedIn: 'root'
})
export class VipBlackService {

    constructor(
        private http: HttpClient
    ) { }

    onGetAllVipblack(search: ISearch) : Observable<IVipblackResponse> {
        return this.http.post<IVipblackResponse>(CommandURL.API_VIPBL_GETALL, search).pipe(catchError(errorMgmt));
    }

    onCreateVipblack(data: IVipblackCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_VIPBL_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateVipblack(data: IVipblackCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_VIPBL_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteVipblack(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_VIPBL_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

    onSetVipBlackContact(data : IVipBlackFromContacts) : Observable<any> {
        return this.http.post<any>(`${CommandURL.API_CONTACTS_VIPBLACK}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onGetVipBlackContact(id: string) : Observable<any> {
        return this.http.get<any>(`${CommandURL.API_CONTACTS_GET_VIPBLACK}/${id}`).pipe(catchError(errorMgmt));
    }
    onSynchronizedVipblack() : Observable<any> {
      return this.http.delete<any>(`${CommandURL.API_VIPBL_SYNCHRONIZED}`).pipe(catchError(errorMgmt));
    }
}
