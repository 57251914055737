import { Injectable } from "@angular/core";
import { Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IFCMReceive, IFCMRequestBody, ITokenFCMRequest } from "../models/firebase.models";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { CommandURL } from "src/app/utils/constant";
import { catchError } from "rxjs/operators";
import { errorMgmt } from "src/app/utils/helpers";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { SessionChatOAInforModel } from "../models/zalo.models";
@Injectable({
    providedIn: 'root'
})
export class FcmService {

    public $requestFirebaseMessage: BehaviorSubject<IFCMReceive | any> = new BehaviorSubject<IFCMReceive | any>(null);
    public $requestFirebaseNotify = new BehaviorSubject<any>(null);
    public currentToken = "";
    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) { }

    onAccessToken(token: ITokenFCMRequest): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZALO_SET_OA_READYCHAT, token).pipe(catchError(errorMgmt));
    }
    onRemoveAccessToken(token: ITokenFCMRequest) :  Observable<any>{
        return this.http.post<any>(CommandURL.API_ZALO_SET_OA_PAUSECHAT,token).pipe(catchError(errorMgmt));
    }
    getAccountOAInfo() :  Observable<SessionChatOAInforModel[]>{
        return this.http.get<any>(CommandURL.API_ZALO_GET_ACCOUNT_OA_INFO).pipe(catchError(errorMgmt));
    }

    requestNotificationsPermissions(oaIds: any[]){
         Notification.requestPermission().then((permission) => {
             if (permission === 'granted') {
                 this.requestPermission(oaIds);
                 this.$requestFirebaseNotify.next(true);
             }else{
                this.$requestFirebaseNotify.next(false);
             }
         })
     }

    requestPermission(oaIds: any[])    {
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey }).then(
            (currentToken: string): void => {
                if (currentToken) {
                    let token = {
                        token: currentToken,
                        oaIds: oaIds,
                    } as ITokenFCMRequest;
                      this.onAccessToken(token).subscribe((res) => {
                      }, err => {
                          console.log('An error occurred add token. ', err);
                      })
                } else {
                  this.requestNotificationsPermissions(oaIds);
                }
            }).catch((err: any) => {
               this.requestNotificationsPermissions(oaIds);
            });
    }

        listen() {
        const messaging = getMessaging();
        onMessage(messaging, (payload: IFCMReceive | any) => {
                this.$requestFirebaseMessage.next(payload);
        });
    }

}

