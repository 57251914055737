import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { IConfirmModel, IErrorModel } from './dialog.model';

@Injectable()
export class DialogService {

    options: MatDialogConfig = {
        panelClass: ['dialog-animation', 'dialog-not-padding'],
        disableClose: true,
        maxWidth: '50vw',
        width: '500px',
    }

    constructor(
        private dialog: MatDialog
    ) { }

    error(data: IErrorModel, callback?: (result: boolean) => void) {
        const dialogRef = this.dialog.open(DialogErrorComponent, {
            data,
            ...this.options,
        });
        if (callback) {
            dialogRef.afterClosed().subscribe(callback);
        }
    }

    confirm(data: IConfirmModel, callback: (result: boolean) => void) {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            data,
            ...this.options,
        });
        dialogRef.afterClosed().subscribe(callback);
    }

    notice(data: IConfirmModel, callback: (result: boolean) => void) {
        const dialogRef = this.dialog.open(DialogNoticeComponent, {
            data,
            ...this.options,
            hasBackdrop: data.type ? true : false
        });
        dialogRef.afterClosed().subscribe(callback);
    }

    message(data: IConfirmModel) {
        this.dialog.open(DialogMessageComponent, {
            data,
            ...this.options,
            panelClass: ['liveToast', 'dialog-not-padding'],
            hasBackdrop: false
        });
    }

}
