import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CookieName, _Constants } from "src/app/utils/constant";
import { EventService } from "../services/event.service";
import { StorageService } from "../services/storage.service";

// default layout
@Injectable()
export class AuthGuardDefault implements CanActivate {
    constructor(
        private router: Router,
        private _event: EventService,
        private cookieService: StorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.cookieService.isLoggedin && this.cookieService.getCookie(CookieName._LOGIN_CONSET) && this.cookieService.get(CookieName._IS_LOGIN) && this.cookieService.getCookie(CookieName._ACCESS_TOKEN)) {
            const token = this.cookieService.getCookie(CookieName._ACCESS_TOKEN);
            if (!token) this._event.requestLogoutRouter$.next(true);

            let permissions = this.cookieService.userData?.permissions;
            if (permissions.length == 0) permissions.push("DASHBOARD_ALLOW");

            const _perData: string = route?.routeConfig?.data?.permission;
            if (!permissions.some(x => x.includes(_perData) || _perData.includes("DASHBOARD"))) {
                // this._event.requestLogoutRouter$.next(true);
                return false;
            }
            return true;
        }
        this._event.requestLogoutRouter$.next(true);
        return false;
    }

}

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private cookieService: StorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.cookieService.isLoggedin && this.cookieService.getCookie(CookieName._LOGIN_CONSET) && this.cookieService.get(CookieName._IS_LOGIN)) {
            const token = this.cookieService.accessToken ? this.cookieService.accessToken : this.cookieService.getCookie(CookieName._ACCESS_TOKEN);
            if (!token) this.router.navigate(['/login']);

            const _token = JSON.parse(atob(token.split('.')[1]));
            
            if (JSON.parse(_token.UserName).includes('admin'))
                return true;

            const perRoute: string[] = route.data && route.data?.permission as string[];
            
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

}

@Injectable()
export class AuthGuardPermission implements CanActivate {
    constructor(
        private router: Router,
        private _event: EventService,
        private cookieService: StorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.cookieService.isLoggedin && this.cookieService.getCookie(CookieName._LOGIN_CONSET) && this.cookieService.get(CookieName._IS_LOGIN)) {
            const token = this.cookieService.accessToken ? this.cookieService.accessToken : this.cookieService.getCookie(CookieName._ACCESS_TOKEN);
            if (!token) this._event.requestLogoutRouter$.next(true);

            let permissions: any[] = this.cookieService.userData?.permissions;
            if (permissions.length == 0) permissions.push("DASHBOARD_ALLOW");

            const perRoute: string = route.data && route.data?.permission as string;
            if (permissions.find((x: string) => x.includes(perRoute) || x.includes("DASHBOARD")))
                return true;
            
            this.router.navigate(['/404']);
            return false;
        }
        this._event.requestLogoutRouter$.next(true);
        return false;
    }

}
