import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BroadCastService {

    public $eventNewEmail: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
    ) { }

    onSendMessage(channel: string, type: string, message: any) {
        const broad = new BroadcastChannel(channel);
        broad.postMessage({ type: type, message: message });
    }

    onReceiptMessage(channel: string, callback: Function) {

        const broad = new BroadcastChannel(channel);
        broad.onmessage = (evt: any) => {
            broad.close();
            callback(evt.data);
        }
    }

    onClose(channel: string) {
        const broad = new BroadcastChannel(channel);
    }
}
