import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuRightComponent } from './header-menu-right.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ViewCallComponent } from './ViewCall/view-call.component';
import { CallPhoneComponent } from './CallPhone/call-phone.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [
    HeaderMenuRightComponent,
    ViewCallComponent,
    CallPhoneComponent,


  ],
  imports: [


    CommonModule,
    FormsModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    NgSelect2Module,

  ],
  exports: [
    HeaderMenuRightComponent
  ]
})
export class HeaderMenuRightModule { }
