import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CookieName } from "src/app/utils/constant";
import { StorageService } from "../services/storage.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private localStore: StorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if ([401, 403].includes(error.status)) {
                    this.localStore.deleteCookie(CookieName._IS_LOGIN);
                    this.localStore.deleteCookie(CookieName._ACCESS_TOKEN);
                    this.localStore.clear();
                    this.router.navigate(['/login']);
                }
                if ([429, 404].includes(error.status)) {
                    this.router.navigate(['/error/404']);
                }
                return throwError(error);
            }));
    }
}
