import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { EventService } from "src/app/shared/services/event.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { LoadListMenu, MenuItems } from "src/app/utils/menu";
import { onLoadScriptEvent } from "src/app/utils/scripts";
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from "@microsoft/signalr";
import { BaseURL, CookieName, _Constants } from "src/app/utils/constant";
import { SupervisorService } from "src/app/shared/services/supervisor.service";
import { IAuthApplicationConfig } from "src/app/shared/models/agent.models";
import { AuthService } from "src/app/shared/services/auth.service";

declare var $: any;

@Injectable({ providedIn: 'root' })
export class DefaultLayoutRouteResolve implements Resolve<Observable<any>> {

    listMenus: MenuItems[] | any = LoadListMenu();

    constructor(
        private cookieService: StorageService,
        private _event: EventService,
        private _sup: SupervisorService,
        private _auth: AuthService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> | any {
        localStorage.removeItem('page-wrapper');
        var permissions = this.cookieService.userData?.permissions;
        this._event.requestMenuData$.next([]);

        var agentZalo = permissions.includes('ZALO_AGENT_ALLOW') ? 'AGENT' : 'DISCONNECT';
        var agentSupZalo = permissions.includes('SUP_ZALO_AGENT_ALLOW') ? 'AGENT' : 'DISCONNECT';





        this._auth.ApplicationConfig().subscribe((dt) => {
            var _appConfig = dt as IAuthApplicationConfig;
            this.listMenus.map((r: MenuItems) => {
                if (r.name.toLowerCase().includes('ticket')) r.isUse = _appConfig.useTicket;
                return r;
            });
            var listMenus = this.listMenus.filter((x: MenuItems) => x.isUse && (permissions.some((p: any) => x.permission == p) || x.permission.includes('DASHBOARD') || x.permission.includes('DASHBOARD') || x.permission.includes(agentZalo) || x.permission.includes(agentSupZalo)));

            // lay menu bao cao theo quyen user dang nhap
            listMenus.forEach((c: MenuItems) => {
                if(c.permission === "REPORT_ALLOW"){
                    c.childrens = c.childrens.filter(child => child.isUse && permissions.some(p => child.permission === p));
                }
            });
            this._event.requestMenuData$.next(listMenus);
        });
    }

}
