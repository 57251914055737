import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { ICalendarCreate, ICalendarReponse } from '../models/calendar.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    constructor(
        private http: HttpClient
    ) { }

    onGetAllCalendar(search: ISearch): Observable<ICalendarReponse> {
        return this.http.post<ICalendarReponse>(CommandURL.API_CALENDAR_GETALL, search).pipe(catchError(errorMgmt));
    }

    onGetCalendarById(id: string) : Observable<ICalendarCreate> {
        return this.http.get<ICalendarCreate>(`${CommandURL.API_CALENDAR_GETBYID}/${id}`).pipe(catchError(errorMgmt));
    }

    onCreateCalendar(data: ICalendarCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_CALENDAR_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateCalendar(data: ICalendarCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_CALENDAR_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteCalendar(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_CALENDAR_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }
}
