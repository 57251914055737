import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subscriber, Subscription } from 'rxjs';
import { storeSchemaAccounts, storeSchemaActivityLogs, storeSchemaContacts } from './shared/models/database.models';
import { IEventMenuTitle } from './shared/models/file.models';
import { DialogService } from './shared/module/dialogs';
import { AgentService } from './shared/services/agent.service';
import { EventService } from './shared/services/event.service';
import { StorageService } from './shared/services/storage.service';
import { CookieName, _Constants } from './utils/constant';

declare var $: any;
declare var bootstrap: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    title = 'v9pbx';

    constructor(
        private _event: EventService,
        private _agent: AgentService,
        private storage: StorageService,
        private router: Router,
        private _dbService: NgxIndexedDBService,
        private _dialog: DialogService
    ) {
        this._event.requestLogout$.subscribe((evt) => {
            if (evt) {
                this._dialog.confirm({
                    title: 'Thông báo',
                    message: 'Bạn có chắc chắn muốn đăng xuất khỏi hệ thống ?'
                }, async (res) => {
                    if (res) {
                        await this._agent.onAgentLogout().toPromise();
                        await this.onClearCache();
                        this.router.navigate(['/login']);
                    }
                });
            }
        }, (err) => console.log('err', err));
        this._event.requestLogoutRouter$.subscribe(async (res) => {
            if (res) {
                await this._agent.onAgentLogout().toPromise();
                await this.onClearCache();
                this.router.navigate(['/login']);
            }
        })
        this.onCheckDatabaseExits();
    }

    onCheckDatabaseExits() {
        this._dbService.count(_Constants.database.accounts).subscribe((count) => {
        }, err => {
            if (err.includes('objectStore does not exists')) {
                this._dbService.createObjectStore(storeSchemaAccounts);
            }
        })
        this._dbService.count(_Constants.database.contacts).subscribe((count) => {
        }, err => {
            if (err.includes('objectStore does not exists')) {
                this._dbService.createObjectStore(storeSchemaContacts);
            }
        })
        this._dbService.count(_Constants.database.activityLogs).subscribe((count) => {
        }, err => {
            if (err.includes('objectStore does not exists')) {
                this._dbService.createObjectStore(storeSchemaActivityLogs);
            }
        })
    }


    async onClearCache() {
        this.storage.deleteCookie(CookieName._LOGIN_CONSET);
        this.storage.deleteCookie(CookieName._ACCESS_TOKEN);
        this.storage.remove(_Constants.storage.userData);
        this.storage.remove(_Constants.storage.extenData);
        this.storage.remove(CookieName._IS_LOGIN);
        this.storage.remove('isLoggedin');
        this.storage.remove('queueRules');
        // this.storage.clear();
        await this._dbService.clear(_Constants.database.contacts).toPromise();
        await this._dbService.clear(_Constants.database.accounts).toPromise();
        await this._dbService.clear(_Constants.database.activityLogs).toPromise();
    }
}
