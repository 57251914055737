import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IConfigSystem, IUpdateConfigSystem } from '../models/config.models';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(
        private http: HttpClient
    ) { }

    onGetConfigSystem() :Observable<IConfigSystem[]> {
        return this.http.get<IConfigSystem[]>(CommandURL.API_VOICE_SETTINGS).pipe(catchError(errorMgmt));
    }

    onGetSelectQueue() :Observable<any[]> {
        return this.http.get<any[]>(CommandURL.API_VOICE_SELECT_QUEUE).pipe(catchError(errorMgmt));
    }

    onUpdateConfigSystem(data: IUpdateConfigSystem) :Observable<any> {
        return this.http.post<any>(CommandURL.API_VOICE_UPDATE_SETTINGS, data).pipe(catchError(errorMgmt));
    }
}
