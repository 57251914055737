import { Injectable } from '@angular/core';
import { IQueueRules, IUserData } from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
    [x: string]: any;

    constructor() { }


    get userData() : IUserData {
        return this.get('userData') as IUserData;
    }
    get queueRules() : IQueueRules[] {
        return this.get('queueRules') as IQueueRules[];
    }
    get accessToken() {
        const userData: IUserData =  this.get('userData') as IUserData;
        return userData?.tokenData?.accesstoken;
    }

    get tokenData() {
        return this.get('TokenData').Accesstoken;
    }

    get userName() {
        return this.get('TokenData').Username;
    }

    get isLoggedin() {
        return this.get('isLoggedin');
    }

    setLogin() {
        this.set('isLoggedin', 'true');
    }

    set(key: string, value: any) {
        const typeOfValue = typeof value;
        if (['string', 'number', 'boolean'].includes(typeOfValue)) {
            localStorage.setItem(key, value);
        } else if (typeOfValue === 'object') {
            localStorage.setItem(key, JSON.stringify(value));
        }

    }

    get(key: string) {
        const value = localStorage.getItem(key);
        if (!value) {
            return null;
        }
        try {
            return JSON.parse(value);
        } catch (error) {
            return localStorage.getItem(key);
        }
    }

    clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    remove(key: string) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }


    getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    setCookie(name: string, value: any, expireDays: number, hours?: number, path: string = '') {
        let d:Date = new Date();
        hours = hours ? Number(hours) : 8;
        var time = (expireDays == 0) ? (d.getTime() + 1 * hours * 60 * 60 * 1000) : (d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${new Date(time)}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    deleteCookie(name: string) {
        this.setCookie(name, '', -5);
    }

    setSession(key: string, value: any) {
        const typeOfValue = typeof value;
        if (['string', 'number', 'boolean'].includes(typeOfValue)) {
            sessionStorage.setItem(key, value);
        } else if (typeOfValue === 'object') {
            sessionStorage.setItem(key, JSON.stringify(value));
        }

    }

    getSession(key: string) {
        const value = sessionStorage.getItem(key);
        if (!value) {
            return null;
        }
        try {
            return JSON.parse(value);
        } catch (error) {
            return sessionStorage.getItem(key);
        }
    }
}
