import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var bootstrap: any;
declare var $: any;

@Component({
    selector: 'app-dialog-message',
    templateUrl: './dialog-message.component.html',
    styleUrls: ['./dialog-message.component.css']
})
export class DialogMessageComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        var f = document.getElementById('messageToast');
        if (this.data.type == 'error') f?.classList.add('bg-danger');
        else f?.classList.add('bg-success');
        var a = new bootstrap.Toast(f);
        a.show();

        $(f).on('hidden.bs.toast', () => {
            this.dialogRef.close();
        })
    }

}
