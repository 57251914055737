import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { DatePickerComponent } from './date-picker.component';



@NgModule({
  declarations: [
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatListModule,
    MatButtonModule
  ],
  exports: [
    DatePickerComponent
  ]
})
export class DatePickerModule { }
