import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DialogModule } from './dialogs';
import { LoadingModule } from './loading/loading.module';
import { AppClickOutDirectiveModule, ClickOutsideDirective } from '../directive/clickoutside.directive';
import { TranslateModule } from '@ngx-translate/core';
import { IvrMenuActionModule } from './ivr-menu-action/ivr-menu-action.module';
import { IvrPlayVoiceModule } from './ivr-play-voice/ivr-play-voice.module';
import { IvrListActionMenuModule } from './ivr-list-action-menu/ivr-list-action-menu.module';
import { CustomSelectModule } from './custom-select/custom-select.module';
import { CustomTimePickerModule } from './custom-time-picker/custom-time-picker.module';
import { KeyActionMenuModule } from './key-action-menu/key-action-menu.module';
import { IvrVoiceSelectModule } from './ivr-voice-select/ivr-voice-select.module';
import { IvrCustomSelectModule } from './ivr-custom-select/ivr-custom-select.module';
import { NgSelect2Module } from 'ng-select2';
import { TypeVbPipe, V9DatePipe, V9DateStrPipe, V9DurationTimePipe, V9HourlyPipe, V9SafePipe, V9StrExtensionPipe } from 'src/app/utils/pipe';
import { HeaderMenuModule } from './header-menu/header-menu.module';
import { LoadingSkeletonModule } from './loading-skeleton/loading-skeleton.module';
import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OBDatetimePickerModule } from './ob-datetime-picker/ob-datetime-picker.module';
import { MonthYearPickerModule } from './month-year-picker/month-year-picker.module';
import { DatePickerModule } from './datet-picker/date-picker.module';


@NgModule({
    imports: [
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatInputModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatCardModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatTreeModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatProgressBarModule
    ],
    exports: [
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatInputModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatCardModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatTreeModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatProgressBarModule
    ],
    declarations: []
})
export class MaterialModule { }




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        LoadingModule,
        LoadingSkeletonModule,
        HeaderMenuModule,
        TranslateModule,
        DialogModule,
        IvrMenuActionModule,
        IvrPlayVoiceModule,
        IvrListActionMenuModule,
        CustomSelectModule,
        CustomTimePickerModule,
        KeyActionMenuModule,
        IvrCustomSelectModule,
        IvrVoiceSelectModule,
        AppClickOutDirectiveModule,
        NgSelect2Module,
        DatetimePickerModule,
        MonthYearPickerModule,
        OBDatetimePickerModule,
        MonthYearPickerModule,
        DragDropModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        LoadingModule,
        LoadingSkeletonModule,
        HeaderMenuModule,
        ClickOutsideDirective,
        TranslateModule,
        DialogModule,
        IvrMenuActionModule,
        IvrPlayVoiceModule,
        IvrListActionMenuModule,
        CustomSelectModule,
        CustomTimePickerModule,
        KeyActionMenuModule,
        IvrCustomSelectModule,
        IvrVoiceSelectModule,
        AppClickOutDirectiveModule,
        NgSelect2Module,
        TypeVbPipe,
        V9DatePipe,
        V9HourlyPipe,
        V9DateStrPipe,
        V9DurationTimePipe,
        V9StrExtensionPipe,
        DatetimePickerModule,
        DatePickerModule,
        MonthYearPickerModule,
        OBDatetimePickerModule,
        DragDropModule,
        MonthYearPickerModule,
        V9SafePipe
    ],
    declarations: [
        ClickOutsideDirective,
        TypeVbPipe,
        V9DateStrPipe,
        V9DatePipe,
        V9HourlyPipe,
        V9DurationTimePipe,
        V9StrExtensionPipe,
        V9SafePipe
    ]
})
export class DynamicModule { }
