import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { ISearch } from '../models/search.models';
import { ITicketProductivityResponse, ITicketTimeResponse, ITicketTotalResponse, ITicketTypeSelect } from '../models/ticket-report.models';

@Injectable({
    providedIn: 'root'
})
export class TicketReportService {
    
    constructor(
        private http: HttpClient
    ) { }

    onGetReportTotal(search: ISearch) : Observable<ITicketTotalResponse> {
        return this.http.post<ITicketTotalResponse>(CommandURL.API_TICKET_REPORT_TOTAL, search).pipe(catchError(errorMgmt));
    }

    onGetReportTime(search: ISearch) : Observable<ITicketTimeResponse> {
        return this.http.post<ITicketTimeResponse>(CommandURL.API_TICKET_REPORT_TIME, search).pipe(catchError(errorMgmt));
    }

    onGetReportProductivity(search: ISearch) : Observable<ITicketProductivityResponse> {
        return this.http.post<ITicketProductivityResponse>(CommandURL.API_TICKET_REPORT_PRODUCTIVITY, search).pipe(catchError(errorMgmt));
    }

    onGetTicketType() : Observable<ITicketTypeSelect[]> {
        return this.http.get<ITicketTypeSelect[]>(CommandURL.API_TICKET_REPORT_GET_TYPE).pipe(catchError(errorMgmt));
    }
}
