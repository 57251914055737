import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { IOBCreateQuestion, IOBQuestions, IOBScript, IOBUpdateScript } from "../models/ob-script.models";
import { ISearch } from "../models/search.models";
import { IOBCampaignModel, IOBCampainResponse } from "../models/ob-campaign.models";

@Injectable({
    providedIn: 'root'
})
export class OBCampaignService {

    constructor(
        private http: HttpClient
    ) { }

    onGetCampaign(search: ISearch): Observable<IOBCampainResponse> {
        return this.http.post<IOBCampainResponse>(CommandURL.API_OB_CAMPAIGN_GET, search).pipe(catchError(errorMgmt));
    }

    onCreateCampaign(data: IOBCampaignModel): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_CAMPAIGN_ADD, data).pipe(catchError(errorMgmt));
    }

    onUpdateCampaign(data: IOBCampaignModel): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_CAMPAIGN_UPDATE, data).pipe(catchError(errorMgmt));
    }

    onDeleteCampaign(campaignId: string): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_CAMPAIGN_DELETE}/${campaignId}`).pipe(catchError(errorMgmt));
    }


}