import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { ICreateRecord, IRecordReponse } from '../models/record.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class RecordService {

    constructor(
        private http: HttpClient
    ) { }

    onGetAllRecord(search: ISearch): Observable<IRecordReponse> {
        return this.http.post<IRecordReponse>(CommandURL.API_RECORD_GETALL, search).pipe(catchError(errorMgmt));
    }

    onCreateRecord(data: ICreateRecord): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('RealFileName', data.fileName);
        formData.append('FileName', data.fileName);
        formData.append('Content', data.content);
        formData.append('File', data.file);
        return this.http.post<any>(CommandURL.API_RECORD_CREATE, formData).pipe(catchError(errorMgmt));
    }

    onUpdateRecord(data: ICreateRecord): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('RealFileName', data.fileName);
        formData.append('FileName', data.fileName);
        formData.append('Content', data.content);
        formData.append('File', data.file);
        return this.http.put<any>(`${CommandURL.API_RECORD_UPDATE}/${data.id}`, formData).pipe(catchError(errorMgmt));
    }

    onDeleteRecord(id: string): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_RECORD_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

    onPlayObservable(id: string): Observable<Blob> {
        return this.http.get<Blob>(`${CommandURL.API_RECORD_LISTEN}/${id}`, {responseType: 'blob' as 'json'}).pipe(catchError(errorMgmt));
    }
}
