import { ObjectStoreMeta } from "ngx-indexed-db";
import { _Constants } from "src/app/utils/constant";

export const storeSchemaAccounts: ObjectStoreMeta = {
    store: _Constants.database.accounts,
    storeConfig: { keyPath: 'intId', autoIncrement: true },
    storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: false } },
        { name: 'userName', keypath: 'userName', options: { unique: false } },
        { name: 'fullName', keypath: 'fullName', options: { unique: false } },
        { name: 'phoneNumber', keypath: 'phoneNumber', options: { unique: false } },
        { name: 'online', keypath: 'online', options: { unique: false } },
        { name: 'isLogin', keypath: 'isLogin', options: { unique: false } },
        { name: 'isBusy', keypath: 'isBusy', options: { unique: false } }
    ]
};

export const storeSchemaContacts: ObjectStoreMeta = {
    store: _Constants.database.contacts,
    storeConfig: { keyPath: 'intId', autoIncrement: true },
    storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: false } },
        { name: 'fullName', keypath: 'fullName', options: { unique: false } },
        { name: 'mobilePhone', keypath: 'mobilePhone', options: { unique: false } },
        { name: 'facebook', keypath: 'facebook', options: { unique: false } },
        { name: 'zalo', keypath: 'zalo', options: { unique: false } },
        { name: 'email', keypath: 'email', options: { unique: false } }
    ]
};

export const storeSchemaActivityLogs: ObjectStoreMeta = {
    store: _Constants.database.activityLogs,
    storeConfig: { keyPath: 'intId', autoIncrement: true },
    storeSchema: [
        { name: 'extension', keypath: 'extension', options: { unique: false } },
        { name: 'queues', keypath: 'queues', options: { unique: false } },
        { name: 'startTime', keypath: 'startTime', options: { unique: false } },
        { name: 'startTimeStr', keypath: 'startTimeStr', options: { unique: false } },
        { name: 'statusCode', keypath: 'statusCode', options: { unique: false } },
        { name: 'statusCodeStr', keypath: 'statusCodeStr', options: { unique: false } }
    ]
};