<div class="p-3 position-absolute top-0 w-100 start-0 dmo">
    <div class="toast hide top-0 m-auto" role="alert" aria-live="assertive" aria-atomic="true" id="messageToast">
        <div class="toast-header d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <mat-icon class="m-r-10">information</mat-icon>
                <strong class="left">{{data?.title}}</strong>
            </div>
            <button class="btn-close" type="button" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body"><span [innerHTML]="data?.message || 'Are you sure?'"></span></div>
    </div>
</div>