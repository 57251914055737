export enum LANGUAGE {
    VI = 'vi',
    EN = 'en',
    DEFAULT = 'vi'
}

export enum TicketFromModule {
    None = 0,
    Call = 1,
    Chat = 2,
    Zalo = 3,
    Facebook = 4
}