import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IFileRecordResponse, IIVR, IIVRModel, IQueueData } from '../models/ivr.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class IvrService {

    constructor(
        private http: HttpClient
    ) { }

    onGetScript() :Observable<IIVRModel[]> {
        return this.http.get<IIVRModel[]>(CommandURL.API_IVR_GETALL).pipe(catchError(errorMgmt)); 
    }

    onGetScriptById(id: string) :Observable<IIVR> {
        return this.http.get<IIVR>(`${CommandURL.API_IVR_GET_BY_ID}/${id}`).pipe(catchError(errorMgmt)); 
    }

    onCreateScript(data: IIVR) :Observable<any> {
        return this.http.post<any>(CommandURL.API_IVR_CREATE, data).pipe(catchError(errorMgmt)); 
    }

    onUpdateScript(data: IIVR) :Observable<any> {
        return this.http.post<any>(CommandURL.API_IVR_UPDATE, data).pipe(catchError(errorMgmt)); 
    }

    onDeleteScript(ivrID: string) :Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_IVR_DELETE}/${ivrID}`).pipe(catchError(errorMgmt)); 
    }

    onGetRecordFile(search: ISearch) :Observable<IFileRecordResponse> {
        return this.http.post<IFileRecordResponse>(CommandURL.API_RECORD_GETALL, search).pipe(catchError(errorMgmt)); 
    }

    onGetQueueList(): Observable<IQueueData[]> {
        return this.http.get<IQueueData[]>(CommandURL.API_QUEUE_GETLIST).pipe(catchError(errorMgmt)); 
    }

    TestRabbitMQ(): Observable<any> {
        return this.http.get<any>(CommandURL.API_IVR_GET_TestRabbit).pipe(catchError(errorMgmt)); 
    }

    onGetIVRAction(type: string , ivrId: string): Observable<IIVR[]> {
        let queryStr: string = '';
        if (ivrId) {
            queryStr = `?currentIVRId=${ivrId}`;
        }
        return this.http.get<IIVR[]>(`${CommandURL.API_IVR_GET_BY_TYPE}/${type}${queryStr}`).pipe(catchError(errorMgmt)); 
    }
}
