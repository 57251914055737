export interface MenuItems {
    name: string | any,
    path: string | any,
    icon: string | any,
    color: string | any,
    childrens: MenuItems[],
    permission: string | any,
    active: string | any,
    isUse: boolean
}

export function LoadListMenu(): MenuItems[] {
    return [
        { name: 'Trang tổng quan', path: '/dashboard', icon: 'dashboard', color: '', childrens: [], permission: 'DASHBOARD_ALLOW', active: '', isUse: true },
        { name: 'Nhân viên', path: '/agent', icon: 'support_agent', color: '', childrens: [], permission: 'AGENT_ALLOW', active: '', isUse: true },
        { name: 'Giám sát', path: '/supervisor', icon: 'supervisor_account', color: '', childrens: [], permission: 'SUP_ALLOW', active: '', isUse: true },
        // {
        //     name: 'Ticket', path: 'javascript:;', icon: 'inventory', color: '',
        //     childrens: [
        //         { name: 'Danh sách', path: '/ticket/list', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //         { name: 'Danh bạ', path: '/ticket/contacts', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //         { name: 'Cấu hình', path: '/ticket/config', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //         {
        //             name: 'Báo cáo', path: 'javascript:;', icon: '', color: '', childrens: [
        //                 { name: 'Tổng hợp', path: '/ticket/report/total', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //                 { name: 'Năng suất', path: '/ticket/report/productivity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //                 { name: 'Thời gian xử lý', path: '/ticket/report/duedate', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
        //             ], permission: '', active: '', isUse: true
        //         },
        //     ],
        //     permission: 'TICKET_ALLOW', active: '', isUse: false
        // },
        {
            name: 'Báo cáo', path: '', icon: 'analytics', color: '',
            childrens: [
                { name: 'Tổng hợp cuộc gọi', path: '/report/calling/summarycall', icon: '', color: '', childrens: [], permission: 'REPORT_TOTAL', active: '', isUse: true },
                {
                    name: 'Nhân viên', path: 'javascript:;', icon: '', color: '', childrens: [
                        { name: 'Trạng thái', path: '/report/agent/status', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Chi tiết trạng thái', path: '/report/agent/statusdetail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Tổng quan', path: '/report/agent/general', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Thời gian làm việc', path: '/report/agent/productivitytime', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Lịch sử bưu tá', path: '/report/agent/productivity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Online muộn', path: '/report/agent/lateonline', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },

                    ], permission: 'REPORT_AGENT', active: '', isUse: true
                },
                {
                    name: 'Cuộc gọi', path: 'javascript:;', icon: '', color: '', childrens: [
                        { name: 'Chi tiết', path: '/report/calling/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Gọi rớt', path: '/report/calling/misscall', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        //{ name: 'Xử lý cuộc gọi rớt', path: '/report/calling/handlemisscalls', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Theo ngày', path: '/report/calling/byday', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        //{ name: 'Theo nhóm', path: '/report/calling/bygroup', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        //{ name: 'Theo múi giờ', path: '/report/calling/byhourly', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Rớt theo múi giờ', path: '/report/calling/misscallbyhour', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Theo đầu số', path: '/report/calling/byhotline', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Gọi ra theo giờ', path: '/report/calling/direction', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        //{ name: 'Tổng hợp cuộc gọi', path: '/report/calling/summarycall', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Tổng hợp cuộc gọi ra', path: '/report/calling/outbound', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Sản lượng cuộc gọi', path: '/report/calling/quantity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                    ], permission: 'REPORT_CALL_DETAIL', active: '', isUse: true
                },
                {
                    name: 'Máy lẻ', path: '', icon: '', color: '', childrens: [
                        { name: 'Chi tiết máy lẻ', path: '/report/extension/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        // { name: 'Tổng hợp máy lẻ', path: '/report/extension/total', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                        { name: 'Hiệu suất tài khoản thoại', path: '/report/calling/performance', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                    ], permission: 'REPORT_INTERNALNUMBER', active: '', isUse: true
                },
                 { name: 'Cuộc gọi nội bộ', path: '/report/pbx/details', icon: '', color: '', childrens: [], permission: 'REPORT_PBX_DETAIL', active: '', isUse: true },
                 { name: 'Dữ liệu', path: '/report/data/details', icon: '', color: '', childrens: [], permission: 'REPORT_DATA_DETAIL', active: '', isUse: true },
                //  {
                //   name: 'Email', path: 'javascript:;', icon: '', color: '', childrens: [
                //       { name: 'Chi tiết Email nhận', path: '/report/email/email-inbox', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //       { name: 'Chi tiết Email gửi', path: '/report/email/email-send', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //       { name: 'Thống kê theo ngày', path: '/report/email/detail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //       { name: 'Thống kê theo Agent', path: '/report/email/agent-detail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //   ], permission: 'REPORT_EMAIL', active: '', isUse: true
                // },
                { name: 'Nghe file ghi âm báo cáo cuộc gọi nội bộ', path: '', icon: '', color: '', childrens: [], permission: 'REPORT_RECORD_INTERNAL', active: '', isUse: true },
                { name: 'Nghe file ghi âm báo cáo cuộc gọi', path: '', icon: '', color: '', childrens: [], permission: 'REPORT_RECORD_CALL', active: '', isUse: true },
            //      { name: 'Zalo Tổng hợp', path: '/report/totalrate', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //      {
            //          name: 'Zalo Chat', path: 'javascript:;', icon: '', color: '', childrens: [
            //              //{ name: 'ZNS Consent', path: '/report/zalo', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'Chi tiết', path: '/report/zalochatdetails', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'Tổng hợp', path: '/report/zalochattotal', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //          ], permission: '', active: '', isUse: true
            //      },

            //      {
            //          name: 'Zalo ZNS', path: 'javascript:;', icon: '', color: '', childrens: [
            //              //{ name: 'ZNS Consent', path: '/report/zalo', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              //   { name: 'ZNS Booking', path: '/report/znsbooking', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'ZNS Vote', path: '/report/znsvote', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'Zns chi tiết', path: '/report/zns-dynamic', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'ZNS đánh giá', path: '/report/znsvote', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //              { name: 'ZNS dạng bảng', path: '/report/znstext', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //          ], permission: '', active: '', isUse: true
            //      },
            //      {
            //       name: 'Facebook Chat', path: 'javascript:;', icon: '', color: '', childrens: [
            //           //{ name: 'ZNS Consent', path: '/report/zalo', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //           { name: 'Chi tiết', path: '/report/facebookchatdetails', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //           { name: 'Tổng hợp', path: '/report/facebookchattotal', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //       ], permission: '', active: '', isUse: true
            //    },
            ], permission: 'REPORT_ALLOW', active: '', isUse: true
        },
        {
            name: 'Quản trị', path: '/manager', icon: 'rule_folder', color: '', childrens: [], permission: 'MANAGER_ALLOW', active: '', isUse: true
        },
        {
            name: 'Outbound OMS', path: '/oms', icon: 'point_of_sale', color: '', childrens: [], permission: 'OB_ALLOW', active: '', isUse: true
        },
        // {
        //     name: 'Outbound OMS', path: '/oms-agent', icon: 'point_of_sale', color: '', childrens: [], permission: 'OMSAGENT', active: '', isUse: true
        // },
        {
          name: 'Chiến dịch EMAIL', path: '/mailling', icon: 'email', color: '', childrens: [], permission: 'MAILLING_ALLOW', active: '', isUse: true
        },
        {
          name: 'Kho tri thức', path: '/kms', icon: 'warehouse', color: '', childrens: [], permission: 'KMS_ALLOW', active: '', isUse: true
        },
        {
          name: 'Ticket', path: '/ticket', icon: 'inventory', color: '',
          childrens: [
              { name: 'Danh sách', path: '/ticket/list', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
             // { name: 'Danh bạ', path: '/ticket/contacts', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
              { name: 'Cấu hình', path: '/ticket/config', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            //  { name: 'Báo cáo', path: '/ticket/report', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
          ], permission: 'TICKET_ALLOW', active: '', isUse: false
      },
        // {
        //   name: 'Ticket', path: '/ticket/contacts/list', icon: 'inventory', color: '', childrens: [], permission: 'TICKET_ALLOW', active: '', isUse: true
        // },
        // {
        //   name: 'Lấy dữ liệu', path: '/oms/datadownload', icon: 'file_download', color: '', childrens: [], permission: 'DOWLOAD_DATA_ALLOW', active: '', isUse: true
        // },
        // {
        //   name: 'Lấy dữ liệu', path: '/report/data/datadownload', icon: 'file_download', color: '', childrens: [], permission: 'DOWLOAD_DATA_ALLOW', active: '', isUse: true
        // },
        // {
        //   name: 'Báo cáo dữ liệu', path: '/report/data/datadownload', icon: 'dataset', color: '', childrens: [], permission: 'DOWLOAD_DATA_ALLOW', active: '', isUse: true
        // },


    ];
}

export function LoadListMenuAgentPage(): MenuItems[] {
    return [
        { name: 'Giám sát', path: '/supervisor', icon: 'supervisor_account', color: '', childrens: [], permission: 'SUP_ALLOW', active: '', isUse: true },
        { name: 'Outbound OMS', path: '/oms', icon: 'point_of_sale', color: '', childrens: [], permission: 'OB_ALLOW', active: '', isUse: true },
        // {
        //   name: 'Outbound OMS', path: '/oms-agent', icon: 'point_of_sale', color: '', childrens: [], permission: 'OMS_AGENTOMS_ALLOWOMS', active: '', isUse: true
        // },
        {
            name: 'Ticket', path: '/ticket', icon: 'inventory', color: '',
            childrens: [
                { name: 'Danh sách', path: '/ticket/list', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Danh bạ', path: '/ticket/contacts', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cấu hình', path: '/ticket/config', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo', path: '/ticket/report', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'TICKET_ALLOW', active: '', isUse: false
        },
        {
            name: 'Báo cáo', path: 'javascript:;', icon: 'analytics', color: '',
            childrens: [
                { name: 'Báo cáo tổng hợp', path: '/report/total', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Trạng thái nhân viên', path: '/report/agent/status', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Năng suất nhân viên', path: '/report/agent/productivity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Thời gian làm việc', path: '/report/agent/productivitytime', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Chi tiết cuộc gọi', path: '/report/calling/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi nhỡ', path: '/report/calling/misscall', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo ngày', path: '/report/calling/byday', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo múi giờ', path: '/report/calling/byhourly', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo đầu số', path: '/report/calling/byhotline', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //{ name: 'Báo cáo zaloZns Consent', path: '/report/zalo/znsdetail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                // { name: 'Báo cáo zaloZns Booking', path: '/report/znsbooking', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                // { name: 'Báo cáo zaloZns Vote', path: '/report/znsbooking', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns', path: '/report/zns-dynamic', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo gọi nội bộ', path: '/report/pbx/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo dữ liệu', path: '/report/data/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'REPORT_ALLOW', active: '', isUse: true
        },
        {
            name: 'Quản trị', path: '/manager/accounts', icon: 'rule_folder', color: '', childrens: [], permission: 'MANAGER_ALLOW', active: '', isUse: true
        },
    ];
}

export function LoadListMenuSupervisorPage(): MenuItems[] {
    return [
        { name: 'Nhân viên', path: '/agent', icon: 'support_agent', color: '', childrens: [], permission: 'AGENT_ALLOW', active: '', isUse: true },
        { name: 'Outbound OMS', path: '/oms', icon: 'point_of_sale', color: '', childrens: [], permission: 'OB_ALLOW', active: '', isUse: true },
        {
            name: 'Ticket', path: '/ticket', icon: 'inventory', color: '',
            childrens: [
                { name: 'Danh sách', path: '/ticket/list', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Danh bạ', path: '/ticket/contacts', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cấu hình', path: '/ticket/config', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo', path: '/ticket/report', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'TICKET_ALLOW', active: '', isUse: false
        },
        {
            name: 'Báo cáo', path: 'javascript:;', icon: 'analytics', color: '',
            childrens: [
                { name: 'Báo cáo tổng hợp', path: '/report/total', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Trạng thái nhân viên', path: '/report/agent/status', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Năng suất nhân viên', path: '/report/agent/productivity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Chi tiết cuộc gọi', path: '/report/calling/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi rớt', path: '/report/calling/misscall', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //{ name: 'Xử lý cuộc gọi rớt', path: '/report/calling/handlemisscalls', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo ngày', path: '/report/calling/byday', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo múi giờ', path: '/report/calling/byhourly', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo đầu số', path: '/report/calling/byhotline', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo gọi nội bộ', path: '/report/pbx/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //{ name: 'Báo cáo zaloZns Consent', path: '/report/zalo/znsdetail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                // { name: 'Báo cáo zaloZns Booking', path: '/report/zalo/znsbooking', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns chi tiết', path: '/report/zns-dynamic', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns đánh giá', path: '/report/zalo/znsvote', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns dạng bảng', path: '/report/zalo/znstext', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'REPORT_ALLOW', active: '', isUse: true
        },
        {
            name: 'Quản trị', path: '/manager/accounts', icon: 'rule_folder', color: '', childrens: [], permission: 'MANAGER_ALLOW', active: '', isUse: true
        },
    ];
}

export function LoadListMenuOMSPage(): MenuItems[] {
    return [
        { name: 'Nhân viên', path: '/agent', icon: 'support_agent', color: '', childrens: [], permission: 'AGENT_ALLOW', active: '', isUse: true },
        { name: 'Giám sát', path: '/supervisor', icon: 'supervisor_account', color: '', childrens: [], permission: 'SUP_ALLOW', active: '', isUse: true },
        {
            name: 'Ticket', path: '/ticket', icon: 'inventory', color: '',
            childrens: [
                { name: 'Danh sách', path: '/ticket/list', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Danh bạ', path: '/ticket/contacts', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cấu hình', path: '/ticket/config', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo', path: '/ticket/report', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'TICKET_ALLOW', active: '', isUse: false
        },
        {
            name: 'Báo cáo', path: 'javascript:;', icon: 'analytics', color: '',
            childrens: [
                { name: 'Báo cáo tổng hợp', path: '/report/total', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Trạng thái nhân viên', path: '/report/agent/status', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Năng suất nhân viên', path: '/report/agent/productivity', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Chi tiết cuộc gọi', path: '/report/calling/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi rớt', path: '/report/calling/misscall', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //{ name: 'Xử lý cuộc gọi rớt', path: '/report/calling/handlemisscalls', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo ngày', path: '/report/calling/byday', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo múi giờ', path: '/report/calling/byhourly', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Cuộc gọi theo đầu số', path: '/report/calling/byhotline', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo gọi nội bộ', path: '/report/pbx/details', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                //{ name: 'Báo cáo zaloZns Consent', path: '/report/zalo/znsdetail', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                // { name: 'Báo cáo zaloZns Booking', path: '/report/zalo/znsbooking', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns', path: '/report/zns-dynamic', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns đánh giá', path: '/report/zalo/znsvote', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns dạng bảng', path: '/report/zalo/znstext', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
                { name: 'Báo cáo zalo Zns thất bại', path: '/report/zalo/znserror', icon: '', color: '', childrens: [], permission: '', active: '', isUse: true },
            ], permission: 'REPORT_ALLOW', active: '', isUse: true
        },
        {
            name: 'Quản trị', path: '/manager/accounts', icon: 'rule_folder', color: '', childrens: [], permission: 'MANAGER_ALLOW', active: '', isUse: true
        },

    ];
}


export function LoadListMenuManager(): MenuItems[] {
    return [
        { name: 'Tài khoản', path: '/manager/accounts', icon: 'people', color: '', childrens: [], permission: 'ACCOUNT', active: '', isUse: true },
        { name: 'Bưu tá', path: '/manager', icon: 'people', color: '', childrens: [], permission: 'BUUTA', active: '', isUse: true },
        { name: 'Phân quyền', path: '/manager/permission', icon: 'admin_panel_settings', color: '', childrens: [], permission: 'PERMISSION', active: '', isUse: true },
        { name: 'Số nội bộ', path: '/manager/extension', icon: 'sip', color: '', childrens: [], permission: 'EXTENTION', active: '', isUse: true },
        { name: 'Yêu cầu chuyển đổi đơn vị', path: '/manager/requests-manager', icon: 'request_page', color: '', childrens: [], permission: 'REQUESTS_MANAGER', active: '', isUse: true },
        { name: 'Yêu cầu đóng mở', path: '/manager/requests-active', icon: 'lock_open', color: '', childrens: [], permission: 'REQUESTS_ACTIVE', active: '', isUse: true },
        // { name: 'Thiết lập chế độ ghi âm', path: '/manager/config-record-active', icon: 'sip', color: '', childrens: [], permission: 'EXTENTION', active: '', isUse: true },
        { name: 'Danh mục tổ chức', path: '/manager/category', icon: 'layers', color: '', childrens: [], permission: 'UNITCODE', active: '', isUse: true },
        { name: 'Nhóm điện thoại viên', path: '/manager/workgroup', icon: 'groups', color: '', childrens: [], permission: 'WORKGROUP', active: '', isUse: true },
        { name: 'Danh bạ', path: '/manager/contact-manager/list', icon: 'perm_contact_calendar', color: '', childrens: [], permission: 'CONTACT_MANAGER', active: '', isUse: true },

        //{ name: 'Nhóm phụ trách', path: '/manager/groupcharge', icon: 'speaker_group', color: '', childrens: [], permission: '', active: '', isUse: true },

        // { name: 'Phòng ban', path: '/manager/department', icon: 'source', color: '', childrens: [], permission: '', active: '', isUse: true},
        //{ name: 'IVR, Lịch làm việc', path: '/manager/ivr-calendar', icon: 'nat', color: '', childrens: [], permission: 'IVR_CELENDAR', active: '', isUse: true },
        //{ name: 'File thông báo', path: '/manager/record', icon: 'music_video', color: '', childrens: [], permission: 'RECORD', active: '', isUse: true },
        { name: 'Quản trị Đầu số', path: '/manager/hotline', icon: 'pin', color: '', childrens: [], permission: 'HOTLINE', active: '', isUse: true },
        // { name: 'Lịch làm việc', path: '/manager/calendar', icon: 'event_outlined', color: '', childrens: [], permission: '', active: '', isUse: true},
        //{ name: 'Ưu tiên, hạn chế', path: '/manager/limited-priority', icon: 'flaky_outlined', color: '', childrens: [], permission: '', active: '', isUse: true },
        // { name: 'Cấu hình hệ thống', path: '/manager/config-system', icon: 'settings', color: '', childrens: [], permission: '', active: '', isUse: true },
        //{ name: 'Nhóm Zalo', path: '/manager/group-zalo', icon: 'chat', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Câu hỏi zalo', path: '/manager/zalo-question', icon: 'question_mark', color: '', childrens: [], permission: 'HOTLINE', active: '', isUse: true },
        { name: 'Zalo OA', path: '/manager/zalo-oa', icon: 'model_training', color: '', childrens: [], permission: 'HOTLINE', active: '', isUse: true },
        { name: 'Chỉ định nhân viên cho OA', path: '/manager/assign-staff-oa', icon: 'people', color: '', childrens: [], permission: 'HOTLINE', active: '', isUse: true },
        //{ name: 'Cấu hình Zalo Zns', path: '/manager/zns-dynamic-config', icon: 'settings', color: '', childrens: [], permission: '', active: '', isUse: true },
       // { name: 'Cấu hình email', path: '/manager/email-configuration', icon: 'email', color: '', childrens: [], permission: 'EMAIL_CONF', active: '', isUse: true },
        //{ name: 'Câu hỏi facebook', path: '/manager/facebook-question', icon: 'question_mark', color: '', childrens: [], permission: '', active: '', isUse: true },
        //{ name: 'Cấu hình facebook', path: '/manager/facebook-page', icon: 'model_training', color: '', childrens: [], permission: '', active: '', isUse: true },
        //{ name: 'Chỉ định nhân viên cho Page', path: '/manager/assign-staff-page', icon: 'people', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Cấu hình chung', path: '/manager/line-spring', icon: 'settings', color: '', childrens: [], permission: 'LINE_SPRING', active: '', isUse: true },
       // { name: 'Cấu hình số di động', path: '/manager/phone-number', icon: 'smartphone  ', color: '', childrens: [], permission: 'PHONE_NUMBER', active: '', isUse: true },
        //{ name: 'Cấu hình gói cước', path: '/manager/packdata', icon: 'factory', color: '', childrens: [], permission: '', active: '', isUse: true },
        //{ name: 'Nạp tiền', path: '/manager/recharge', icon: 'money', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Định tuyến cuộc gọi', path: '/manager/routing-call-flow', icon: 'nat', color: '', childrens: [], permission: 'PHONE_NUMBER', active: '', isUse: true },
        { name: 'Cấu hình email gửi khi bận', path: '/manager/config-email-misscall', icon: 'send', color: '', childrens: [], permission: 'PHONE_NUMBER', active: '', isUse: true },

    ];
}

export function LoadListMenuOutBound(): MenuItems[] {
    return [
        { name: 'Gọi ra', path: '/oms/call', icon: 'phone_forwarded', color: '', childrens: [], permission: 'CALL_OUT', active: '', isUse: true },
        { name: 'Tự động gọi ra', path: '/oms/auto-call', icon: 'event_repeat', color: '', childrens: [], permission: 'AUTO_CALL', active: '', isUse: true },
        { name: 'File âm thanh', path: '/oms/record', icon: 'music_video', color: '', childrens: [], permission: 'AUTO_CALL', active: '', isUse: true },
        { name: 'Lịch sử gọi ra', path: '/oms/history', icon: 'history', color: '', childrens: [], permission: 'HISTORY', active: '', isUse: true },
        { name: 'Dữ liệu ĐTV', path: '/oms/getdata', icon: 'sim_card_download', color: '', childrens: [], permission: 'GETDATA', active: '', isUse: true },
        { name: 'Chiến dịch', path: '/oms/campaign', icon: 'campaign', color: '', childrens: [], permission: 'CAMPAIGN', active: '', isUse: true },
        { name: 'Dữ liệu', path: '/oms/storage', icon: 'storage', color: '', childrens: [], permission: 'STORAGE', active: '', isUse: true },
        { name: 'Nhóm gọi ra', path: '/oms/group', icon: 'interpreter_mode', color: '', childrens: [], permission: 'GROUP', active: '', isUse: true },
        //{ name: 'Cấp đơn', path: '/oms/contract-evalution', icon: 'send', color: '', childrens: [], permission: 'CONTRACT_EVALUTION', active: '', isUse: true },
    ];
}

export function LoadListMenuReportOutBound(): MenuItems[] {
    return [
        { name: 'Tổng hợp chiến dịch', path: '/oms/report/total-campain', icon: 'table_chart', color: '', childrens: [], permission: 'TOTAL_CAMPAIGN', active: '', isUse: true },
        { name: 'Theo nhóm phân công', path: '/oms/report/total-campain-follow-group', icon: 'bar_chart', color: '', childrens: [], permission: 'TOTAL_CAMPAIGN_FOLLOW_GROUP', active: '', isUse: true },
        { name: 'Tổng hợp agent', path: '/oms/report/total-agent', icon: 'groups', color: '', childrens: [], permission: 'TOTAL_AGENT', active: '', isUse: true },
        //{ name: 'Năng suất gọi của agent', path: '/oms/report/total-agent-call-productivity', icon: 'phonelink_ring', color: '', childrens: [], permission: 'TOTAL_AGENT_CALL_PRODUCTIVITY', active: '', isUse: true },
        { name: 'Báo cáo chiến dịch', path: '/oms/report/campaign-report', icon: 'campaign', color: '', childrens: [], permission: 'DETAIL', active: '', isUse: true },
        { name: 'Gọi ra', path: '/oms/report/details', icon: 'format_list_bulleted', color: '', childrens: [], permission: 'DETAIL', active: '', isUse: true },
        { name: 'Trạng thái', path: '/oms/report/status', icon: 'query_stats', color: '', childrens: [], permission: 'STATUS', active: '', isUse: true },
        //{ name: 'Kết quả CSKH theo chi nhánh', path: '/oms/report/result-customer-care', icon: 'pending_actions', color: '', childrens: [], permission: 'RESULT_CUSTOMER_CARE', active: '', isUse: true },
        //{ name: 'Báo cáo CSKH', path: '/oms/report/customer-care', icon: 'pending_actions', color: '', childrens: [], permission: 'CUSTOMER_CARE', active: '', isUse: true },
        { name: 'Kết quả khảo sát', path: '/oms/report/result', icon: 'pending', color: '', childrens: [], permission: 'RESULT', active: '', isUse: true },
        { name: 'Chi tiết dữ liệu', path: '/oms/report/datadetails', icon: 'summarize', color: '', childrens: [], permission: 'DATA_DETAIL_2', active: '', isUse: true },
        { name: 'Giám sát chiến dịch', path: '/oms/monitor', icon: 'monitor', color: '', childrens: [], permission: 'MONITOR', active: '', isUse: true },
    ];
}

export function LoadListMenuContactTicket(): MenuItems[] {
    return [
        { name: 'Danh sách', path: '/ticket/contacts/list', icon: 'contact_phone', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Import', path: '/ticket/contacts/import', icon: 'file_upload', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Cấu hình', path: '/ticket/contacts/config', icon: 'text_fields', color: '', childrens: [], permission: '', active: '', isUse: true },
    ];
}

export function LoadListMenuEmail(): MenuItems[] {
  return [
      { name: 'Hộp thư đến', path: 'inbox', icon: 'email', color: '', childrens: [], permission: '', active: '', isUse: true },
      { name: 'Đã gửi', path: 'send', icon: 'send', color: '', childrens: [], permission: '', active: '', isUse: true },
      { name: 'Đã xóa', path: 'delete', icon: 'delete', color: '', childrens: [], permission: '', active: '', isUse: true },
  ];
}

export function LoadListMenuConfigTicket(): MenuItems[] {
    return [
        { name: 'Trường thông tin', path: '/ticket/config/field', icon: 'text_fields', color: '', childrens: [], permission: '', active: '', isUse: true },
        { name: 'Thẻ tags', path: '/ticket/config/tags', icon: 'local_offer', color: '', childrens: [], permission: '', active: '', isUse: true },
    ];
}
