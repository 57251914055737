import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { DanhSachOA, GetListZNSRequestModel, ITokenRequest, IZaloConfig, IZaloConfigData, LayDanhSachCacHoiThoaiGanNhatDetailModel, LayDanhSachCacHoiThoaiGanNhatModel, LayDanhSachCacHoiThoaiVoiNguoiDungModel, QoataModel, QoataOfMonthModel, RMFollowerFromTagReqestModel, SendFileToUserIdModel, SendGifMessageToUserIdByAttachmentIdModel, SendImageMessageToUserIdByUrlModel, SendQoata, SendText, SessionChatOAInforModel, TestZalo, ZNSModel, Z_ChatDistributedModel, ZnsDynamicConfig, ZnsDynamicConfigReponse } from '../models/zalo.models';
import { ISearch } from '../models/search.models';
import { IReportDetailZNS, ReportVoteZNSObject, ResponseReportBookingZNS, ResponseReportDetailZNS, ResponseReportDetailZNSDynamic, ResponseReportTextZNS, ResponseReportVoteZNS } from '../models/zns.models';
import { ITokenFCMRequest } from '../models/firebase.models';

@Injectable({
    providedIn: 'root'
})
export class ZaloService {

    public $requestDataChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $CountZalo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $requestRabbitMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $requestGoogleNotify: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(
        private http: HttpClient
    ) { }
    onGetListOA(): Observable<DanhSachOA[]> {
        return this.http.get<DanhSachOA[]>(CommandURL.API_ZALO_CONFIG_GET_ALL_BY_USER).pipe(catchError(errorMgmt));
    }
    onGetAllConfigByQuestion(): Observable<IZaloConfigData[]> {
        return this.http.get<IZaloConfigData[]>(CommandURL.API_ZALO_CONFIG_GET_ALL_BY_QUESTION).pipe(catchError(errorMgmt));
    }
    onGetAllConfig(): Observable<IZaloConfigData[]> {
        return this.http.get<IZaloConfigData[]>(CommandURL.API_ZALO_CONFIG_GET_ALL).pipe(catchError(errorMgmt));
    }
    onAccessToken(token: ITokenFCMRequest): Observable<any> {
         return this.http.post<any>(CommandURL.API_ZALO_SET_OA_READYCHAT, token).pipe(catchError(errorMgmt));
     }
    onRemoveAccessToken(token: ITokenFCMRequest) :  Observable<any>{
        return this.http.post<any>(CommandURL.API_ZALO_SET_OA_PAUSECHAT,token).pipe(catchError(errorMgmt));
    }
    getAccountOAInfo() :  Observable<SessionChatOAInforModel[]>{
        return this.http.get<any>(CommandURL.API_ZALO_GET_ACCOUNT_OA_INFO).pipe(catchError(errorMgmt));
    }
    requestNotificationsPermissions(oaIds: any[]){
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                this.requestPermission(oaIds);
                this.$requestGoogleNotify.next(true);
            }else{
               this.$requestGoogleNotify.next(false);
            }
        })
    }
    requestPermission(oaIds: any[]){
            let token = {
                oaIds: oaIds,
            } as ITokenFCMRequest;
            this.onAccessToken(token).subscribe((res) => {

            }, err => {
                console.log('An error occurred add token. ', err);
            })
    }
    onGetAccessToken(data: ITokenRequest): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZALO_CONFIG_GET_ACCESS_TOKEN, data).pipe(catchError(errorMgmt));
    }
    onUpdateConfig(data: IZaloConfig): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZALO_CONFIG_UPDATE, data).pipe(catchError(errorMgmt));
    }
    onRemoveConfig(oAId: string): Observable<any> {
        return this.http.post<string>(`${CommandURL.API_ZALO_CONFIG_REMOVE}`, oAId).pipe(catchError(errorMgmt));
    }
    onRefreshTokenConfig(oAId: string): Observable<any> {
        return this.http.post<string>(`${CommandURL.API_ZALO_CONFIG_REFERESHTOKEN}`, oAId).pipe(catchError(errorMgmt));
    }
    onCreateConfig(data: IZaloConfig): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZALO_CONFIG_CREATE, data).pipe(catchError(errorMgmt));
    }
    onAssignStaffOA(data: IZaloConfig): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZALO_CONFIG_ASSIGNSTAFFOA, data).pipe(catchError(errorMgmt));
    }
    onGetCodeChallenge(code: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_ZALO_GET_CODE_CHALLENGE}/${code}`).pipe(catchError(errorMgmt));
    }

    onAsyncContacts(): Observable<any> {
        return this.http.get<any>(CommandURL.API_ZALO_ASYNC_CONTACT).pipe(catchError(errorMgmt));
    }

    onSendconsent(phone: string): Observable<any> {
        return this.http.put<any>(`${CommandURL.API_ZALO_SEND_CONSENT}/${phone}`, {}).pipe(catchError(errorMgmt));
    }

    onCheckConsent(zaloId: string): Observable<any> {
        return this.http.put<any>(`${CommandURL.API_ZALO_CHECK_CONSENT_BY_ID}/${zaloId}`, {}).pipe(catchError(errorMgmt));
    }
    onCheckConsentMobile(mobile: string): Observable<any> {
        return this.http.put<any>(`${CommandURL.API_ZALO_CHECK_CONSENT_BY_PHONE}/${mobile}`, {}).pipe(catchError(errorMgmt));
    }
    // Zalo chat
    onGetTagsOfOA(oAId: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_ZaloChat_TAGS_OF_OA}/${oAId}`).pipe(catchError(errorMgmt));
    }
    onTagFollower(tags : RMFollowerFromTagReqestModel): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_TAG_FOLLOWER}`,tags).pipe(catchError(errorMgmt));
    }
    onRMFollowerFromTag(tags : RMFollowerFromTagReqestModel): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_RM_FOLLOWER_FROM_TAG}`,tags).pipe(catchError(errorMgmt));
    }
    onRMTag(tags : RMFollowerFromTagReqestModel): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_RM_TAG}`,tags).pipe(catchError(errorMgmt));
    }
    onGetTagUser(userId : string): Observable<RMFollowerFromTagReqestModel> {
        return this.http.get<RMFollowerFromTagReqestModel>(`${CommandURL.API_ZaloChat_GET_TAG_USER}/${userId}`).pipe(catchError(errorMgmt));
    }
    onGetChat(search : ISearch): Observable<LayDanhSachCacHoiThoaiGanNhatModel> {
        return this.http.post<LayDanhSachCacHoiThoaiGanNhatModel>(CommandURL.API_ZaloChat_LAYDANHSACH_HOITHOAI_GANNHAT, search).pipe(catchError(errorMgmt));
    }
    onGetChatNew(search : ISearch): Observable<Z_ChatDistributedModel[]> {
        return this.http.post<Z_ChatDistributedModel[]>(CommandURL.API_ZaloChat_LAYDANHSACH_HOITHOAI_GANNHAT_NEW, search).pipe(catchError(errorMgmt));
    }
    onSeenChat(senderId: string): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_UPLOAD_SEEN_CHAT, senderId).pipe(catchError(errorMgmt));
    }
    GetListZNS(znsModel : GetListZNSRequestModel): Observable<ZNSModel[]> {
        return this.http.post<ZNSModel[]>(CommandURL.API_ZaloChat_GETLISTZNS, znsModel).pipe(catchError(errorMgmt));
    }
    onGetBoxChat(search : ISearch): Observable<LayDanhSachCacHoiThoaiVoiNguoiDungModel> {
        return this.http.post<LayDanhSachCacHoiThoaiVoiNguoiDungModel>(CommandURL.API_ZaloChat_LDS_HOITHOAI_VOI_NGUOIDUNG, search).pipe(catchError(errorMgmt));
    }
    SendText(sendtext : SendText): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_SENDTEXT, sendtext).pipe(catchError(errorMgmt));
    }
    SendImageUrl(sendImageUrl : SendImageMessageToUserIdByUrlModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_SENDIMAGEURL, sendImageUrl).pipe(catchError(errorMgmt));
    }

    UploadImageForOfficialAccountAPI(formdata : FormData): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_UPLOADIMAGE_FOR_OFFICIAL_ACCOUNTAPI, formdata).pipe(catchError(errorMgmt));
    }
    SendImageMessageToUserIdByAttachmentId(data : SendImageMessageToUserIdByUrlModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_SENDIMAGEATTACH, data).pipe(catchError(errorMgmt));
    }
    SendGifMessageToUserIdByAttachmentId(data : SendGifMessageToUserIdByAttachmentIdModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_SENDIMAGEGIF, data).pipe(catchError(errorMgmt));
    }
    SendFileToUserId(data : SendFileToUserIdModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_SENDFILE_TO_USERID, data).pipe(catchError(errorMgmt));
    }
    KiemTraTinNhanTuVan48h(sendtext: SendQoata): Observable<QoataModel> {
        return this.http.post<QoataModel>(CommandURL.API_ZaloChat_KIEMTRA_TINNHAN_TUVAN_48H, sendtext).pipe(catchError(errorMgmt));
    }

    KiemTraTinNhanTuVanMienPhi(OAId : string): Observable<QoataOfMonthModel> {
        return this.http.post<QoataOfMonthModel>(CommandURL.API_ZaloChat_KIEMTRA_TINNHAN_TUVAN_MIENPHI, OAId).pipe(catchError(errorMgmt));
    }
    LayTinHoiThoaiMoiNhatVoiNguoiDung(search : ISearch): Observable<LayDanhSachCacHoiThoaiVoiNguoiDungModel> {
        return this.http.post<LayDanhSachCacHoiThoaiVoiNguoiDungModel>(CommandURL.API_ZaloChat_LAY_TINNHAN_MOINHAT_VOI_NGUOIDUNG, search).pipe(catchError(errorMgmt));
    }
    UploadFileForOfficialAccountAPI(formdata : FormData): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_UPLOADFILE_FOR_OFFICIAL_ACCOUNTAPI, formdata).pipe(catchError(errorMgmt));
    }
    UploadGifForOfficialAccountAPI(formdata : FormData): Observable<any> {
        return this.http.post<any>(CommandURL.API_ZaloChat_UPLOADGIF_FOR_OFFICIAL_ACCOUNTAPI, formdata).pipe(catchError(errorMgmt));
    }
    // Zalo report
    onGetReportDetailZNS(search : ISearch): Observable<ResponseReportDetailZNS> {
        return this.http.post<ResponseReportDetailZNS>(CommandURL.API_ZALO_REPORT_ZNS_DETAIL, search).pipe(catchError(errorMgmt));
    }

    onGetReportDetailZNSExport(data: ISearch): Observable<any> {
        const httpOptions = {
            responseType: 'blob' as 'blob',
            observe: 'response' as 'response'
        };
        return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_DETAIL_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
    }

    onGetReportBookingZNS(search : ISearch): Observable<ResponseReportBookingZNS> {
      return this.http.post<ResponseReportBookingZNS>(CommandURL.API_ZALO_REPORT_ZNS_BOOKING, search).pipe(catchError(errorMgmt));
    }
    onGetReportBookingZNSExport(data: ISearch): Observable<any> {
      const httpOptions = {
          responseType: 'blob' as 'blob',
          observe: 'response' as 'response'
      };
      return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_BOOKING_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
  }
    onGetReportVoteZNS(search : ISearch): Observable<ResponseReportVoteZNS> {
      return this.http.post<ResponseReportVoteZNS>(CommandURL.API_ZALO_REPORT_ZNS_VOTE, search).pipe(catchError(errorMgmt));
    }
    onGetReportVoteZNSExport(data: ISearch): Observable<any> {
      const httpOptions = {
          responseType: 'blob' as 'blob',
          observe: 'response' as 'response'
      };
      return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_VOTE_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
   }
  onGetReportTextZNS(search : ISearch): Observable<ResponseReportTextZNS> {
    return this.http.post<ResponseReportTextZNS>(CommandURL.API_ZALO_REPORT_ZNS_TEXT, search).pipe(catchError(errorMgmt));
  }
  onGetReportTextZNSExport(data: ISearch): Observable<any> {
    const httpOptions = {
        responseType: 'blob' as 'blob',
        observe: 'response' as 'response'
    };
    return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_TEXT_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
   }
   onGetReportErrorZNS(search : ISearch): Observable<ResponseReportTextZNS> {
    return this.http.post<ResponseReportTextZNS>(CommandURL.API_ZALO_REPORT_ZNS_ERROR, search).pipe(catchError(errorMgmt));
  }
  onGetReportErrorZNSExport(data: ISearch): Observable<any> {
    const httpOptions = {
        responseType: 'blob' as 'blob',
        observe: 'response' as 'response'
    };
    return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_ERROR_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
   }
   // dynamic config zns
   onGetZnsDymanicConfig(search : ISearch): Observable<any> {
     return this.http.post<ZnsDynamicConfigReponse>(CommandURL.API_ZALO_ZNS_DYNAMIC_CONFIG_GETALL,search).pipe(catchError(errorMgmt));
   }
   onCreateZnsDymanicConfig(dynamicConfig: ZnsDynamicConfig): Observable<any> {
     return this.http.post<any>(CommandURL.API_ZALO_ZNS_DYNAMIC_CONFIG_CREATE,dynamicConfig).pipe(catchError(errorMgmt));
   }
   onUpdateZnsDymanicConfig(dynamicConfig: ZnsDynamicConfig): Observable<any> {
     return this.http.put<any>(CommandURL.API_ZALO_ZNS_DYNAMIC_CONFIG_UPDATE, dynamicConfig).pipe(catchError(errorMgmt));
   }
   onDeleteZnsDymanicConfig(Id: string): Observable<any> {
     return this.http.delete<any>(`${CommandURL.API_ZALO_ZNS_DYNAMIC_CONFIG_DELETE}/${Id}`).pipe(catchError(errorMgmt));
   }

   // dynamic zns
   onGetReportDetailZNSDynamic(search : ISearch): Observable<ResponseReportDetailZNSDynamic> {
        return this.http.post<ResponseReportDetailZNSDynamic>(CommandURL.API_ZALO_REPORT_ZNS_DYNAMIC_DETAIL, search).pipe(catchError(errorMgmt));
   }
   onGetReportDetailZNSDynamicExport(data: ISearch): Observable<any> {
    const httpOptions = {
        responseType: 'blob' as 'blob',
        observe: 'response' as 'response'
    };
    return this.http.post(CommandURL.API_ZALO_REPORT_ZNS_DYNAMIC_DETAIL_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
    }
    onTestWebhook(testZalo : TestZalo): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_TESTWEBHOOK}`,testZalo).pipe(catchError(errorMgmt));
    }
    onTestNotify(testZalo : TestZalo): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_TESTNOTIFY}`,testZalo).pipe(catchError(errorMgmt));
    }
    onTestNotifyZns(): Observable<any> {
        return this.http.post<any>(`${CommandURL.API_ZaloChat_TESTNOTIFY_ZNS}`,"").pipe(catchError(errorMgmt));
    }
}
