import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { ISearch } from "../models/search.models";
import { ISelect2Model } from "../models/select2-models";

@Injectable({
    providedIn: 'root'
})
export class OBCallService {

    public $eventOnCalling: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $eventCallbackCalling: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpClient
    ) { }

    onGetSelect2Campaign(): Observable<any> {
        return this.http.get<any>(CommandURL.API_OB_CALL_GET_SELECT2_CAMPAIGN).pipe(catchError(errorMgmt));
    }

    onGetSelect2ActiveCampaign(): Observable<ISelect2Model[]> {
        return this.http.get<ISelect2Model[]>(CommandURL.API_OB_CALL_GET_SELECT2_ACTIVE_CAMPAIGN).pipe(catchError(errorMgmt));
    }

    onGetSelect2ActiveCampaignAutoCall(): Observable<ISelect2Model[]> {
        return this.http.get<ISelect2Model[]>(CommandURL.API_OB_CALL_GET_SELECT2_ACTIVE_CAMPAIGN_AUTOCALL).pipe(catchError(errorMgmt));
    }

    onGetAgentCallHistoryUser(search: ISearch): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_CALL_HISTORY_AGENT, search).pipe(catchError(errorMgmt));
    }

    onGetOBScreen(campaignId: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_OB_CALL_GET_OB_SCREEN}/${campaignId}`).pipe(catchError(errorMgmt));
    }

    onGetNextCustomer(campaignId: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_OB_CALL_GET_NEXT_CUSTOMER}/${campaignId}`).pipe(catchError(errorMgmt));
    }

    onSaveOBScreen(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_CALL_GET_OB_SCREEN_SAVE, data).pipe(catchError(errorMgmt));
    }

    onGetCallHistory(search: ISearch): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_CALL_GET_HISTORY, search).pipe(catchError(errorMgmt));
    }

    onStartAutodial(code: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_OB_CALL_START_AUTODIAL}/${code}`).pipe(catchError(errorMgmt));
    }

    onCheckKeyPhone(Phone: string): Observable<any> {
      return this.http.get<any>(`${CommandURL.API_OB_CALL_GET_CHECK_KEYPHONE}/${Phone}`).pipe(catchError(errorMgmt));
  }

}
