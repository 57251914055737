import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IContactImportUpload, IContactsTempResponse } from '../models/contacts.models';
import { IHotlineCreate, IResponseHotline } from '../models/hotline.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class ImportService {

    
    constructor(
        private http: HttpClient
    ) { }
    
    onGetImportTemplate() : Observable<Blob> {
        return this.http.get<Blob>(CommandURL.API_CONTACTS_GET_TEMPALTE, { responseType : 'blob' as 'json'}).pipe(catchError(errorMgmt));
    }
    
    onGetContactColumns() : Observable<any> {
        return this.http.get<any>(CommandURL.API_CONTACTS_COLUMNS).pipe(catchError(errorMgmt));
    }
    
    onGetContactTemp(search: ISearch) : Observable<IContactsTempResponse> {
        return this.http.post<IContactsTempResponse>(CommandURL.API_CONTACTS_TEMP, search).pipe(catchError(errorMgmt));
    }
    
    onGetConfirmImport() : Observable<any> {
        return this.http.get<any>(CommandURL.API_CONTACTS_CONFIRM).pipe(catchError(errorMgmt));
    }

    onUploadFileImport(files: File) : Observable<IContactImportUpload> {
        let formData: FormData = new FormData();
        formData.append('file', files);
        return this.http.post<IContactImportUpload>(CommandURL.API_CONTACTS_UPLOAD, formData).pipe(catchError(errorMgmt));
    }
}
