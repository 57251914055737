import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IPermissionResponse, IRoleCreate, IRoleData } from '../models/role.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(
        private http: HttpClient
    ) { }

    onGetAllRoles() : Observable<IRoleData[]> {
        return this.http.get<IRoleData[]>(CommandURL.API_ROLE_GETALL).pipe(catchError(errorMgmt));
    }

    onGetAllPermissions() : Observable<IPermissionResponse[]> {
        return this.http.get<IPermissionResponse[]>(CommandURL.API_ROLE_GET_PERMISSION).pipe(catchError(errorMgmt));
    }

    onCreateRole(data: IRoleCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_ROLE_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateRole(data: IRoleCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_ROLE_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteRole(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_ROLE_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }
}
