import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CookieName } from "src/app/utils/constant";
import { StorageService } from "../services/storage.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private localService: StorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.localService.getCookie(CookieName._ACCESS_TOKEN) ? this.localService.getCookie(CookieName._ACCESS_TOKEN) : this.localService.accessToken;
        const userData = this.localService.userData;
        if (this.localService.isLoggedin && this.localService.getCookie(CookieName._LOGIN_CONSET) && this.localService.get(CookieName._IS_LOGIN)) {
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) });
        }
        // if (userData && userData.permissions.length > 0) {
        //     request = request.clone({ headers: request.headers.set('Permissions', `${JSON.stringify(userData.permissions)}`) });
        // }

        let ignore =
            typeof request.body === "undefined"
            || request.body === null
            || request.body.toString() === "[object FormData]";

        if (ignore) {
            return next.handle(request);
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        return next.handle(request);
    }

}
