import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { ISearch } from "../models/search.models";
import { ISelect2Model } from "../models/select2-models";
import { IOBGroupAssign } from "../models/ob-datasource.models";

@Injectable({
    providedIn: 'root'
})
export class OBGroupService {

    public requestEditGroup$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpClient
    ) { }

    onGetAllGroup(search: ISearch): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_GROUP_GETALL, search).pipe(catchError(errorMgmt));
    }

    onCreateGroup(data: any): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_GROUP_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateGroup(data: any): Observable<any[]> {
        return this.http.put<any[]>(`${CommandURL.API_OB_GROUP_UPDATE}/${data?.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteGroup(groupId: any): Observable<any[]> {
        return this.http.delete<any[]>(`${CommandURL.API_OB_GROUP_DELETE}/${groupId}`).pipe(catchError(errorMgmt));
    }

    onGetAgentInGroup(groupId: any): Observable<any[]> {
        return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_GETAGENT}/${groupId}`).pipe(catchError(errorMgmt));
    }

    onGetAgentInGroupToAssignOrBack(campaignId: string, groupId: string): Observable<any[]> {
        return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_GETAGENT_ASSIGN_BACK}/${campaignId}/${groupId}`).pipe(catchError(errorMgmt));
    }


    onGetAllAgentData(campaignId: string): Observable<any[]> {
      return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_GETAGENT_DATA}/${campaignId}`).pipe(catchError(errorMgmt));
    }

    onBackDataToGroup(campaignId: string, agent: string): Observable<any[]> {
        return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_BACKDATA}/${campaignId}/${agent}`).pipe(catchError(errorMgmt));
    }

    onBackDataToCampaign(campaignId: string, groupId: string): Observable<any[]> {
        return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_BACKDATA_CAMPAIGN}/${campaignId}/${groupId}`).pipe(catchError(errorMgmt));
    }

    onGetGroupToAssign(): Observable<any[]> {
        return this.http.get<any[]>(CommandURL.API_OB_DATASOURCE_GET_GROUP_ASSIGN).pipe(catchError(errorMgmt));
    }

    onActionAssignToGroup(data: any): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_DATASOURCE_ASSIGN_GROUP, data).pipe(catchError(errorMgmt));
    }

    onActionAssignToAgent(data: any): Observable<any[]> {
        return this.http.post<any[]>(CommandURL.API_OB_DATASOURCE_ASSIGN_AGENT, data).pipe(catchError(errorMgmt));
    }

    onAllAssignToAgent(campaignId: string): Observable<IOBGroupAssign[]> {
        return this.http.get<IOBGroupAssign[]>(`${CommandURL.API_OB_DATASOURCE_GET_ASSIGN_TO_AGENT}?campaignId=${campaignId}`).pipe(catchError(errorMgmt));
    }

    onGetGroupInCampaign(campaignId: string): Observable<any[]> {
        return this.http.get<any[]>(`${CommandURL.API_OB_GROUP_GET_IN_CAMPAIGN}/${campaignId}`).pipe(catchError(errorMgmt));
    }

}
