import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { ISearch } from "../models/search.models";
import { ISelect2Model } from "../models/select2-models";
import { IAddDataModel, IColumnsDataReponse, IGetAnswerModel, IOBDSResponse, IOBDataSourceModel, IReportCampaign, IReportSurveyResults, IResponseColumnsImport, IResponseCustomersBulk, IResponseDataMap } from "../models/ob-datasource.models";

@Injectable({
    providedIn: 'root'
})
export class OBDataSourceService {

    constructor(
        private http: HttpClient
    ) { }

    onUploadFileCustomer(campaignId: string, files: File): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('files', files);
        return this.http.post<any>(`${CommandURL.API_OB_DATASOURCE_UPLOAD}?campaignId=${campaignId}`, formData, { reportProgress: true, observe: 'events' }).pipe(catchError(errorMgmt));
    }

    onDownloadloadFileCustomer(campaignId: string): Observable<any> {
      return this.http.post<any>(`${CommandURL.API_OB_DATASOURCE_DOWNLOAD}?campaignId=${campaignId}`,  { reportProgress: true, observe: 'events' }).pipe(catchError(errorMgmt));
  }

    onGetColumnsFileImport(campaignId: string): Observable<IResponseColumnsImport> {
        return this.http.get<IResponseColumnsImport>(`${CommandURL.API_OB_DATASOURCE_GET_COLUMNS}/${campaignId}`).pipe(catchError(errorMgmt));
    }

    onGetAllCustomersBulk(search: ISearch): Observable<IResponseCustomersBulk> {
        return this.http.post<IResponseCustomersBulk>(CommandURL.API_OB_DATASOURCE_GET_BULK, search).pipe(catchError(errorMgmt));
    }

    onCreateDataSource(data: IOBDataSourceModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_DATASOURCE_CREATE, data).pipe(catchError(errorMgmt));
    }

    onConfirmImportDataSource(data: IOBDataSourceModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_DATASOURCE_CONFIRM_IMPORT, data).pipe(catchError(errorMgmt));
    }

    onGetDataSourceFromCampaign(campaignId: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_OB_DATASOURCE_GET}/${campaignId}`).pipe(catchError(errorMgmt));
    }

    onGetAllDataSource(search: ISearch): Observable<IOBDSResponse[]> {
        return this.http.post<IOBDSResponse[]>(CommandURL.API_OB_DATASOURCE_GETALL, search).pipe(catchError(errorMgmt));
    }

    onGetAllArea(): Observable<any[]> {
        return this.http.get<any[]>(CommandURL.API_OB_DATASOURCE_GET_AREA).pipe(catchError(errorMgmt));
    }

  //   GetColumnsData(nameColumns: string): Observable<IColumnsDataReponse> {
  //     //return this.http.post<IColumnsDataReponse>(CommandURL.API_OB_DATASOURCE_COLUMNSDATA, null).pipe(catchError(errorMgmt));
  //     return this.http.post<IColumnsDataReponse>(`${CommandURL.API_OB_DATASOURCE_COLUMNSDATA}?nameColumns=${nameColumns}`,  { reportProgress: true, observe: 'events' }).pipe(catchError(errorMgmt));
  // }
  GetColumnsData(campaignId: string): Observable<IColumnsDataReponse> {
    return this.http.get<IColumnsDataReponse>(`${CommandURL.API_OB_DATASOURCE_COLUMNSDATA}/${campaignId}`).pipe(catchError(errorMgmt));
  }


  CheckNameData(campaignId: string): Observable<IColumnsDataReponse> {
    return this.http.get<IColumnsDataReponse>(`${CommandURL.API_OB_DATASOURCE_CHECKTABLE}/${campaignId}`).pipe(catchError(errorMgmt));
  }

  AddData(data: IAddDataModel): Observable<any> {
    return this.http.post<any>(CommandURL.API_OB_DATASOURCE_ADDDATA, data).pipe(catchError(errorMgmt));
}

GetAllDataMap(search: ISearch): Observable<IResponseDataMap> {
  return this.http.post<IResponseDataMap>(CommandURL.API_OB_DATASOURCE_GET_DATAMAP, search).pipe(catchError(errorMgmt));
}

onConfirmDownLoadDataSource(data: IOBDataSourceModel): Observable<any> {
  return this.http.post<any>(CommandURL.API_OB_DATASOURCE_CONFIRM_DOWLOAD, data).pipe(catchError(errorMgmt));
}

onGetReportDataMap(search: ISearch): Observable<IResponseCustomersBulk> {
  return this.http.post<IResponseCustomersBulk>(CommandURL.API_OB_DATASOURCE_GET_REPORTDATA, search).pipe(catchError(errorMgmt));
}
onGetReportDataMapAgent(search: ISearch): Observable<IResponseCustomersBulk> {
  return this.http.post<IResponseCustomersBulk>(CommandURL.API_OB_DATASOURCE_GET_REPORTDATA_AGENT, search).pipe(catchError(errorMgmt));
}
onGetReportSurveyResultsp(search: ISearch): Observable<IReportSurveyResults> {
  return this.http.post<IReportSurveyResults>(CommandURL.API_OB_DATASOURCE_GET_REPORTSURVEYRESULTS, search).pipe(catchError(errorMgmt));
}

onGetReportSurveyResultspExport(data: ISearch): Observable<any> {
  const httpOptions = {
      responseType: 'blob' as 'blob',
      observe: 'response' as 'response'
  };
  return this.http.post(CommandURL.API_OB_DATASOURCE_GET_REPORTSURVEYRESULTS_EXPORT, data, httpOptions).pipe(catchError(errorMgmt));
}


onGetAnswers(campaignId: string): Observable<IGetAnswerModel> {
  return this.http.get<IGetAnswerModel>(`${CommandURL.API_OB_DATASOURCE_GET_ANSWERS}/${campaignId}`).pipe(catchError(errorMgmt));
}



onGetReportCampaignsp(search: ISearch): Observable<IReportCampaign> {
  return this.http.post<IReportCampaign>(CommandURL.API_OB_DATASOURCE_GETREPORTCAMPAIGNS, search).pipe(catchError(errorMgmt));
}


}
