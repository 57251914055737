import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IHotlineCreate, IResponseHotline } from '../models/hotline.models';
import { ISearch } from '../models/search.models';
import { ITagsCreateUpdate, ITagsData } from '../models/tags.models';
import { ITicketFieldCreate, ITicketFieldData, ITicketFieldSort } from '../models/ticket-field.models';

@Injectable({
    providedIn: 'root'
})
export class TicketFieldService {

    
    constructor(
        private http: HttpClient
    ) { }
    
    onGetAllTicketField() : Observable<ITicketFieldData[]> {
        return this.http.get<ITicketFieldData[]>(CommandURL.API_TICKET_FIELD_GETALL).pipe(catchError(errorMgmt));
    }

    onCreateTicketField(data: ITicketFieldCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_TICKET_FIELD_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateTicketField(data: ITicketFieldCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_TICKET_FIELD_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteTicketField(tId: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_TICKET_FIELD_DELETE}/${tId}`).pipe(catchError(errorMgmt));
    }

    onOrderTicketFields(data: ITicketFieldSort[]) : Observable<any> {
        return this.http.post<any>(CommandURL.API_TICKET_FIELD_ORDER, data).pipe(catchError(errorMgmt));
    }
}
