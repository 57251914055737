import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CookieName } from "src/app/utils/constant";
import { onConsoleLog } from "src/app/utils/helpers";
import { IRequestTokenRf, ITokenRefresh } from "../models/auth.models";
import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";

@Injectable({providedIn: 'root'})
export class TokenRefreshResolve implements Resolve<Observable<any>> {

    constructor(
        private _storage: StorageService,
        private _auth: AuthService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> | any {
        onConsoleLog('Refresh Token', '....');
        if (this._storage.getCookie(CookieName._ACCESS_TOKEN) && this._storage.userData) {
            const accessToken = this._storage.getCookie(CookieName._ACCESS_TOKEN);
            const userData = this._storage.userData;
            let data: IRequestTokenRf = {
                userName: userData.userName,
                oldToken: accessToken
            } as IRequestTokenRf;
            this._auth.RequestTokenRefres(data).subscribe((res: ITokenRefresh) => {
                let now = new Date();
                let expired = new Date(res.expiredAt);
                var offset = expired.getTime() - now.getTime();
                var hours = Math.floor(offset / 1000 / 60 / 60);
                var days = Math.floor(offset / 1000 / 60 / 60 / 24);
                this._storage.setCookie(CookieName._ACCESS_TOKEN, res.accesstoken, days, hours);
                this._storage.setSession(CookieName._ACCESS_TOKEN, res.accesstoken);
            });
        }
    }
}