import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { ISearch } from "../models/search.models";
import { IOBTemplate, IOBTemplateFieldCreate, IOBTemplateModal, IReponseOBTemplateField } from "../models/ob-template.models";

@Injectable({
    providedIn: 'root'
})
export class OBTemplateService {

    constructor(
        private http: HttpClient
    ) { }

    onGetTemplate(): Observable<IOBTemplate[]> {
        return this.http.get<IOBTemplate[]>(CommandURL.API_OB_TEMPLATE_GET).pipe(catchError(errorMgmt));
    }

    onCreateTemplate(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_TEMPLATE_ADD, data).pipe(catchError(errorMgmt));
    }

    onUpdateTemplate(data: IOBTemplate): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_TEMPLATE_UPDATE, data).pipe(catchError(errorMgmt));
    }

    onDeleteTemplate(tempId: any): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_TEMPLATE_DELETE}/${tempId}`).pipe(catchError(errorMgmt));
    }

    onGetAllTemplateField(search: ISearch): Observable<IReponseOBTemplateField> {
        return this.http.post<IReponseOBTemplateField>(CommandURL.API_OB_TEMPLATE_GET_FIELD, search).pipe(catchError(errorMgmt));
    }

    onCopyTemplateField(data: IOBTemplateFieldCreate): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_TEMPLATE_COPY, data).pipe(catchError(errorMgmt));
    }

    onCreateTemplateField(data: IOBTemplateFieldCreate): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_TEMPLATE_CREATE_FIELD, data).pipe(catchError(errorMgmt));
    }

    onUpdateTemplateField(data: IOBTemplateFieldCreate): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_TEMPLATE_UPDATE_FIELD, data).pipe(catchError(errorMgmt));
    }

    onDeleteTemplateField(fieldId: string): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_TEMPLATE_DELETE_FIELD}/${fieldId}`).pipe(catchError(errorMgmt));
    }

    onGetTemplateImportData(campaignId: string): Observable<Blob> {
        return this.http.get<Blob>(`${CommandURL.API_OB_TEMPLATE_GET_IMPORT_DATA}/${campaignId}`, { responseType: 'blob' as 'json' }).pipe(catchError(errorMgmt));
    }
}