import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { IOBCreateQuestion, IOBQuestions, IOBScript, IOBUpdateScript } from "../models/ob-script.models";

@Injectable({
    providedIn: 'root'
})
export class OBScriptService {

    constructor(
        private http: HttpClient
    ) { }

    onGetScript(search: string): Observable<IOBScript[]> {
        return this.http.post<IOBScript[]>(CommandURL.API_OB_SCRIPT_GET, search).pipe(catchError(errorMgmt));
    }

    onGetQuestion(scriptId: string): Observable<IOBQuestions[]> {
        return this.http.get<IOBQuestions[]>(`${CommandURL.API_OB_SCRIPT_GET_QUESTION}/${scriptId}`).pipe(catchError(errorMgmt));
    }

    onCreateScript(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_SCRIPT_ADD, data).pipe(catchError(errorMgmt));
    }

    onCreateQuestion(data: IOBCreateQuestion): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_SCRIPT_ADD_QUESTION, data).pipe(catchError(errorMgmt));
    }

    onDeleteQuestion(questionId: any): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_SCRIPT_DELETE_QUESTION}/${questionId}`).pipe(catchError(errorMgmt));
    }

    onCreateAnswer(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_SCRIPT_ADD_ANSWER, data).pipe(catchError(errorMgmt));
    }

    onDeleteAnswer(answerId: any): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_SCRIPT_DELETE_ANSWER}/${answerId}`).pipe(catchError(errorMgmt));
    }

    onDeleteScript(scriptId: any): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_OB_SCRIPT_DELETE}/${scriptId}`).pipe(catchError(errorMgmt));
    }

    onUpdateScript(data: IOBUpdateScript): Observable<any> {
        return this.http.post<any>(CommandURL.API_OB_SCRIPT_UPDATE, data).pipe(catchError(errorMgmt));
    }
}