import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient, languagePath: ILangugeConfig) {
  return new TranslateHttpLoader(http, languagePath.path, '.json');
}

export const LANGUAGE_CONFIG = new InjectionToken<ILangugeConfig>('language.config');

export interface ILangugeConfig {
  path: string;
}

export class CustomizeMissingTranslationHandler implements MissingTranslationHandler {

  constructor(private translate: MissingTranslateService) {}

  handle(params: MissingTranslationHandlerParams) {
    return !!params.interpolateParams ?
      this.translate.getTranslation(params.key, params.interpolateParams) :
      this.translate.getTranslation(params.key);
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class MissingTranslateService {

  constructor(
    private translate: TranslateService
  ) { }

  getTranslation(key: string, interpolateParams?: any): string | any {
    return !!interpolateParams ?
      this.translate.instant(key, interpolateParams) :
      this.translate.instant(key);
  }
}
