import { ChangeDetectorRef, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "src/app/shared/services/storage.service";
import * as signalR from '@microsoft/signalr';
import { BaseURL, CookieName, _Constants } from "src/app/utils/constant";
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { AgentService } from "src/app/shared/services/agent.service";
import { ISearch } from "src/app/shared/models/search.models";
import { IAgentCurrentCall, IAgentEventRingCall, IAgentQueueOutBound, IAuthApplicationConfig, IExtensionAgent, IExtensionDataStorage } from "src/app/shared/models/agent.models";
import { EventService } from "src/app/shared/services/event.service";
import { LoadListMenuAgentPage, MenuItems } from "src/app/utils/menu";
import { SupervisorService } from "src/app/shared/services/supervisor.service";
import { IUserData } from "src/app/shared/models/auth.models";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { AuthService } from "src/app/shared/services/auth.service";
import { Select2OptionData } from "ng-select2";

@Injectable({ providedIn: 'root' })
export class AgentRouteResolve implements Resolve<Observable<any>> {

    listMenus: MenuItems[] = LoadListMenuAgentPage();

    constructor(
        private cookieService: StorageService,
        private router: Router,
        private _agent: AgentService,
        private _auth: AuthService,
        private _sup: SupervisorService,
        private _dbService: NgxIndexedDBService,
        private _event: EventService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> | any> {
        const extenData: IExtensionDataStorage = this.cookieService.get(_Constants.storage.extenData) as IExtensionDataStorage;
        const userData: IUserData = this.cookieService.userData as IUserData;
        this._agent.requestDataExtAgent$.next(extenData);
        this._agent.requestUserData$.next(userData);

        var permissions = this.cookieService.userData?.permissions;

        if (!permissions.some((x: any) => x.includes(route.data?.permission)))
            this.router.navigate(['/']);

        this._event.requestMenuData$.next([]);
        this._auth.ApplicationConfig().subscribe((dt) => {
            var _appConfig = dt as IAuthApplicationConfig;

            this.listMenus.map(r => {
                if (r.name.toLowerCase().includes('ticket')) {
                    r.isUse = _appConfig.useTicket;
                }
                return r;
            });
            this._agent.$agentAppConfig.next(_appConfig);
            this._agent.$agentAppConfigActivity.next(_appConfig);
            var listMenus = this.listMenus.filter(x => x.isUse && permissions.some((p: any) => x.permission == p) || x.permission.includes('DASHBOARD') || x.permission.includes('ZALO') || x.permission.includes('EMAIL'));
            this._event.requestMenuData$.next(listMenus);
        });
        this._agent.onAgentQueueOutBound().subscribe((queue: IAgentQueueOutBound[]) => {
            let mapped: Select2OptionData[] = [];
            queue.forEach(r => {
                r.extensionIds.forEach(e => {
                    mapped.push({
                        id: `${r.queueId}_${e.extensionId}`,
                        text: `${r.queueName}${e.phone ? ' - ' + e.phone : ''}`,
                        additional: e
                    });
                });
                return mapped;
            });
            this._agent.$agentQueueOBExten.next(mapped);
        })
        this.onClearIndexDb();
    }

    onClearIndexDb() {
        this._dbService.clear(_Constants.database.contacts).subscribe((res) => { return res; });
        this._dbService.clear(_Constants.database.accounts).subscribe((res) => { return res; });
        this._dbService.clear(_Constants.database.activityLogs).subscribe((res) => { return res; });
    }


}
