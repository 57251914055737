import { CookieName } from "src/app/utils/constant";
import { IRequestTokenRf, ITokenRefresh } from "../models/auth.models";
import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";

export function appInitializer(auth: AuthService, _storage: StorageService) {
    return () => new Promise(resolve => {
        if (_storage.getCookie(CookieName._ACCESS_TOKEN) && _storage.userData) {
            const accessToken = _storage.getCookie(CookieName._ACCESS_TOKEN);
            const userData = _storage.userData;
            let data: IRequestTokenRf = {
                userName: userData.userName,
                oldToken: accessToken
            } as IRequestTokenRf;
            auth.RequestTokenRefres(data)
                .subscribe((res: ITokenRefresh) => {
                    let now = new Date();
                    let expired = new Date(res.expiredAt);
                    var offset = expired.getTime() - now.getTime();
                    var hours = Math.floor(offset / 1000 / 60 / 60);
                    var days = Math.floor(offset / 1000 / 60 / 60 / 24);
                    _storage.setCookie(CookieName._ACCESS_TOKEN, res.accesstoken, days, hours, '/');
                    _storage.setSession(CookieName._ACCESS_TOKEN, res.accesstoken);
                })
                .add(resolve);
        }
        resolve(true);
    });
}