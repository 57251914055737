import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { IAccountResponse, IAccounts, IAccountModel, IAccountInfor, IChangePassword, IAssignExtAccountModel } from "../models/account.models";
import { LoginModel, LoginResult } from "../models/auth.models";
import { ISearch } from "../models/search.models";

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private http: HttpClient
    ) { }

    GetAllUsers(data: ISearch): Observable<IAccountResponse> {
        return this.http.post<IAccountResponse>(CommandURL.API_GET_ALL_USER, data).pipe(catchError(errorMgmt));
    }

    GetUserInfor(): Observable<IAccountInfor> {
        return this.http.get<IAccountInfor>(CommandURL.API_GET_USER).pipe(catchError(errorMgmt));
    }

    AddUsers(data: IAccountModel): Observable<any> {
        return this.http.post<any>(CommandURL.API_ADD_USER, data).pipe(catchError(errorMgmt));
    }

    UpdateUsers(data: IAccountModel): Observable<any> {
        return this.http.put<any>(`${CommandURL.API_UPDATE_USER}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    DeleteUsers(data: IAccounts): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_DELETE_USER}/${data.id}`).pipe(catchError(errorMgmt));
    }

    LockUsers(data: IAccounts): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_LOCK_USER}/${data.id}/${data.isLock}`).pipe(catchError(errorMgmt));
    }

    ResetPasswordUsers(data: IAccounts): Observable<any> {
        return this.http.get(`${CommandURL.API_RESET_PASSWORD_USER}/${data.id}`, { responseType: 'text' }).pipe(catchError(errorMgmt));
    }

    onChangePassword(data: IChangePassword): Observable<any> {
        return this.http.post<any>(CommandURL.API_CHANGE_PWD_USER, data).pipe(catchError(errorMgmt));
    }

    onAccountSettings(data: IAccountInfor): Observable<any> {
        return this.http.post<any>(CommandURL.API_UPDATE_INFO_USER, data).pipe(catchError(errorMgmt));
    }

    onAssignExtension(data: IAssignExtAccountModel): Observable<any> {
        return this.http.put<any>(`${CommandURL.API_ASSIGN_EXTENSION}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    GetAllCategory(data: ISearch): Observable<any> {
        return this.http.post<any>(CommandURL.API_GET_ALL_CATEGORY, data).pipe(catchError(errorMgmt));
    }

    GetAllCategoryByIdCategory(data: ISearch): Observable<any> {
        return this.http.post<any>(CommandURL.API_GET_ALL_CATEGORY_BY_IDCATEGORY, data).pipe(catchError(errorMgmt));
    }
}