import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './shared/guards/app_init';
import { AuthGuard, AuthGuardDefault, AuthGuardPermission } from './shared/guards/auth.guard';
import { RequestInterceptor } from './shared/guards/request.interceptor';
import { ResponseInterceptor } from './shared/guards/response.interceptor';
import { DynamicModule } from './shared/module/dynamic.module';
import { AccountService } from './shared/services/account.service';
import { AgentService } from './shared/services/agent.service';
import { AuthService } from './shared/services/auth.service';
import { CalendarService } from './shared/services/calendar.service';
import { ConfigService } from './shared/services/config.service';
import { ContactsService } from './shared/services/contacts.service';
import { EventService } from './shared/services/event.service';
import { ExtensionService } from './shared/services/extension.service';
import { HotlineService } from './shared/services/hotline.service';
import { ImportService } from './shared/services/import.service';
import { IvrService } from './shared/services/ivr.service';
import { QueueService } from './shared/services/queue.service';
import { RecordService } from './shared/services/record.service';
import { RoleService } from './shared/services/role.service';
import { StorageService } from './shared/services/storage.service';
import { SupervisorService } from './shared/services/supervisor.service';
import { TagsService } from './shared/services/tags.service';
import { TicketFieldService } from './shared/services/ticket-field.service';
import { TicketReportService } from './shared/services/ticket-report.service';
import { TicketService } from './shared/services/ticket.service';
import { HttpLoaderFactory, LANGUAGE_CONFIG } from './shared/services/translate.service';
import { VipBlackService } from './shared/services/vipblack.service';
import { BaseModule } from './utils/base.module';
import { dbConfig } from './utils/database';
import { CustomPaginatorIntlTranslate, ServerId } from './utils/helpers';
import { OBScriptService } from './shared/services/ob-script.service';
import { OBTemplateService } from './shared/services/ob-template.service';
import { OBCampaignService } from './shared/services/ob-campaign.service';
import { OBCallService } from './shared/services/ob-call.service';
import { OBDataSourceService } from './shared/services/ob-datasource.service';
import { OBGroupService } from './shared/services/ob-group.service';
import { ZaloService } from './shared/services/zalo.service';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { FcmService } from './shared/services/firebase.service';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { RabbitMQService } from './shared/services/rabbitmq.service';
import { BroadCastService } from './shared/services/broadcast.service';

initializeApp(environment.firebaseConfig);


const APP_SERVICES = [
    StorageService,
    AuthService,
    AccountService,
    IvrService,
    HotlineService,
    CalendarService,
    EventService,
    RecordService,
    QueueService,
    ExtensionService,
    RoleService,
    VipBlackService,
    AgentService,
    SupervisorService,
    ConfigService,
    ContactsService,
    ImportService,
    TagsService,
    TicketFieldService,
    TicketService,
    TicketReportService,
    OBScriptService,
    OBTemplateService,
    OBCampaignService,
    OBCallService,
    OBDataSourceService,
    OBGroupService,
    ZaloService,
    FcmService,
    RabbitMQService,
    BroadCastService
]


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: ServerId.start() }),
        BrowserAnimationsModule,
        HttpClientModule,
        DynamicModule,
        ReactiveFormsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, LANGUAGE_CONFIG]
            },
            isolate: true
        }),
        NgxIndexedDBModule.forRoot(dbConfig),
        NgxTippyModule
    ],
    providers: [
        ...APP_SERVICES,
        AuthGuard,
        AuthGuardDefault,
        AuthGuardPermission,
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, StorageService] },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntlTranslate },
        {
            provide: LANGUAGE_CONFIG,
            useValue: {
                path: './assets/i18n/'
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule extends BaseModule {
    constructor(router: Router, translate: TranslateService) {
        super(router, translate);
    }
}

