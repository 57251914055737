import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogService } from './dialog.service';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { V9HTMLSafePipe, V9SafePipe } from 'src/app/utils/pipe';

@NgModule({
    imports: [
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        TranslateModule
    ],
    declarations: [
        DialogErrorComponent,
        DialogConfirmComponent,
        DialogNoticeComponent,
        DialogMessageComponent,
        V9HTMLSafePipe
    ],
    entryComponents: [
        DialogErrorComponent,
        DialogConfirmComponent,
        DialogNoticeComponent
    ],
    providers: [
        DialogService
    ]
})
export class DialogModule { }
