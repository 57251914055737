import { Options } from "select2";
import { environment } from "src/environments/environment";
import { GenerateClient, ZaloState } from "./helpers";

export const _Constants = {
    storage: {
        authorization: 'authorization',
        userData: 'userData',
        language: 'language',
        pageTokens: 'pageTokens',
        Permissions: 'Permissions',
        module: 'module',
        extenData: 'extenData',
        QueueRules: 'queueRules',
        appConfig: 'appConfig',
        status: 'status',
        statusSub: 'statusSub',
    },
    config: {
        apiUrl: 'assets/api',
        localeUrl: './assets/locales/'
    },
    database: {
        contacts: 'contacts',
        accounts: 'accounts',
        activityLogs: 'activityLogs',
    }
};

export const _OPTION_SELECT: Options = {
    language: {
        noResults: () => "Không có dữ liệu"
    },
    allowClear: true,
    minimumResultsForSearch: -1,
} as Options;


export class CookieName {
    public static _IS_LOGIN = '_is_login';
    public static _LOGIN_CONSET = '_login_conset';
    public static _ACCESS_TOKEN = '_token_access';
    public static _INFOMATION = '_infomation';
}

export class InfoRabbitMQ {
    public static hostName = environment.production ? 'vnpost.callbot.cloud' : 'vnpost.callbot.cloud';
    public static userName = 'vnpostmessage';
    public static password = '98064fa88e7cb9edf68a8657164adf3d';
    public static emailuserName = 'admin';
    public static emailpassword = 'admin';
    public static clientId = GenerateClient.start();
}

export class BaseURL {
    public static baseURL = environment.baseURL;
    public static location = environment.location;
    public static apiURL = `${BaseURL.baseURL}/` + (BaseURL.location != '' ? `${BaseURL.location}/` : '');

    public static signalrHub = environment.signalURL; //'https://localhost:64101/v9hub';
    public static signalrStatusHub = environment.signalStatusURL; //'https://localhost:64101/v9hub';
}


export class CommandURL {
    public static API_LOGIN = BaseURL.apiURL + 'auth/Account/Token';
    public static API_REQUEST_LOGOUT = BaseURL.apiURL + 'auth/Account/RequestLogout';
    public static API_REQUEST_FOTGOT = BaseURL.apiURL + 'auth/Account/GetCode';
    public static API_REQUEST_TOKEN_SIGNIN = BaseURL.apiURL + 'auth/Account/SigninToken';
    public static API_REQUEST_RECOVERY = BaseURL.apiURL + 'auth/Account/Recoverypw';
    public static API_APPLICATION_CONFIG = BaseURL.apiURL + 'application/Config';

    //category
    public static API_CATEGORY_GET_ALL = BaseURL.apiURL + 'manager/category/GetAll';
    public static API_CATEGORY_GET_BY_USER = BaseURL.apiURL + 'manager/category/GetByUser';
    public static API_CATEGORY_ADD_UPDATE = BaseURL.apiURL + 'manager/category/CreateOrUpdate';
    public static API_CATEGORY_DELETE = BaseURL.apiURL + 'manager/category/Delete';
    public static API_CATEGORY_SELECT_ONE = BaseURL.apiURL + 'manager/category/SelectOne';

    // User
    public static API_GET_ALL_USER = BaseURL.apiURL + 'manager/User/GetAll';
    public static API_GET_USER = BaseURL.apiURL + 'manager/User';
    public static API_ADD_USER = BaseURL.apiURL + 'manager/User';
    public static API_UPDATE_USER = BaseURL.apiURL + 'manager/User/Update';
    public static API_DELETE_USER = BaseURL.apiURL + 'manager/User';
    public static API_LOCK_USER = BaseURL.apiURL + 'manager/User/Lock';
    public static API_RESET_PASSWORD_USER = BaseURL.apiURL + 'manager/User/ResetPassword';
    public static API_UPDATE_INFO_USER = BaseURL.apiURL + 'manager/User/AccountSetting';
    public static API_CHANGE_PWD_USER = BaseURL.apiURL + 'manager/User/ChangePassword';
    public static API_ASSIGN_EXTENSION = BaseURL.apiURL + 'manager/User/AssignExtension';
    public static API_GET_ALL_CATEGORY = BaseURL.apiURL + 'manager/User/GetAllCategory';
    public static API_GET_ALL_CATEGORY_BY_IDCATEGORY = BaseURL.apiURL + 'manager/User/GetAllCategoryByIdCategory';

    // IVR
    public static API_IVR_GETALL = BaseURL.apiURL + 'voice/IVR/GetAll';
    public static API_IVR_CREATE = BaseURL.apiURL + 'voice/IVR/Create';
    public static API_IVR_UPDATE = BaseURL.apiURL + 'voice/IVR/Update';
    public static API_IVR_DELETE = BaseURL.apiURL + 'voice/IVR';
    public static API_IVR_GET_BY_ID = BaseURL.apiURL + 'voice/IVR';
    public static API_IVR_GET_BY_TYPE = BaseURL.apiURL + 'voice/IVR/GetByType';
    public static API_IVR_GET_TestRabbit = BaseURL.apiURL + 'voice/IVR/TestRabbitMQ';

    // Queue
    public static API_QUEUE_GETALL = BaseURL.apiURL + 'voice/Queue/GetAll';
    public static API_QUEUE_CREATE = BaseURL.apiURL + 'voice/Queue/Create';
    public static API_QUEUE_UPDATE = BaseURL.apiURL + 'voice/Queue/Update';
    public static API_QUEUE_GETLIST = BaseURL.apiURL + 'voice/Queue/GetSelectQueue';
    public static API_QUEUE_DELETE = BaseURL.apiURL + 'voice/Queue';

    // IVR Record
    public static API_RECORD_GETALL = BaseURL.apiURL + 'voice/IVR/IVRRecord/GetAll';
    public static API_RECORD_CREATE = BaseURL.apiURL + 'voice/IVR/IVRRecord/Create';
    public static API_RECORD_UPDATE = BaseURL.apiURL + 'voice/IVR/IVRRecord/Update';
    public static API_RECORD_DELETE = BaseURL.apiURL + 'voice/IVR/IVRRecord';
    public static API_RECORD_LISTEN = BaseURL.apiURL + 'voice/IVR/IVRRecord/Listen';

    // Get ALL Đầu số
    public static API_HOTLINE_GETALL = BaseURL.apiURL + 'voice/IVR/Extension/GetAll';
    public static API_HOTLINE_CREATE = BaseURL.apiURL + 'voice/IVR/Extension/Create';
    public static API_HOTLINE_UPDATE = BaseURL.apiURL + 'voice/IVR/Extension/Update';
    public static API_HOTLINE_DELETE = BaseURL.apiURL + 'voice/IVR/Extension';
    public static API_HOTLINE_BY_USER = BaseURL.apiURL + 'voice/IVR/Extension/getExtensionByUserLogon';

    // Lịch làm việc
    public static API_CALENDAR_GETALL = BaseURL.apiURL + 'voice/IVR/Calendar/GetAll';
    public static API_CALENDAR_GETBYID = BaseURL.apiURL + 'voice/IVR/Calendar';
    public static API_CALENDAR_CREATE = BaseURL.apiURL + 'voice/IVR/Calendar/Create';
    public static API_CALENDAR_UPDATE = BaseURL.apiURL + 'voice/IVR/Calendar/Update';
    public static API_CALENDAR_DELETE = BaseURL.apiURL + 'voice/IVR/Calendar';

    // Số nội bộ
    public static API_INTERNAL_NUMBER_GETALL = BaseURL.apiURL + 'voice/InternalNumber/GetAll';
    public static API_INTERNAL_NUMBER_GETBYISRECORDACTIVE = BaseURL.apiURL + 'voice/InternalNumber/GetAllByIsRecordActive';
    public static API_INTERNAL_NUMBER_HISTORY = BaseURL.apiURL + 'voice/InternalNumber/GetHistoryInternalNumber';
    public static API_INTERNAL_NUMBER_GETREQUESTEDIT = BaseURL.apiURL + 'voice/InternalNumber/GetRequestsEdit';
    public static API_INTERNAL_NUMBER_BY_USER = BaseURL.apiURL + 'voice/InternalNumber/GetByUser';
    public static API_INTERNAL_NUMBER_GETALLavailable = BaseURL.apiURL + 'voice/InternalNumber/GetAllavailable';
    public static API_INTERNAL_NUMBER_TestRabbitMQ = BaseURL.apiURL + 'voice/InternalNumber/TestRabbitMQ';
    public static API_INTERNAL_NUMBER_CREATE = BaseURL.apiURL + 'voice/InternalNumber/Create';
    public static API_INTERNAL_NUMBER_UPDATE = BaseURL.apiURL + 'voice/InternalNumber/Update';
    public static API_INTERNAL_NUMBER_CHANGERECORDACTIVE = BaseURL.apiURL + 'voice/InternalNumber/ChangeRecordActive';
    public static API_INTERNAL_NUMBER_CHANGERECORDACTIVEALL = BaseURL.apiURL + 'voice/InternalNumber/ChangeRecordActiveAll';
    public static API_INTERNAL_NUMBER_DELETE = BaseURL.apiURL + 'voice/InternalNumber';

    // Phân quyền
    public static API_ROLE_GETALL = BaseURL.apiURL + 'manager/Role/GetAll';
    public static API_ROLE_CREATE = BaseURL.apiURL + 'manager/Role/Create';
    public static API_ROLE_UPDATE = BaseURL.apiURL + 'manager/Role/Update';
    public static API_ROLE_DELETE = BaseURL.apiURL + 'manager/Role';
    public static API_ROLE_DELETE_ACCOUNT = BaseURL.apiURL + 'manager/Role/RemoveAccountInRole';
    public static API_ROLE_GET_PERMISSION = BaseURL.apiURL + 'manager/Role/GetAllPermission';

    // VIP Black
    public static API_VIPBL_GETALL = BaseURL.apiURL + 'voice/VIPBlack/GetAll';
    public static API_VIPBL_DELETE = BaseURL.apiURL + 'voice/VIPBlack';
    public static API_VIPBL_UPDATE = BaseURL.apiURL + 'voice/VIPBlack/Update';
    public static API_VIPBL_CREATE = BaseURL.apiURL + 'voice/VIPBlack/Create';
    public static API_VIPBL_SYNCHRONIZED = BaseURL.apiURL + 'voice/VIPBlack/Synchronized';


    // Agent
    // public static API_AGENT_LOGOUT = BaseURL.apiURL + 'voice/Agent/Logout';
    public static API_AGENT_LOGOUT = BaseURL.apiURL + 'manager/User/LogOut';
    public static API_AGENT_REFRESH_TOKEN = BaseURL.apiURL + 'voice/Agent/RefreshToken';
    public static API_AGENT_CS_ALL_QUEUE = BaseURL.apiURL + 'voice/Agent/ChangeAllStatus'; // Change status all queue
    public static API_AGENT_CHANGE_STATUS = BaseURL.apiURL + 'voice/Agent/ChangeStatus';
    public static API_AGENT_GET_ALL = BaseURL.apiURL + 'voice/Agent/GetAllAccount';
    public static API_AGENT_OUTBOUND = BaseURL.apiURL + 'voice/Agent/OutBound';
    public static API_AGENT_OUTBOUND_OMS = BaseURL.apiURL + 'voice/Agent/OutBoundOMS';

    public static API_AGENT_ACTIVITY_LOG = BaseURL.apiURL + 'voice/Agent/GetActiveLog';
    public static API_AGENT_LQUEUE_TO_OB = BaseURL.apiURL + 'voice/Agent/GetListQueueToOutBound';
    public static API_AGENT_QUEUE_AGENT = BaseURL.apiURL + 'voice/Agent/GetQueueAgent';
    public static API_AGENT_CALL_HISTORY = BaseURL.apiURL + 'voice/Agent/GetCallHistoryAgent';
    public static API_AGENT_LEAVE_QUEUE = BaseURL.apiURL + 'voice/Agent/LeaveQueue';
    public static API_AGENT_JOIN_QUEUE = BaseURL.apiURL + 'voice/Agent/JoinQueue';
    public static API_AGENT_CALENDAR_GETALL = BaseURL.apiURL + 'voice/Agent/Calendar/GetAll';
    public static API_AGENT_CALENDAR_CREATE = BaseURL.apiURL + 'voice/Agent/Calendar/Create';
    public static API_AGENT_CALENDAR_UPDATE = BaseURL.apiURL + 'voice/Agent/Calendar/Update';
    public static API_AGENT_CALENDAR_DELETE = BaseURL.apiURL + 'voice/Agent/Calendar/Delete';
    public static API_AGENT_RECORDING_FILE = BaseURL.apiURL + 'voice/Agent/GetRecordingFile';
    public static API_AGENT_CURRENT_CALL = BaseURL.apiURL + 'voice/Agent/GetAgentStatusCall';
    public static API_AGENT_CONTACT_GETALL = BaseURL.apiURL + 'voice/Agent/GetAllContact';
    public static API_AGENT_CALL_MUTE = BaseURL.apiURL + 'voice/Agent/Mute';
    public static API_AGENT_CALL_UNMUTE = BaseURL.apiURL + 'voice/Agent/UnMute';
    public static API_AGENT_GET_AGENT_TF = BaseURL.apiURL + 'voice/Agent/AgentsToTransfer';
    public static API_AGENT_BLIND_TRANSFER = BaseURL.apiURL + 'voice/Agent/BlindTransfer';
    public static API_AGENT_TRANSFER = BaseURL.apiURL + 'voice/Agent/Transfer';
    public static API_AGENT_HANGUP = BaseURL.apiURL + 'voice/Agent/Hangup';
    public static API_AGENT_NOTI_MISS_CALL = BaseURL.apiURL + 'voice/Agent/Notify/GetAgentMissCall';
    public static API_AGENT_NOTI_VIEW_MISS_CALL = BaseURL.apiURL + 'voice/Agent/Notify/UpdateViewAgentMissCall';
    public static API_AGENT_GET_CALL_HISTORY = BaseURL.apiURL + 'voice/Agent/GetCallHistoryAgentByCallId';
    public static API_UPDATE_STATUS_MISS_CALL = BaseURL.apiURL + 'voice/Agent/Notify/UpdateStatus';
    public static API_AGENT_NOTI_VIEW_MISS_CALL_ID = BaseURL.apiURL + 'voice/Agent/GetNotifyAgentMissCallList';
    public static API_AGENT_NOTI_VIEW_MISS_CALL_PHONE = BaseURL.apiURL + 'voice/Agent/GetNotifyAgentMissCallPhone';
    public static API_AGENT_NOTI_VIEW_MISS_CALL_PHONE_REPORT = BaseURL.apiURL + 'voice/Agent/GetNotifyAgentMissCallPhoneReprot';
    public static API_AGENT_CHECK_ENDPOINT = BaseURL.apiURL + 'voice/Agent/CheckEndpointExtension';

    public static API_AGENT_NOTI_LOCK_PHONE = BaseURL.apiURL + 'voice/Agent/Notify/GetAgentLockPhone';
    public static API_UPDATE_STATUS_LOCKPHONE = BaseURL.apiURL + 'voice/Agent/Notify/UpdateViewAgentLockPhone';
    public static API_AGENT_NOTI_GENERALNOTICES = BaseURL.apiURL + 'voice/Agent/Notify/GetGeneralNotices';

    public static API_RECEIVE_MESSAGE = BaseURL.apiURL + 'manager/User/ApiReceiveMessage';

    // Supervisor
    public static API_SUP_ALL_QUEUE = BaseURL.apiURL + 'voice/SUP/GetAllQueue';
    public static API_SUP_SELECT_QUEUE = BaseURL.apiURL + 'voice/SUP/GetSelectQueue';
    public static API_SUP_ALL_AGENT = BaseURL.apiURL + 'voice/SUP/GetAllAgent';
    public static API_SUP_REAL_TIME = BaseURL.apiURL + 'voice/SUP/GetRealTimeChart';
    public static API_SUP_MISS_CALL = BaseURL.apiURL + 'voice/SUP/GetIVRBoxMissCall';
    public static API_SUP_CHANGE_STATUS_AGENT = BaseURL.apiURL + 'voice/SUP/ChangeStatusAgentInQueue';
    public static API_SUP_ADD_REMOVE_QUEUE = BaseURL.apiURL + 'voice/SUP/AddRemoveQueue';
    public static API_SUP_CHANSPY = BaseURL.apiURL + 'voice/SUP/Chanspy';
    public static API_SUP_REMIND = BaseURL.apiURL + 'voice/SUP/Remind';
    public static API_SUP_CONFERENCE = BaseURL.apiURL + 'voice/SUP/Conference';
    public static API_SUP_HANGUP = BaseURL.apiURL + 'voice/SUP/Hangup';
    public static API_SUP_GET_AGENT_TF = BaseURL.apiURL + 'voice/SUP/AgentsToTransfer';
    public static API_SUP_BLIND_TRANSFER = BaseURL.apiURL + 'voice/SUP/BlindTransfer';
    public static API_SUP_TRANSFER = BaseURL.apiURL + 'voice/SUP/Transfer';
    public static API_SUP_PICKUP = BaseURL.apiURL + 'voice/SUP/Pickup';
    public static API_SUP_CHANGE_ORDER_INDEX = BaseURL.apiURL + 'voice/SUP/QueueChangeOrderIndex';
    public static API_SUP_HANGUP_CLEAR = BaseURL.apiURL + 'voice/SUP/HangupClearAgent';
    public static API_SUP_CHANGE_AGENT_STATUS = BaseURL.apiURL + 'voice/SUP/QueueChangeStatus';
    public static API_SUP_GET_TOTAL_CALL = BaseURL.apiURL + 'voice/SUP/GetTotalCall';

    //Report
    public static API_REPORT_ALL_USER = BaseURL.apiURL + 'voice/Report/GetAllUser';
    public static API_REPORT_ALL_QUEUE = BaseURL.apiURL + 'voice/Report/GetAllQueue';
    public static API_REPORT_ALL_GROUPCHARGE = BaseURL.apiURL + 'voice/Report/GetAllGroupCharge';
    public static API_REPORT_CALLABANDON = BaseURL.apiURL + 'voice/Report/CallAbandon';
    public static API_REPORT_MISS_CALL_BY_HOUR = BaseURL.apiURL + 'voice/Report/GetReport_MissCall_By_Hour';
    public static API_REPORT_MISS_CALL_DETAIL_BY_HOUR = BaseURL.apiURL + 'voice/Report/GetReport_MissCall_Detail_By_Hour';
    public static API_REPORT_CALLABANDON_DETAIL = BaseURL.apiURL + 'voice/Report/CallAbandonDetail';
    public static API_REPORT_CALLBYDAY = BaseURL.apiURL + 'voice/Report/CallByDay';
    public static API_REPORT_CALLBYHOURLY = BaseURL.apiURL + 'voice/Report/CallByHourly';
    public static API_REPORT_CALLDETAIL = BaseURL.apiURL + 'voice/Report/Detail';
    public static API_REPORT_CALLPERFORMANCE = BaseURL.apiURL + 'voice/Report/GetCallPerformance';
    public static API_REPORT_PBX_CALLDETAIL = BaseURL.apiURL + 'voice/Report/PBXDetail';
    public static API_REPORT_CALLDETAILLOG = BaseURL.apiURL + 'voice/Report/CallDetailLog';
    public static API_REPORT_DNIS = BaseURL.apiURL + 'voice/Report/CallByDNIS';
    public static API_REPORT_TOTAL_INDEX = BaseURL.apiURL + 'voice/Report/TotalIndex';
    public static API_REPORT_RATE_QUEUE = BaseURL.apiURL + 'voice/Report/RateQueue';
    public static API_REPORT_TOP_AGENT = BaseURL.apiURL + 'voice/Report/TopAgent';
    public static API_REPORT_STATUS_AGENT = BaseURL.apiURL + 'voice/Report/ReportStatusAgent';
    public static API_REPORT_PRODUCTIVITY_AGENT = BaseURL.apiURL + 'voice/Report/AgentPerformance';
    public static API_REPORT_CALL_RECORDINGS = BaseURL.apiURL + 'voice/Report/CallGetRecodings';
    public static API_AGENT_MISS_CALLDETAIL = BaseURL.apiURL + 'Agent/MisCallDetail';
    public static API_AGENT_MISS_CALLDETAILLOG = BaseURL.apiURL + 'Agent/GetMissCallDetailLog';
    public static API_REPORT_PRODUCTIVITYTIME_AGENT = BaseURL.apiURL + 'voice/Report/AgentPerformanceTime';
    public static API_REPORT_CALLABANDON_QUEUE = BaseURL.apiURL + 'voice/Report/CallAbandonQueue';
    public static API_UPDATE_STATUS_REPORT = BaseURL.apiURL + 'Agent/Notify/UpdateStatusReport';
    public static API_REPORT_EMAILINBOX_EXPORT = BaseURL.apiURL + 'voice/Report/ReportEmailInbox';
    public static API_REPORT_EMAISEND_EXPORT = BaseURL.apiURL + 'voice/Report/ReportEmailSend';
    public static API_REPORT_GET_CALL_BY_GROUP = BaseURL.apiURL + 'voice/Report/ReportCallByGroup';
    public static API_REPORT_GET_DETAIL_EXTENSION = BaseURL.apiURL + 'voice/Report/GetDetailExtension';
    public static API_REPORT_GET_SUMMARY_EXTENSION = BaseURL.apiURL + 'voice/Report/GetSummaryExtension';

    public static API_REPORT_CALLBYHOURLY_DETAIL = BaseURL.apiURL + 'voice/Report/CallByHourly_Detail';
    public static API_REPORT_GET_CALL_BY_GROUP_DETAIL = BaseURL.apiURL + 'voice/Report/ReportCallByGroup_Detail';
    public static API_REPORT_DNIS_TONG_HOP = BaseURL.apiURL + 'voice/Report/CallByDNIS_TongHop';
    public static API_REPORT_DNIS_CHI_TIET = BaseURL.apiURL + 'voice/Report/CallByDNIS_ChiTiet';
    public static API_REPORT_STATUS_DETAIL_AGENT = BaseURL.apiURL + 'voice/Report/AgentStatusDetail';
    public static API_REPORT_AGENT_ONLINE_LATE = BaseURL.apiURL + 'email/Report/ReportEmailSend';
    public static API_REPORT_PRODUCTIVITY_AGENT_DETAIL = BaseURL.apiURL + 'voice/Report/AgentDetaliPerformance';
    public static API_REPORT_GENERAL = BaseURL.apiURL + 'voice/Report/GetGeneral';
    public static API_REPORT_GENERAL_DETILE_AGENT = BaseURL.apiURL + 'voice/Report/GetGeneral_Agent';
    public static API_REPORT_CALLDIRECTION = BaseURL.apiURL + 'voice/Report/GetReportCallDirection';
    public static API_REPORT_CALLSUMMARY = BaseURL.apiURL + 'voice/Report/GetSummaryCall';
    public static API_REPORT_CALLSUMMARYOUTBOUND = BaseURL.apiURL + 'voice/Report/GetSummaryCallOutBound';

    //Báo cáo sản lượng
    public static API_OB_DATASOURCE_GET_REPORT_AGNETQUANTITY = BaseURL.apiURL + 'voice/Report/GetAgentQuantity';
    public static API_OB_DATASOURCE_GET_REPORT_TIMEAGNET_EXPORT = BaseURL.apiURL + 'voice/Report/GetAgentQuantityExport';


    //EXPORT
    public static API_REPORT_CALLABANDON_EXPORT = BaseURL.apiURL + 'voice/Report/CallAbandonExport';
    public static API_REPORT_CALLABANDON_QUEUE_EXPORT = BaseURL.apiURL + 'voice/Report/CallAbandonQueueExport';
    public static API_REPORT_CALLBYDAY_EXPORT = BaseURL.apiURL + 'voice/Report/CallByDayExport';
    public static API_REPORT_CALLBYHOURLY_EXPORT = BaseURL.apiURL + 'voice/Report/CallByHourlyExport';
    public static API_REPORT_CALLBY_DIRECTION_EXPORT = BaseURL.apiURL + 'voice/Report/CallByDirectionExport';
    public static API_REPORT_CALLDETAIL_EXPORT = BaseURL.apiURL + 'voice/Report/ExportDetail';
    public static API_REPORT_CALL_PERFORMANCE_EXPORT = BaseURL.apiURL + 'voice/Report/ExportCallPerformance';
    public static API_REPORT_PBX_CALLDETAIL_EXPORT = BaseURL.apiURL + 'voice/Report/ExportPBXDetail';
    public static API_REPORT_DNIS_EXPORT = BaseURL.apiURL + 'voice/Report/CallByDNISExport';
    public static API_REPORT_CALL_BY_GROUP = BaseURL.apiURL + 'voice/Report/ReportCallByGroupExport_Excel';
    public static API_REPORT_AGENT_ONLINE_LATE_EXPORT = BaseURL.apiURL + 'voice/Report/AgentOnlineLateDetailExport';
    public static API_REPORT_AGENT_STATUS_EXPORT = BaseURL.apiURL + 'voice/Report/GetAgentStatusExport_Excel';
    public static API_REPORT_AGENT_STATUS_DETAIL_EXPORT = BaseURL.apiURL + 'voice/Report/GetAgentStatusDetailExport_Excel';
    public static API_REPORT_AGENT_PERFORMANCE_EXPORT = BaseURL.apiURL + 'voice/Report/AgentPerformanceExport';
    public static API_REPORT_AGENT_GENERAL_EXPORT = BaseURL.apiURL + 'voice/Report/ExportReportGetGeneral_Excel';
    public static API_REPORT_AGENT_MISS_CALLDETAIL = BaseURL.apiURL + 'voice/Agent/ExportMissCallDetail';
    public static API_REPORT_DETAIL_EXTENSION_EXPORT = BaseURL.apiURL + 'voice/Report/ExportReportGetDetailExtension';
    public static API_REPORT_SUMMARY_EXTENSION_EXPORT = BaseURL.apiURL + 'voice/Report/ExportReportGetSummaryExtension';
    public static API_REPORT_SUMMARY_CALL_EXPORT = BaseURL.apiURL + 'voice/Report/ExportReportGetSummaryCall';
    public static API_REPORT_SUMMARY_CALL_OUTBOUND_EXPORT = BaseURL.apiURL + 'voice/Report/ExportReportGetSummaryCallOutBound';

    public static API_REPORT_EMAILINBOX_EXPORT_EXCEL = BaseURL.apiURL + 'voice/Report/ReportEmailInbox_Excel';
    public static API_REPORT_EMAISEND_EXPORT_EXCEL = BaseURL.apiURL + 'voice/Report/ReportEmailSend_Export';
    // Voice Settings

    public static API_VOICE_SETTINGS = BaseURL.apiURL + 'manager/VoiceSetting/GetAll';
    public static API_VOICE_UPDATE_SETTINGS = BaseURL.apiURL + 'manager/VoiceSetting/Update';
    public static API_VOICE_SELECT_QUEUE = BaseURL.apiURL + 'manager/VoiceSetting/GetSelectQueueNoExten';


    // Contacts
    public static API_CONTACTS_GET_ALL = BaseURL.apiURL + 'ticket/Contact/GetAll';
    public static API_CONTACTS_VIEW_BYID = BaseURL.apiURL + 'ticket/Contact/GetViewById';
    public static API_CONTACTS_UPDATE_BYID = BaseURL.apiURL + 'ticket/Contact/GetUpdateById';
    public static API_CONTACTS_UPDATE = BaseURL.apiURL + 'ticket/Contact';
    public static API_CONTACTS_DELETE = BaseURL.apiURL + 'ticket/Contact';
    public static API_CONTACTS_CREATE = BaseURL.apiURL + 'ticket/Contact';
    public static API_CONTACTS_GET_TEMPALTE = BaseURL.apiURL + 'ticket/Contact/GetTemplateImport';
    public static API_CONTACTS_UPLOAD = BaseURL.apiURL + 'ticket/Contact/UploadFile';
    public static API_CONTACTS_COLUMNS = BaseURL.apiURL + 'ticket/Contact/GetColumnsFied';
    public static API_CONTACTS_TEMP = BaseURL.apiURL + 'ticket/Contact/GetAllContactTemp';
    public static API_CONTACTS_CONFIRM = BaseURL.apiURL + 'ticket/Contact/ConfirmImport';
    public static API_CONTACTS_VIPBLACK = BaseURL.apiURL + 'ticket/Contact/UpdateVIPBlackList';
    public static API_CONTACTS_GET_VIPBLACK = BaseURL.apiURL + 'ticket/Contact/GetVIPBlackList';


    // Contact Field
    public static API_CONTACT_FIELD_CREATE = BaseURL.apiURL + 'ticket/ContactField/Create';
    public static API_CONTACT_FIELD_UPDATE = BaseURL.apiURL + 'ticket/ContactField/Update';
    public static API_CONTACT_FIELD_DELETE = BaseURL.apiURL + 'ticket/ContactField';
    public static API_CONTACT_FIELD_GETALL = BaseURL.apiURL + 'ticket/ContactField/GetAllticket/ContactField';
    public static API_CONTACT_FIELD_DEFAULT = BaseURL.apiURL + 'ticket/ContactField/GetDefaultField';
    public static API_CONTACT_FIELD_DETAIL = BaseURL.apiURL + 'ticket/ContactField/GetDetailField';
    public static API_CONTACT_FIELD_ORDER = BaseURL.apiURL + 'ticket/ContactField/Order';

    // Ticket Field
    public static API_TICKET_FIELD_GETALL = BaseURL.apiURL + 'Ticket/TicketField/GetAllTicketField';
    public static API_TICKET_FIELD_CREATE = BaseURL.apiURL + 'Ticket/TicketField/Create';
    public static API_TICKET_FIELD_UPDATE = BaseURL.apiURL + 'Ticket/TicketField/Update';
    public static API_TICKET_FIELD_DELETE = BaseURL.apiURL + 'Ticket/TicketField';
    public static API_TICKET_FIELD_ORDER = BaseURL.apiURL + 'Ticket/TicketField/Order';

    // Tags - Ticket Field
    public static API_TAGS_GETALL = BaseURL.apiURL + 'Ticket/TagList/GetAll';
    public static API_TAGS_CREATE = BaseURL.apiURL + 'Ticket/TagList/Create';
    public static API_TAGS_UPDATE = BaseURL.apiURL + 'Ticket/TagList/Update';
    public static API_TAGS_DELETE = BaseURL.apiURL + 'Ticket/TagList';

    // Ticket
    public static API_TICKET_GETALL = BaseURL.apiURL + 'Ticket/GetAll';
    public static API_TICKET_GETALL_FIELD = BaseURL.apiURL + 'Ticket/GetAllField';
    public static API_TICKET_GET_CODE = BaseURL.apiURL + 'Ticket/GetTicketCode';
    public static API_TICKET_GET_CATEGORY = BaseURL.apiURL + 'Ticket/GetTicketCategory';
    public static API_TICKET_GET_TICKET_HISTORY_BY_PHONE = BaseURL.apiURL + 'Ticket/GetTicketHistoryByPhone';
    public static API_TICKET_GET_ALL_TICKET_TYPE = BaseURL.apiURL + 'Ticket/GetAllTicketType';
    public static API_TICKET_CREATE = BaseURL.apiURL + 'Ticket';
    public static API_TICKET_UPDATE = BaseURL.apiURL + 'Ticket';
    public static API_TICKET_DELETE = BaseURL.apiURL + 'Ticket';
    public static API_TICKET_TAG_ALL = BaseURL.apiURL + 'Ticket/Tag/GetAll';
    public static API_TICKET_GET_CONTACT_BYPHONE = BaseURL.apiURL + 'Ticket/GetContactByPhone';
    public static API_TICKET_GET_TICKET_CONTACT_BYPHONE = BaseURL.apiURL + 'Ticket/GetTicketContactByPhone';
    public static API_TICKET_GET_TICKET_BY_CALLID = BaseURL.apiURL + 'Ticket/GetTicketByCallId';
    public static API_TICKET_GET_BYID = BaseURL.apiURL + 'Ticket/GetById';
    public static API_TICKET_DELETE_TAG = BaseURL.apiURL + 'Ticket/DeleteTag';
    public static API_TICKET_HANDLE_TAG = BaseURL.apiURL + 'Ticket/HandlerTag';
    public static API_TICKET_CREATE_TAG = BaseURL.apiURL + 'Ticket/CreateTicketTag';

    // Ticket Report
    public static API_TICKET_REPORT_TOTAL = BaseURL.apiURL + 'TicketReport/GetReportTotal';
    public static API_TICKET_REPORT_TIME = BaseURL.apiURL + 'TicketReport/GetReportTime';
    public static API_TICKET_REPORT_PRODUCTIVITY = BaseURL.apiURL + 'TicketReport/GetReportProductivity';
    public static API_TICKET_REPORT_GET_TYPE = BaseURL.apiURL + 'TicketReport/GetTicketType';

    // Outbound OMS

    public static API_OB_SCRIPT_GET = BaseURL.apiURL + 'OB/Script/GetAllScript';
    public static API_OB_SCRIPT_ADD = BaseURL.apiURL + 'OB/Script/CreateScript';
    public static API_OB_SCRIPT_DELETE = BaseURL.apiURL + 'OB/Script/Delete';
    public static API_OB_SCRIPT_UPDATE = BaseURL.apiURL + 'OB/Script/UpdateScriptName';
    public static API_OB_SCRIPT_GET_QUESTION = BaseURL.apiURL + 'OB/Script/GetQuestionByScript';
    public static API_OB_SCRIPT_ADD_QUESTION = BaseURL.apiURL + 'OB/Script/CreateQuestion';
    public static API_OB_SCRIPT_DELETE_QUESTION = BaseURL.apiURL + 'OB/Script/DeleteQuestion';
    public static API_OB_SCRIPT_ADD_ANSWER = BaseURL.apiURL + 'OB/Script/CreateAnswer';
    public static API_OB_SCRIPT_DELETE_ANSWER = BaseURL.apiURL + 'OB/Script/DeleteAnswer';



    // OB PackData

    public static API_OB_PACKDATA_GETALL = BaseURL.apiURL + 'OB/PackData/GetAll';
    public static API_OB_PACKDATA_CREATE = BaseURL.apiURL + 'OB/PackData/Create';
    public static API_OB_PACKDATA_UPDATE = BaseURL.apiURL + 'OB/PackData/Update';
    public static API_OB_PACKDATA_DELETE = BaseURL.apiURL + 'OB/PackData';


    public static API_OB_RECHARGE_GETALL = BaseURL.apiURL + 'OB/Recharge/GetAll';
    public static API_OB_RECHARGE_CREATE = BaseURL.apiURL + 'OB/Recharge/Create';
    public static API_OB_RECHARGE_UPDATE = BaseURL.apiURL + 'OB/Recharge/Update';
    public static API_OB_RECHARGE_DELETE = BaseURL.apiURL + 'OB/Recharge';
    public static API_REPORT_RECHARGE = BaseURL.apiURL + 'OB/Recharge/GetReportRecharge';
    public static API_CHECKPASS_RECHARGE = BaseURL.apiURL + 'OB/Recharge/CheckPass';
    // Template

    public static API_OB_TEMPLATE_GET = BaseURL.apiURL + 'OB/Template/GetAllTemplate';
    public static API_OB_TEMPLATE_ADD = BaseURL.apiURL + 'OB/Template/CreateTemplate';
    public static API_OB_TEMPLATE_DELETE = BaseURL.apiURL + 'OB/Template/DeleteTemplate';
    public static API_OB_TEMPLATE_UPDATE = BaseURL.apiURL + 'OB/Template/UpdateTemplateName';
    public static API_OB_TEMPLATE_COPY = BaseURL.apiURL + 'OB/Template/CopyTemplate';
    public static API_OB_TEMPLATE_GET_FIELD = BaseURL.apiURL + 'OB/Template/GetAllTemplateField';
    public static API_OB_TEMPLATE_CREATE_FIELD = BaseURL.apiURL + 'OB/Template/CreateTemplateField';
    public static API_OB_TEMPLATE_UPDATE_FIELD = BaseURL.apiURL + 'OB/Template/UpdateTemplateField';
    public static API_OB_TEMPLATE_DELETE_FIELD = BaseURL.apiURL + 'OB/Template/DeleteTemplateField';
    public static API_OB_TEMPLATE_GET_IMPORT_DATA = BaseURL.apiURL + 'OB/Template/GetFileTemplateImportData';

    // CAMPAIGN

    public static API_OB_CAMPAIGN_GET = BaseURL.apiURL + 'OB/Campaign/GetAllCampaign';
    public static API_OB_CAMPAIGN_ADD = BaseURL.apiURL + 'OB/Campaign/CreateCampaign';
    public static API_OB_CAMPAIGN_DELETE = BaseURL.apiURL + 'OB/Campaign/DeleteCampaign';
    public static API_OB_CAMPAIGN_UPDATE = BaseURL.apiURL + 'OB/Campaign/UpdateCampaign';

    // CONTRACT EVALUTION
    public static API_OB_CONTRACT_EVALUTION_SEARCH = BaseURL.apiURL + 'OB/ContractEvalution/Search';
    public static API_OB_CONTRACT_EVALUTION_UPDATE_SALES_STATUS = BaseURL.apiURL + 'OB/ContractEvalution/UpdateSalesStatus';

    // CALL

    public static API_OB_CALL_GET_SELECT2_CAMPAIGN = BaseURL.apiURL + 'OB/Call/GetSelect2AllCampaign';
    public static API_OB_CALL_GET_SELECT2_ACTIVE_CAMPAIGN = BaseURL.apiURL + 'OB/Call/GetSelect2ActiveCampaign';
    public static API_OB_CALL_GET_SELECT2_ACTIVE_CAMPAIGN_AUTOCALL = BaseURL.apiURL + 'OB/Call/GetSelect2ActiveCampaignAutoCall';
    public static API_OB_CALL_GET_NEXT_CUSTOMER = BaseURL.apiURL + 'OB/Call/GetNextCustomer';
    public static API_OB_CALL_GET_OB_SCREEN = BaseURL.apiURL + 'OB/Call/GetOBScreen';
    public static API_OB_CALL_GET_OB_SCREEN_SAVE = BaseURL.apiURL + 'OB/Call/GetOBScreen/SaveOBInfo';
    public static API_OB_CALL_GET_HISTORY = BaseURL.apiURL + 'OB/Call/GetCallHistory';
    public static API_OB_CALL_GET_CUSTOMER_COLUMN = BaseURL.apiURL + 'OB/Call/AllCustomerColumn';
    public static API_OB_CALL_GET_ALL_CUSTOMERS = BaseURL.apiURL + 'OB/Call/GetCallCustomers';
    public static API_OB_CALL_GET_CUSTOMER_BYID = BaseURL.apiURL + 'OB/Call/GetCallCustomerById';
    public static API_OB_CALL_START_AUTODIAL = BaseURL.apiURL + 'OB/Call/StartAutoCall';
    public static API_OB_CALL_GET_CHECK_KEYPHONE = BaseURL.apiURL + 'OB/Call/CheckKeyPhone';

    //OB Report
    public static API_OB_GET_ALL_DEPARTMENT = BaseURL.apiURL + 'OB/Report/GetAllDepartment';
    public static API_OB_REPORT_CUSTOM1 = BaseURL.apiURL + 'OB/Report/GetReportCustom1';
    public static API_OB_REPORT_CUSTOM2 = BaseURL.apiURL + 'OB/Report/GetReportCustom2';
    public static API_OB_EXPORT_REPORT_CUSTOM1 = BaseURL.apiURL + 'OB/Report/ExportReportCustom1';
    public static API_OB_EXPORT_REPORT_CUSTOM2 = BaseURL.apiURL + 'OB/Report/ExportReportCustom2';
    public static API_OB_REPORT_TOTAL_CAMPAIGN = BaseURL.apiURL + 'OB/Report/Total'; // tổng hợp chiến dịch
    public static API_OB_REPORT_TOTAL_CAMPAIGN_FOLLOW_GROUP = BaseURL.apiURL + 'OB/Report/TotalCampaignFollowGroup'; // tổng hợp theo nhóm phân công
    public static API_OB_REPORT_TOTAL_AGENT_CALL_PRODUCTIVITY = BaseURL.apiURL + 'OB/Report/TotalAgentCallProductivity'; // bc năng suất gọi của nhân viên theo múi giờ
    public static API_OB_REPORT_TOTAL_AGENT = BaseURL.apiURL + 'OB/Report/TotalAgent'; // tổng hợp agent
    public static API_OB_REPORT_DETAILS = BaseURL.apiURL + 'OB/Report/Details'; // Chi tiết
    public static API_OB_REPORT_EXPORT_DETAILS = BaseURL.apiURL + 'OB/Report/ExportDetails'; // Export Chi tiết
    public static API_OB_REPORT_STATUS = BaseURL.apiURL + 'OB/Report/GetReportStatus'; // Trạng thái
    public static API_OB_REPORT_EXPORT_STATUS = BaseURL.apiURL + 'OB/Report/ExportReportStatus'; // Export Trạng thái
    public static API_OB_REPORT_GET_CALL_STATUS = BaseURL.apiURL + 'OB/Report/GetAllCallStatus';
    public static API_OB_REPORT_GET_RECORDINGS = BaseURL.apiURL + 'OB/Report/GetRecordingFile';
    public static API_OB_REPORT_EXPORT_TOTAL_AGENT = BaseURL.apiURL + 'OB/Report/ExportTotalAgent'; // Export trạng thái nhân viên

    public static API_OB_REPORT_DETAILS_HISTORY = BaseURL.apiURL + 'OB/Report/DetailsHistory'; // Chi tiết
    // CallHistory

    public static API_OB_CALL_HISTORY_AGENT = BaseURL.apiURL + 'OB/CallHistory/OB/AgentCallHistoryUser';
    public static API_OB_DATASOURCE_EXPORT_TOTAL_AGENT = BaseURL.apiURL + 'OB/DataSource/ExportTotalAgent'; // Export trạng thái nhân viên
    // Datasource - Source - Storage

    public static API_OB_DATASOURCE_TOTAL_AGENT = BaseURL.apiURL + 'OB/DataSource/TotalAgent';

    public static API_OB_DATASOURCE_CREATE = BaseURL.apiURL + 'OB/DataSource/Create';
    public static API_OB_DATASOURCE_GETALL = BaseURL.apiURL + 'OB/DataSource/GetAllDataSource';
    public static API_OB_DATASOURCE_GET = BaseURL.apiURL + 'OB/DataSource/GetDatasourceCampaign';
    public static API_OB_DATASOURCE_UPLOAD = BaseURL.apiURL + 'OB/DataSource/UploadFileCustomer';
    public static API_OB_DATASOURCE_GET_COLUMNS = BaseURL.apiURL + 'OB/DataSource/GetColumnsFileImport';
    public static API_OB_DATASOURCE_GET_BULK = BaseURL.apiURL + 'OB/DataSource/GetAllCustomerBulk';
    public static API_OB_DATASOURCE_CONFIRM_IMPORT = BaseURL.apiURL + 'OB/DataSource/ConfirmImportData';
    public static API_OB_DATASOURCE_GET_GROUP_ASSIGN = BaseURL.apiURL + 'OB/DataSource/GetGroupToAssign';
    public static API_OB_DATASOURCE_ASSIGN_GROUP = BaseURL.apiURL + 'OB/DataSource/AssignToGroup';
    public static API_OB_DATASOURCE_GET_ASSIGN_TO_AGENT = BaseURL.apiURL + 'OB/DataSource/GetAllAssignToAgent';
    public static API_OB_DATASOURCE_ASSIGN_AGENT = BaseURL.apiURL + 'OB/DataSource/AssignToAgent';
    public static API_OB_DATASOURCE_GET_AREA = BaseURL.apiURL + 'OB/DataSource/GetAllArea';
    public static API_OB_DATASOURCE_GET_REPORTDATA = BaseURL.apiURL + 'OB/DataSource/GetReportDataMap';
    public static API_OB_DATASOURCE_GET_REPORTDATA_AGENT = BaseURL.apiURL + 'OB/DataSource/GetReportDataMapAgent';
    public static API_OB_DATASOURCE_GET_REPORTSURVEYRESULTS = BaseURL.apiURL + 'OB/DataSource/GetReportSurveyResults';
    public static API_OB_DATASOURCE_GET_REPORTSURVEYRESULTS_EXPORT = BaseURL.apiURL + 'OB/DataSource/ExportSurveyResults';
    public static API_OB_DATASOURCE_GET_ANSWERS = BaseURL.apiURL + 'OB/DataSource/GetAnswers';

    public static API_OB_DATASOURCE_GET_DATAMAP = BaseURL.apiURL + 'OB/DataSource/GetAllDataMap';
    public static API_OB_DATASOURCE_CONFIRM_DOWLOAD = BaseURL.apiURL + 'OB/DataSource/ConfirmDownLoadData';

    public static API_OB_DATASOURCE_DOWNLOAD = BaseURL.apiURL + 'OB/DataSource/DownloadFileCustomer';
    public static API_OB_DATASOURCE_COLUMNSDATA = BaseURL.apiURL + 'OB/DataSource/GetColumnsData';
    public static API_OB_DATASOURCE_CHECKTABLE = BaseURL.apiURL + 'OB/DataSource/CheckTable';
    public static API_OB_DATASOURCE_ADDDATA = BaseURL.apiURL + 'OB/DataSource/AddData';
    public static API_OB_DATASOURCE_GETREPORTCAMPAIGNS = BaseURL.apiURL + 'OB/DataSource/GetReportCampaigns';

    // OB Group

    public static API_OB_GROUP_CREATE = BaseURL.apiURL + 'OB/Group/Create';
    public static API_OB_GROUPZALO_CREATE = BaseURL.apiURL + 'OB/Group/CreateGroupZalo';
    public static API_OB_GROUP_UPDATE = BaseURL.apiURL + 'OB/Group/Update';
    public static API_OB_GROUP_UPDATEZALO = BaseURL.apiURL + 'OB/Group/UpdateGroupZalo';
    public static API_OB_GROUP_DELETE = BaseURL.apiURL + 'OB/Group/DeleteGroup';
    public static API_OB_GROUP_GETALL = BaseURL.apiURL + 'OB/Group/GetAllGroup';
    public static API_OB_GROUP_GETAGENT = BaseURL.apiURL + 'OB/Group/GetAgentInGroup';
    public static API_OB_GROUP_GETAGENT_ASSIGN_BACK = BaseURL.apiURL + 'OB/Group/GetAgentInGroupToAssignOrBackData';
    public static API_OB_GROUP_BACKDATA = BaseURL.apiURL + 'OB/Group/BackDataToGroup';
    public static API_OB_GROUP_BACKDATA_CAMPAIGN = BaseURL.apiURL + 'OB/Group/BackDataToCampaign';
    public static API_OB_GROUP_GET_IN_CAMPAIGN = BaseURL.apiURL + 'OB/Group/GetAllGroupInCampaign';
    public static API_OB_GROUP_GETAGENT_DATA = BaseURL.apiURL + 'OB/Group/GetAllAgentData';

    // OB Monitor

    public static API_OB_MONITOR_ALL_AGENT = BaseURL.apiURL + 'OB/Monitor/GetAllAgent';

    // OB IVR Record
    public static API_OB_RECORD_GETALL = BaseURL.apiURL + 'OB/IVRRecord/GetAll';
    public static API_OB_RECORD_CREATE = BaseURL.apiURL + 'OB/IVRRecord/Create';
    public static API_OB_RECORD_UPDATE = BaseURL.apiURL + 'OB/IVRRecord/Update';
    public static API_OB_RECORD_DELETE = BaseURL.apiURL + 'OB/IVRRecord';
    public static API_OB_RECORD_LISTEN = BaseURL.apiURL + 'OB/IVRRecord/ ';

    // API ZALO
    public static API_ZALO_GET_CODE_CHALLENGE = BaseURL.apiURL + 'zalo/ZaloConfig/GetCodeChallenge';
    public static API_ZALO_ASYNC_CONTACT = BaseURL.apiURL + 'zalo/ZaloConfig/ContactAsync';
    public static API_ZALO_CONFIG_GET_ALL = BaseURL.apiURL + 'zalo/ZaloConfig/GetAllConfig';
    public static API_ZALO_CONFIG_GET_ALL_BY_QUESTION = BaseURL.apiURL + 'zalo/ZaloConfig/GetAllConfigByQuestion';
    public static API_ZALO_CONFIG_GET_ALL_BY_USER = BaseURL.apiURL + 'zalo/ZaloConfig/GetAllConfigByUser';
    public static API_ZALO_CONFIG_GET_ACCESS_TOKEN = BaseURL.apiURL + 'zalo/ZaloConfig/GetAccessToken';
    public static API_ZALO_CONFIG_CREATE = BaseURL.apiURL + 'zalo/ZaloConfig/Create';
    public static API_ZALO_CONFIG_UPDATE = BaseURL.apiURL + 'zalo/ZaloConfig/Update';
    public static API_ZALO_CONFIG_REMOVE = BaseURL.apiURL + 'zalo/ZaloConfig/Remove';
    public static API_ZALO_CONFIG_ASSIGNSTAFFOA = BaseURL.apiURL + 'zalo/ZaloConfig/AssignStaffOA';
    public static API_ZALO_CONFIG_REFERESHTOKEN = BaseURL.apiURL + 'zalo/ZaloConfig/RefreshToken';
    public static API_ZALO_SEND_CONSENT = BaseURL.apiURL + 'zalo/ZaloZNS/SendConsent';
    public static API_ZALO_CHECK_CONSENT_BY_ID = BaseURL.apiURL + 'zalo/ZaloZNS/CheckConsentById';
    public static API_ZALO_CHECK_CONSENT_BY_PHONE = BaseURL.apiURL + 'zalo/ZaloZNS/CheckConsentByPhone';

    // Zalo Group
    public static API_ZALOMANAGER_GETALLQUEUE = BaseURL.apiURL + 'zalo/ZaloManager/GetAllQueue';
    public static API_ZALOMANAGER_POSTGETALLQUEUE = BaseURL.apiURL + 'zalo/ZaloManager/PostGetAllQueue';
    public static API_ZALOMANAGER_CREATEQUEUE = BaseURL.apiURL + 'zalo/ZaloManager/CreateQueue';
    public static API_ZALOMANAGER_UPDATEQUEUE = BaseURL.apiURL + 'zalo/ZaloManager/UpdateQueue';
    public static API_ZALOMANAGER_DELETE = BaseURL.apiURL + 'zalo/ZaloManager/DeleteQueue';

    // ZaloChat
    public static API_ZaloChat_TAGS_OF_OA = BaseURL.apiURL + 'zalo/ZaloChat/GetTagsOfOA';
    public static API_ZaloChat_TAG_FOLLOWER = BaseURL.apiURL + 'zalo/ZaloChat/TagFollower';
    public static API_ZaloChat_RM_FOLLOWER_FROM_TAG = BaseURL.apiURL + 'zalo/ZaloChat/RMFollowerFromTag';
    public static API_ZaloChat_RM_TAG = BaseURL.apiURL + 'zalo/ZaloChat/RMTag';
    public static API_ZaloChat_GET_TAG_USER = BaseURL.apiURL + 'zalo/ZaloChat/GetTagUser';
    public static API_ZaloChat_LAYDANHSACH_HOITHOAI_GANNHAT = BaseURL.apiURL + 'zalo/ZaloChat/LayDanhSachCacHoiThoaiGanNhat';
    public static API_ZaloChat_LAYDANHSACH_HOITHOAI_GANNHAT_NEW = BaseURL.apiURL + 'zalo/ZaloChat/LayDanhSachCacHoiThoaiGanNhatNew';
    public static API_ZaloChat_KIEMTRA_TINNHAN_TUVAN_48H = BaseURL.apiURL + 'zalo/ZaloChat/KiemTraTinNhanTuVan48h';
    public static API_ZaloChat_KIEMTRA_TINNHAN_TUVAN_MIENPHI = BaseURL.apiURL + 'zalo/ZaloChat/KiemTraTinNhanTuVanMienPhi';
    public static API_ZaloChat_LDS_HOITHOAI_VOI_NGUOIDUNG = BaseURL.apiURL + 'zalo/ZaloChat/LayDanhSachCacHoiThoaiVoiNguoiDung';
    public static API_ZaloChat_GETLISTZNS = BaseURL.apiURL + 'zalo/ZaloChat/GetListZNS';
    public static API_ZaloChat_SENDTEXT = BaseURL.apiURL + 'zalo/ZaloChat/SendText';
    public static API_ZaloChat_SENDIMAGEURL = BaseURL.apiURL + 'zalo/ZaloChat/SendImageUrl';
    public static API_ZaloChat_UPLOAD_SEEN_CHAT = BaseURL.apiURL + 'zalo/ZaloChat/UpdateSeenChat';
    public static API_ZaloChat_UPLOADIMAGE_FOR_OFFICIAL_ACCOUNTAPI = BaseURL.apiURL + 'zalo/ZaloChat/UploadImageForOfficialAccountAPI';
    public static API_ZaloChat_UPLOADFILE_FOR_OFFICIAL_ACCOUNTAPI = BaseURL.apiURL + 'zalo/ZaloChat/UploadFileForOfficialAccountAPI';
    public static API_ZaloChat_UPLOADGIF_FOR_OFFICIAL_ACCOUNTAPI = BaseURL.apiURL + 'zalo/ZaloChat/UploadGifForOfficialAccountAPI';

    public static API_ZaloChat_SENDIMAGEGIF = BaseURL.apiURL + 'zalo/ZaloChat/SendImageGif';
    public static API_ZaloChat_SENDIMAGEATTACH = BaseURL.apiURL + 'zalo/ZaloChat/SendImageAttach';
    public static API_ZaloChat_SENDFILE_TO_USERID = BaseURL.apiURL + 'zalo/ZaloChat/SendFileToUserId';
    public static API_ZaloChat_LAY_TINNHAN_MOINHAT_VOI_NGUOIDUNG = BaseURL.apiURL + 'zalo/ZaloChat/LayTinHoiThoaiMoiNhatVoiNguoiDung';

    public static API_ZALO_FCM_TOKEN = BaseURL.apiURL + 'zalo/ZaloConfig/GetAccessTokenFCM';
    public static API_ZALO_SET_OA_READYCHAT = BaseURL.apiURL + 'zalo/ZaloConfig/SetOAReadyChat';
    public static API_ZALO_SET_OA_PAUSECHAT = BaseURL.apiURL + 'zalo/ZaloConfig/SetOAPauseChat';
    public static API_ZALO_GET_ACCOUNT_OA_INFO = BaseURL.apiURL + 'zalo/ZaloConfig/GetAccountOAInfo';
    public static API_ZaloChat_TESTWEBHOOK = BaseURL.apiURL + 'zalo/ZaloChat/TestWebhook';
    public static API_ZaloChat_TESTNOTIFY = BaseURL.apiURL + 'zalo/ZaloChat/TestNotify';
    public static API_ZaloChat_TESTNOTIFY_ZNS = BaseURL.apiURL + 'zalo/ZaloZNS/TestNotifyZNS';

    //Zalo report
    public static API_ZALO_REPORT_ZNS_DETAIL = BaseURL.apiURL + 'zalo/ZaloReport/ReportDetailZNS';
    public static API_ZALO_REPORT_ZNS_BOOKING = BaseURL.apiURL + 'zalo/ZaloReport/ReportBookingZNS';
    public static API_ZALO_REPORT_ZNS_BOOKING_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportBookingZNS';
    public static API_ZALO_REPORT_ZNS_VOTE = BaseURL.apiURL + 'zalo/ZaloReport/ReportVoteZNS';
    public static API_ZALO_REPORT_ZNS_VOTE_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportVoteZNS';
    public static API_ZALO_REPORT_ZNS_TEXT = BaseURL.apiURL + 'zalo/ZaloReport/ReportTextZNS';
    public static API_ZALO_REPORT_ZNS_TEXT_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportTextZNS';
    public static API_ZALO_REPORT_ZNS_ERROR = BaseURL.apiURL + 'zalo/ZaloReport/ReportErrorZNS';
    public static API_ZALO_REPORT_ZNS_ERROR_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportErrorZNS';
    public static API_ZALO_REPORT_ZNS_DETAIL_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportDetailZNS';
    public static API_REPORT_TOTALRATE_INDEX = BaseURL.apiURL + 'zalo/ZaloReport/TotalRateIndex';
    public static API_REPORT_PERCENTRATE_INDEX = BaseURL.apiURL + 'zalo/ZaloReport/GetPercentRate';
    public static API_REPORT_ZNSBOOKINGBYDAY = BaseURL.apiURL + 'zalo/ZaloReport/GetReportZNSBookingByDay';
    public static API_REPORT_TOTALZALO = BaseURL.apiURL + 'zalo/ZaloReport/GetTotalZalo';
    public static API_REPORT_CHAT_DETAILS = BaseURL.apiURL + 'zalo/ZaloReport/GetReportChatDetails';
    public static API_REPORT_CHAT_TOTAL = BaseURL.apiURL + 'zalo/ZaloReport/GetReportChatTotal';
    public static API_CHAT_View_DETAILS = BaseURL.apiURL + 'zalo/ZaloReport/GetChatViewDetails';
    public static API_REPORT_CHAT_DETAILS_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportChatDetail';
    public static API_REPORT_CHAT_DETAILS_Total = BaseURL.apiURL + 'zalo/ZaloReport/ExportReportChatTotal';

    //Zalo Question
    public static API_ZALO_QUESTION_GETALL = BaseURL.apiURL + 'zalo/ZaloQuestion/GetAll';
    public static API_ZALO_QUESTION_CREATE = BaseURL.apiURL + 'zalo/ZaloQuestion/CreateQuestion';
    public static API_ZALO_QUESTION_UPDATE = BaseURL.apiURL + 'zalo/ZaloQuestion/UpdateQuestion';
    public static API_ZALO_QUESTION_DELETE = BaseURL.apiURL + 'zalo/ZaloQuestion/DeleteQuestion';
    public static API_ZALO_QUESTION_GETQUESTION = BaseURL.apiURL + 'zalo/ZaloQuestion/GetQuestion';



    //Zalo Zns Dynamic
    public static API_ZALO_REPORT_ZNS_DYNAMIC_DETAIL = BaseURL.apiURL + 'zalo/ZaloReport/ReportDynamicZNS';
    public static API_ZALO_REPORT_ZNS_DYNAMIC_DETAIL_EXPORT = BaseURL.apiURL + 'zalo/ZaloReport/ReportDynamicZNSExport';

    //Facebook Chat
    // public static API_ZALO_GET_CODE_CHALLENGE = BaseURL.apiURL + 'FacebookChat/GetCodeChallenge';
    // public static API_ZALO_ASYNC_CONTACT = BaseURL.apiURL + 'FacebookChat/ContactAsync';
    public static API_FACEBOOK_GET_ALL = BaseURL.apiURL + 'FacebookChat/GetAllConfig';
    public static API_FACEBOOK_GET_ALL_BY_USER = BaseURL.apiURL + 'FacebookChat/GetAllConfigByUser';
    public static API_FACEBOOK_GET_ALL_BY_QUESTION = BaseURL.apiURL + 'FacebookChat/GetAllConfigByQuestion';
    //public static API_ZALO_CONFIG_GET_ACCESS_TOKEN = BaseURL.apiURL + 'FacebookChat/GetAccessToken';

    //Facebook Config
    public static API_FACEBOOK_GET_CODE_CHALLENGE = BaseURL.apiURL + 'FacebookConfig/GetCodeChallenge';
    public static API_FACEBOOK_ASYNC_CONTACT = BaseURL.apiURL + 'FacebookConfig/ContactAsync';
    public static API_FACEBOOK_CONFIG_GET_ALL = BaseURL.apiURL + 'FacebookConfig/GetAllConfig';
    public static API_FACEBOOK_CONFIG_GET_ALL_BY_QUESTION = BaseURL.apiURL + 'FacebookConfig/GetAllConfigByQuestion';
    public static API_FACEBOOK_CONFIG_GET_ALL_BY_USER = BaseURL.apiURL + 'FacebookConfig/GetAllConfigByUser';
    public static API_FACEBOOK_CONFIG_GET_ACCESS_TOKEN = BaseURL.apiURL + 'FacebookConfig/GetAccessToken';
    public static API_FACEBOOK_CONFIG_CREATE = BaseURL.apiURL + 'FacebookConfig/Create';
    public static API_FACEBOOK_CONFIG_UPDATE = BaseURL.apiURL + 'FacebookConfig/Update';
    public static API_FACEBOOK_CONFIG_REMOVE = BaseURL.apiURL + 'FacebookConfig/Remove';
    public static API_FACEBOOK_CONFIG_ASSIGNSTAFFPage = BaseURL.apiURL + 'FacebookConfig/AssignStaffPage';
    public static API_FACEBOOK_CONFIG_REFERESHTOKEN = BaseURL.apiURL + 'FacebookConfig/RefreshToken';

    public static API_FACEBOOK_LIST_CHAT = BaseURL.apiURL + 'FacebookChat/GetListChatFacebook';
    public static API_FACEBOOK_LIST_CHAT_WITH_USER = BaseURL.apiURL + 'FacebookChat/GetListChatWithUserFacebook';
    public static API_FACEBOOK_LIST_CHAT_WITH_USER_PAGGING = BaseURL.apiURL + 'FacebookChat/GetListChatWithUserPaggingFacebook';
    public static API_FACEBOOK_LATEST_CHAT_WITH_USER = BaseURL.apiURL + 'FacebookChat/GetLatestChatWithUserFacebook';
    public static API_FACEBOOK_SEEN_CHAT = BaseURL.apiURL + 'FacebookChat/UpdateSeenChat';
    public static API_FACEBOOK_CHAT_SENDTEXT = BaseURL.apiURL + 'FacebookChat/SendFacebookText';
    public static API_FACEBOOK_CHAT_SENDIMAGE = BaseURL.apiURL + 'FacebookChat/SendImageForFacebook';
    public static API_FACEBOOK_CHAT_SENDFILE = BaseURL.apiURL + 'FacebookChat/SendFileForFacebook';
    public static API_FACEBOOK_CHAT_TESTWEBHOOK = BaseURL.apiURL + 'FacebookChat/TestWebhook';
    public static API_FACEBOOK_CHAT_TESTNOTIFY = BaseURL.apiURL + 'FacebookChat/TestNotify';


    // public static API_FACEBOOK_PAGE_TOKEN = BaseURL.apiURL + 'FacebookChat/GetAccessTokenPage';
    public static API_FACEBOOK_SET_PAGE_READYCHAT = BaseURL.apiURL + 'FacebookChat/SetPageReadyChat';
    public static API_FACEBOOK_SET_PAGE_PAUSECHAT = BaseURL.apiURL + 'FacebookChat/SetPagePauseChat';
    public static API_FACEBOOK_GET_ACCOUNT_PAGE_INFO = BaseURL.apiURL + 'FacebookChat/GetAccountPageInfo';


    //Facebook Question
    public static API_FACEBOOK_QUESTION_GETALL = BaseURL.apiURL + 'FacebookQuestion/GetAll';
    public static API_FACEBOOK_QUESTION_CREATE = BaseURL.apiURL + 'FacebookQuestion/CreateQuestion';
    public static API_FACEBOOK_QUESTION_UPDATE = BaseURL.apiURL + 'FacebookQuestion/UpdateQuestion';
    public static API_FACEBOOK_QUESTION_DELETE = BaseURL.apiURL + 'FacebookQuestion/DeleteQuestion';
    public static API_FACEBOOK_QUESTION_GETQUESTION = BaseURL.apiURL + 'FAcebookQuestion/GetQuestion';

    //Facebook Report
    public static API_FACEBOOK_REPORT_CHAT_DETAILS = BaseURL.apiURL + 'FacebookReport/GetReportChatDetails';
    public static API_FACEBOOK_REPORT_CHAT_TOTAL = BaseURL.apiURL + 'FacebookReport/GetReportChatTotal';
    public static API_FACEBOOK_CHAT_View_DETAILS = BaseURL.apiURL + 'FacebookReport/GetChatViewDetails';
    public static API_FACEBOOK_REPORT_CHAT_DETAILS_EXPORT = BaseURL.apiURL + 'FacebookReport/ExportReportChatDetail';
    public static API_FACEBOOK_REPORT_CHAT_DETAILS_Total = BaseURL.apiURL + 'FacebookReport/ExportReportChatTotal';

    // EmailConfiguration
    public static API_EmailConfiguration_GETALL = BaseURL.apiURL + 'email/EmailConfiguration/GetAll';
    public static API_EmailConfiguration_CREATE = BaseURL.apiURL + 'email/EmailConfiguration/Create';
    public static API_EmailConfiguration_UPDATE = BaseURL.apiURL + 'email/EmailConfiguration/Update';
    public static API_EmailConfiguration_DELETE = BaseURL.apiURL + 'email/EmailConfiguration';
    public static API_EmailConfiguration_RESTORE = BaseURL.apiURL + 'email/EmailConfiguration/Restore';
    public static API_EmailConfiguration_ADDAGENT = BaseURL.apiURL + 'email/EmailConfiguration/AddAgent';

    //Zalo Zns Dynamic Config
    public static API_ZALO_ZNS_DYNAMIC_CONFIG_GETALL = BaseURL.apiURL + 'zalo/ZaloConfig/GetAllConfigZNS';
    public static API_ZALO_ZNS_DYNAMIC_CONFIG_CREATE = BaseURL.apiURL + 'zalo/ZaloConfig/CreateConfigZNS';
    public static API_ZALO_ZNS_DYNAMIC_CONFIG_UPDATE = BaseURL.apiURL + 'zalo/ZaloConfig/UpdateConfigZNS';
    public static API_ZALO_ZNS_DYNAMIC_CONFIG_DELETE = BaseURL.apiURL + 'zalo/ZaloConfig/RemoveConfigZNS';


    // Email
    public static API_EMAIL_GETALLEmailConfiguration = BaseURL.apiURL + 'email/Email/GetAllEmailConfiguration';
    public static API_EMAIL_SEND = BaseURL.apiURL + 'email/Email/Send';
    public static API_EMAIL_REPLY_EMAIL = BaseURL.apiURL + 'email/Email/ReplyEmail';
    public static API_EMAIL_GETALL_EMAIL_INBOX = BaseURL.apiURL + 'email/Email/GetAllEmailInbox';
    public static API_EMAIL_GETALL_EMAIL_INBOX_DELETE = BaseURL.apiURL + 'email/Email/GetAllEmailDelete';
    public static API_EMAIL_REPLY = BaseURL.apiURL + 'email/Email/Reply';
    public static API_EMAIL_GETALL_EMAIL_DELETE = BaseURL.apiURL + 'Email';
    public static API_EMAIL_AGENT_DELETE = BaseURL.apiURL + 'email/Email/RemoveAgent';
    public static API_REPORT_EMAILDETILE_EXPORT = BaseURL.apiURL + 'Report/ReportEmailExport';
    public static API_REPORT_EMAILAGENT_EXPORT = BaseURL.apiURL + 'Report/ExportEmailAgent';
    public static API_REPORT_MAILBOX = BaseURL.apiURL + 'email/Email/GetAllEmailConfiguration';
    public static API_EMAIL_ASSIGN_AGENT = BaseURL.apiURL + 'email/Email/GetAllEmail_Agent';
    public static API_REPORT_EMAILDETAIL = BaseURL.apiURL + 'Report/ReportEmail_Day';
    public static API_REPORT_EMAILAGENT = BaseURL.apiURL + 'Report/ReportEmailAgent';
    public static API_Email_CREATE = BaseURL.apiURL + 'email/Email/Create';
    public static API_EMAIL_CALL_STATUS = BaseURL.apiURL + 'email/Email/GetAction';
    public static API_EMAIL_GETEMAIL_CHECKBOX = BaseURL.apiURL + 'email/Email/GetEmailCheckBox';
    public static API_EMAIL_AGENT = BaseURL.apiURL + 'email/Email/GetEmailAgent';
    public static API_EMAIL_ALLOCATION = BaseURL.apiURL + 'email/Email/Allotment';
    public static API_TOTAL_EMAIL_SEEN = BaseURL.apiURL + 'email/Email/TotalEmailSeen';
    public static API_EMAILIBOX_UPDATE = BaseURL.apiURL + 'email/Email/Update';
    public static API_EMAILSEND_RECORDING_FILE = BaseURL.apiURL + 'email/Email/DownloadFileName';
    public static API_EMAIL_GETALL_EMAIL_SEND = BaseURL.apiURL + 'email/Email/GetAllEmailSend';
    public static API_EMAIL_GETALL_FILE_EMAIL_INBOX = BaseURL.apiURL + 'email/Email/GetFileEmailInbox';
    public static API_DOWLOAD_FILE_EMAIL_SEND = BaseURL.apiURL + 'email/Email/GetFileEmailInbox';
    public static API_EMAIL_FILE_NAME_EMAIL_INBOX_DB = BaseURL.apiURL + 'email/Email/GetFileInBox_DB';
    public static API_EMAIL_MEMMORY = BaseURL.apiURL + 'email/Email/GetEmailMemmory';
    //
    public static API_EMAIL_FILE_NAME_EMAIL_SEND = BaseURL.apiURL + 'email/Email/GetFileEmailSend';
    public static API_EMAIL_FILE_NAME_EMAIL_SEND_DB = BaseURL.apiURL + 'email/Email/GetFileSend_DB';
    //dowload file mail gui-den
    public static API_DOWLOAD_FILE_EMAIL_INBOX = BaseURL.apiURL + 'email/Email/FileDownload';

    //LineSpring
    public static API_LINESPRING_GETALL = BaseURL.apiURL + 'voice/LineSpring/GetAll';
    public static API_LINESPRING_CREATE = BaseURL.apiURL + 'voice/LineSpring/Create';
    public static API_LINESPRING_UPDATE = BaseURL.apiURL + 'voice/LineSpring/Update';
    public static API_LINESPRING_DELETE = BaseURL.apiURL + 'voice/LineSpring';
    public static API_LINESPRING_ALL_QUEUE = BaseURL.apiURL + 'voice/LineSpring/GetAllQueue';
    public static API_LINESPRING_SYNCHRONIZED = BaseURL.apiURL + 'voice/LineSpring/Synchronized';

    //Setting
    public static API_SETTING_GETDI = BaseURL.apiURL + 'voice/Setting/GetDurationIncreases';
    public static API_SETTING_CREATEDI = BaseURL.apiURL + 'voice/Setting/CreateDurationIncrease';
    public static API_SETTING_GETCA = BaseURL.apiURL + 'voice/Setting/GetListCallAfterHours';
    public static API_SETTING_CREATECA = BaseURL.apiURL + 'voice/Setting/CreateCallAfterHours';

    // Nhóm phụ trách
    public static API_GROUPCHARGE_GETALL = BaseURL.apiURL + 'voice/GroupCharges/GetAll';
    public static API_GROUPCHARGE_CREATE = BaseURL.apiURL + 'voice/GroupCharges/Create';
    public static API_GROUPCHARGE_UPDATE = BaseURL.apiURL + 'voice/GroupCharges/Update';
    public static API_GROUPCHARGE_DELETE = BaseURL.apiURL + 'voice/GroupCharges';

    //PhoneNumber
    public static API_PHONENUMBER_GETALL = BaseURL.apiURL + 'voice/PhoneNumber/GetAll';
    public static API_PHONENUMBER_CREATE = BaseURL.apiURL + 'voice/PhoneNumber/Create';
    public static API_PHONENUMBER_UPDATE = BaseURL.apiURL + 'voice/PhoneNumber/Update';
    public static API_PHONENUMBER_DELETE = BaseURL.apiURL + 'voice/PhoneNumber';
    public static API_PHONENUMBER_ALL_QUEUE = BaseURL.apiURL + 'voice/PhoneNumber/GetAllQueue';
    public static API_PHONENUMBER_SYNCHRONIZED = BaseURL.apiURL + 'voice/PhoneNumber/Synchronized';

    //EmailSendMissCall
    public static API_EMAILSENDMISSCALL_GETALL = BaseURL.apiURL + 'voice/EmailSendMissCall/GetAll';
    public static API_EMAILSENDMISSCALL_DELETE = BaseURL.apiURL + 'voice/EmailSendMissCall/Delete';
    public static API_EMAILSENDMISSCALL_CREATE = BaseURL.apiURL + 'voice/EmailSendMissCall/Create';
    public static API_EMAILSENDMISSCALL_UPDATE = BaseURL.apiURL + 'voice/EmailSendMissCall/Update';

// Mailling Config
public static MAILLINGCONFIG_GETALL = BaseURL.apiURL + 'Mailling/Config/GetAll';
public static MAILLINGCONFIG_CREAT = BaseURL.apiURL + 'Mailling/Config/Create';
public static MAILLINGCONFIG_DELETE = BaseURL.apiURL + 'Mailling/Config/Delete';
public static MAILLINGCONFIG_UPDATE = BaseURL.apiURL + 'Mailling/Config/Update';

// Mailling Campaign
public static MAILLING_CAMPAIGN_GETALL = BaseURL.apiURL + 'Mailling/Campaign/GetAll';
public static MAILLING_CAMPAIGN_GETALL2 = BaseURL.apiURL + 'Mailling/Campaign/GetAll2';
public static MAILLING_CAMPAIGN_CREAT = BaseURL.apiURL + 'Mailling/Campaign/Create';
public static MAILLING_CAMPAIGN_GETCAMPAIGNId = BaseURL.apiURL + 'Mailling/Campaign/GetCampaignId';
public static MAILLING_CAMPAIGN_UPDATE = BaseURL.apiURL + 'Mailling/Campaign/Update';
public static MAILLING_CAMPAIGN_DELETE = BaseURL.apiURL + 'Mailling/Campaign/Delete';

// Mailling Send
public static MAILLING_SEND_GETEXCELBULKDATA = BaseURL.apiURL + 'Mailling/Source/GetExcelBulkData';
public static MAILLING_SEND_STARTSENDMAIL = BaseURL.apiURL + 'Mailling/StartSendMail';
public static MAILLING_SEND_STOPSENDMAIL = BaseURL.apiURL + 'Mailling/StopSendMail';

// Mailling Source
public static MAILLING_SOURCE_GETSELECTCAMPAIGN = BaseURL.apiURL + 'Mailling/Source/GetSelectCampaign';
public static MAILLING_SOURCE_TEMPLATE = BaseURL.apiURL + 'Mailling/Source/Template';
public static MAILLING_SOURCE_GETALLCUSTOMER = BaseURL.apiURL + 'Mailling/Source/GetAllCustomer';
public static MAILLING_SOURCE_UPLOAdFILE = BaseURL.apiURL + 'Mailling/Source/UploadFile';
public static MAILLING_SOURCE_GETCOLUMNSFILEIMPORT = BaseURL.apiURL + 'Mailling/Source/GetColumnsFileImport';
public static MAILLING_SOURCE_GETAllCUSTOMERBULK = BaseURL.apiURL + 'Mailling/Source/GetAllCustomerBulk';
public static MAILLING_SOURCE_SAVE = BaseURL.apiURL + 'Mailling/Source/SaveDataSource';
public static MAILLING_SOURCE_ALLATTACH = BaseURL.apiURL + 'Mailling/Source/GetAllAttach';
public static MAILLING_SOURCE_UPLOAdFILEATTACH = BaseURL.apiURL + 'Mailling/Source/UploadFileAttach';
public static MAILLING_SOURCE_DELETE_FILEATTACH = BaseURL.apiURL + 'Mailling/Source/DeleteAttach';
public static MAILLING_SOURCE_DELETE_CUSTOMER = BaseURL.apiURL + 'Mailling/DeleteCustomer';


// Mailling report
public static MAILLING_REPORT_TOTAL = BaseURL.apiURL + 'Mailling/Report/Total';


// KMS
//FOLDER
public static KMS_FOLDER_GETALL = BaseURL.apiURL + 'KMS/Posts/GetAllFolder';
public static KMS_FOLDER_CREATE = BaseURL.apiURL + 'KMS/Posts/CreateFolder';
public static KMS_FOLDER_DELETE = BaseURL.apiURL + 'KMS/Posts/Folder/Delete';
public static KMS_FOLDER_UPDATE = BaseURL.apiURL + 'KMS/Posts/Folder/Update';
public static KMS_FOLDER_LIST = BaseURL.apiURL + 'KMS/Posts/GetAllFolderList';
public static KMS_FOLDER_CREATE_FOLDERCHILDREN = BaseURL.apiURL + 'KMS/Posts/CreateFolderChildrens';

//Post
public static KMS_POST_GETALL = BaseURL.apiURL + 'KMS/Posts/GetAllPosts';
public static KMS_POST_CREATE = BaseURL.apiURL + 'KMS/Posts/CreatePots';
public static KMS_POST_CREATE_FILE = BaseURL.apiURL + 'KMS/Posts/CreatePotsFile';
public static KMS_POST_DOWNLOAD_FILE = BaseURL.apiURL + 'KMS/Posts/GetDownLoadFile';
public static KMS_POST_DELETE = BaseURL.apiURL + 'KMS/Posts/DeletePost';
public static KMS_POST_UPDATE = BaseURL.apiURL + 'KMS/Posts/UpdatePost';
public static KMS_POST_UPDATEVIEW = BaseURL.apiURL + 'KMS/Posts/UpdateView';
public static KMS_POST_UPDATEDOWNLOAD = BaseURL.apiURL + 'KMS/Posts/UpdateDownLoad';
public static KMS_POST_GETALLNEWPOST = BaseURL.apiURL + 'KMS/Posts/GetAllNewPosts';
public static KMS_POST_GETALLVIEWPOST = BaseURL.apiURL + 'KMS/Posts/GetAllViewPosts';


}
