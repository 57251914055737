import { Injectable } from "@angular/core";
import { onConsoleLog } from "src/app/utils/helpers";
import { environment } from "src/environments/environment";

declare var Paho: any;

@Injectable({
    providedIn: 'root'
})
export class RabbitMQService {

    client!: any;
    reconnect: boolean = false;
    public isConnected: boolean = false;

    constructor(

    ) { }

    onMessages(callback: Function) {
        this.client.onMessageArrived = (message: any) => callback(message);
    }

    onConnectionLost(responseObject: any) {
        if (responseObject.errorCode !== 0) {
            this.isConnected = false;
            localStorage.removeItem('rabbimq');
            onConsoleLog('onConnectionLost RABBITMQ', `CONNECTION LOST - ${responseObject.errorMessage}`);
        }
    }

    onConnected(responseObject: any) {
        this.reconnect = responseObject;
    }

    OnMessageDelivered(responseObject: any) {
        console.log('responseObject', responseObject);
    }

    onDisconnected() {
        if (this.isConnected) {
            this.isConnected = false;
            localStorage.removeItem('rabbimq');
            this.client.disconnect();
        }
    }

    onStart(host: string, port: number = 15676 , clientId: string, userName: string, pwd: string) {
        try {
            if (environment.production && location.protocol == "https:") {
                port = 15676;
            }
            this.client = new Paho.MQTT.Client(host, port, '/ws', clientId);
            // set callback handlers
            this.client.onConnectionLost = this.onConnectionLost;
            this.client.onConnected = this.onConnected;
            this.client.OnMessageDelivered = this.OnMessageDelivered;
            var options = {
                timeout: 5,
                keepAliveInterval: 120,
                userName: userName,
                password: pwd,
                cleanSession: true,
                reconnect: true,
                //useSSL: location.protocol == "https:" && environment.production,
                useSSL: true,
                onSuccess: () => {
                    onConsoleLog('onSuccess RABBITMQ', "CONNECTION SUCCESS");
                    this.isConnected = true;
                    localStorage.setItem('rabbimq', 'true');
                    this.client.subscribe('my/call/data/details');
                },
                onFailure: (message: any) => {
                    this.isConnected = false;
                    localStorage.removeItem('rabbimq');
                    onConsoleLog('onFailure RABBITMQ', `CONNECTION FAILURE - ${message.errorMessage}`);
                }
            };
            // connect the client
            this.client.connect(options);
        } catch (e: unknown) {
            if (typeof e === "string") {
                console.log(e.toUpperCase());
            } else if (e instanceof Error) {
                console.log(e.message);
            }
        }
    }
}
