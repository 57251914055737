import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Select2OptionData } from "ng-select2";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { IDateSelectFilter } from "../shared/models/agent.models";
import { DateSelect, ICalendarConvert } from "../shared/models/calendar.models";
import { IAction } from "../shared/models/ivr.models";

declare var Date: any;
declare var $: any;

export function errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (error.status == 520) {
        return [];
    }
    //return  throwError(errorMessage);
    return throwError({ errorCode: error.status, errorMessage: error.message, errorText: error.error });
}


@Injectable()
export class CustomPaginatorIntlTranslate extends MatPaginatorIntl {
    constructor(private translate: TranslateService) {
        super();
        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translate.onLangChange.subscribe(() => {
            this.itemsPerPageLabel = this.translate.instant('items_per_page');
            this.nextPageLabel = this.translate.instant('next_page');
            this.previousPageLabel = this.translate.instant('previous_page');
            this.changes.next();
        });
        this.itemsPerPageLabel = this.translate.instant('items_per_page');
        this.nextPageLabel = this.translate.instant('next_page');
        this.previousPageLabel = this.translate.instant('previous_page');
        this.changes.next();
    }

}


export function CustomPaginator(value: string) {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = value;
    customPaginatorIntl.nextPageLabel = value;
    customPaginatorIntl.previousPageLabel = value;

    return customPaginatorIntl;
}


export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}


export class ServerId {
    static start() {
        return '4xxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}


export class ZaloVerifierId {
    static start() {
        return 'xxxxxxxx-xxxx-xxx4xxx-yxxx-xxxxxxxxxxxx-xxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 32 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(32);
        });
    }
}

export class ZaloState {
    static start() {
        return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 32 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(32);
        });
    }
}

export class IVRAction {
    static newAction() {
        const objAction: IAction = {
            id: undefined,
            idAction: Guid.newGuid(),
            name: '',
            ivrId: '',
            actionType: 0,
            actionCode: 0, // 1 phím bấm , 2 trực thuộc
            parentId: '',
            numpad: '',
            ivR_FileThongBao: '',
            ivR_SoLanPhatThongBao: 0,
            ivR_ThoiGianCho: 0,
            ivR_FileThongBaoKhiBamSaiPhim: '',
            ivR_SoLanToiDaBamSaiPhim: 0,
            tG_FileThongBao: '',
            tG_IsGroup: false,
            tG_GroupName: '',
            tG_FileNhacCho: '',
            tG_FileThongBaoBan: '',
            tG_PhatThongBaoBanSau: 0,
            tG_SoLanPhatThongBaoBan: 0,
            tM_FileThongBao: '',
            tM_FileNhacCho: '',
            tM_FileThongBaoBan: '',
            tM_PhatThongBaoBanSau: 0,
            tM_SoLanPhatThongBaoBan: 0,
            tM_Mobiles: [],
            vM_FileThongBao: '',
            vM_Mail: '',
            vM_Pin: '',
            vM_MailThongBao: '',
            eD_FileThongBao: '',
            childrens: [],
            subActionCode: 0
        } as IAction;

        return objAction;
    }
}


export function onGetTypeVB(type: string) {
    let dow: string = 'Không xác định';
    switch (type) {
        case '1':
            dow = 'Giờ';
            break;
        case '2':
            dow = 'Ngày';
            break;
        case '3':
            dow = 'Tháng';
            break;
        case '4':
            dow = 'Năm';
            break;
    }
    return dow;
}

export function onConvertToDayOfWeek(dayOfWeek: string) {
    let dow: string = dayOfWeek;
    switch (dayOfWeek) {
        case 'Hai':
            dow = 'T2';
            break;
        case 'Ba':
            dow = 'T3';
            break;
        case 'Tư':
            dow = 'T4';
            break;
        case 'Năm':
            dow = 'T5';
            break;
        case 'Sáu':
            dow = 'T6';
            break;
        case 'Bảy':
            dow = 'T7';
            break;
    }
    return dow;
}

export function onConvertToStatusName(status: string) {
    let dow: string = status;
    switch (status) {
        case 'war-6':
            dow = 'Supervisor đặt trạng thái sẵn sàng';
            break;
        case 'blue-1':
            dow = 'Sẵn sàng';
            break;
        case 'pause-1':
            dow = 'Nghỉ trưa';
            break;
        case 'pause-2':
            dow = 'Email';
            break;
        case 'pause-3':
            dow = 'Đi họp';
            break;
        case 'pause-4':
            dow = 'Ra ngoài';
            break;
        case 'pause-5':
            dow = 'Việc riêng';
            break;
        case 'pause-6':
            dow = 'Ăn tối';
            break;
        case 'pause-7':
            dow = 'Nhập liệu';
            break;
        case 'gre-1':
            dow = 'Đăng nhập hệ thống';
            break;
        case 'war-1':
            dow = 'Supervisor loại khỏi nhóm';
            break;
        case 'war-2':
            dow = 'Tạm dừng bởi giám sát';
            break;
        case 'war-3':
            dow = 'Rời nhóm';
            break;
        case 'war-4':
            dow = 'Vào nhóm';
            break;
        case 'war-5':
            dow = 'Người dùng yêu cầu logout';
            break;
        case 'red-1':
            dow = 'Đăng xuất hệ thống';
            break;
        case 'gre-2':
            dow = 'Chưa sẵn sàng';
            break;
    }
    return dow;
}


export function onConvertClassName(status: string) {
    let dow: string = 'badge-success';
    switch (status) {
        case 'pause-1':
        case 'pause-2':
        case 'pause-3':
        case 'pause-4':
        case 'pause-5':
        case 'pause-6':
        case 'pause-7':
        case 'war-1':
        case 'war-2':
        case 'war-3':
        case 'war-4':
        case 'war-5':
        case 'war-6':
            dow = 'badge-danger';
            break;
        case 'blue-1':
            dow = 'badge-success';
            break;
        case 'gre-1':
        case 'red-1':
        case 'gre-2':
            dow = 'bg-light-1';
            break;
    }
    return dow;
}


export function onConvert2DayOfWeek(dateInput: Date): number {
    let dayOfWeek = new Date(dateInput).toLocaleString('en-US', { weekday: 'long' });
    let results: number = 0;
    switch (dayOfWeek) {
        case 'Monday': //Monday = 1,
            results = 1;
            break;
        case 'Tuesday': //Tuesday = 2,
            results = 2;
            break;
        case 'Wednesday': //Wednesday = 3,
            results = 3;
            break;
        case 'Thursday': //Thursday = 4,
            results = 4;
            break;
        case 'Friday': //Friday = 5,
            results = 5;
            break;
        case 'Saturday': //Saturday = 6
            results = 6;
            break;
        default:  //Sunday = 0,
            results = 0;
            break;
    }
    return results;
}

export function onConvertDayOfWeek2Text(dateOfWeek: number): ICalendarConvert {
    let results: ICalendarConvert = {} as ICalendarConvert;
    switch (dateOfWeek) {
        case 1: //Monday = 1,
            results.text = 'T2';
            results.date = getNextDay('monday');
            break;
        case 2: //Tuesday = 2,
            results.text = 'T3';
            results.date = getNextDay('tuesday');
            break;
        case 3: //Wednesday = 3,
            results.text = 'T4';
            results.date = getNextDay('wednesday');
            break;
        case 4: //Thursday = 4,
            results.text = 'T5';
            results.date = getNextDay('thursday');
            break;
        case 5: //Friday = 5,
            results.text = 'T6';
            results.date = getNextDay('friday');
            break;
        case 6: //Saturday = 6
            results.text = 'T7';
            results.date = getNextDay('saturday');
            break;
        default:  //Sunday = 0,
            results.text = 'CN';
            results.date = getNextDay('sunday');
            break;
    }
    return results;
}


function getNextDay(dayName: string) {
    // The current day
    var date = new Date();
    var now = date.getDay();

    // Days of the week
    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    // The index for the day you want
    var day = days.indexOf(dayName.toLowerCase());

    // Find the difference between the current day and the one you want
    // If it's the same day as today (or a negative number), jump to the next week
    var diff = day - now;
    diff = diff < 1 ? 7 + diff : diff;

    // Get the timestamp for the desired day
    var nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);

    // Get the next day
    return new Date(nextDayTimestamp);
}


export function onGetCallAllocation(): any[] | Select2OptionData[] {
    return [
        {
            id: 'ringall',
            text: 'Đồng thời',
            additional: {
                description: 'Cuộc gọi được đổ chuông đồng thời tới tất cả các nhân viên và sẽ phân bổ cho người nhấc máy đầu tiên'
            }
        },
        {
            id: 'rrmemory',
            text: 'Xoay vòng',
            additional: {
                description: 'Cuộc gọi được được phân bổ đến lần lượt từng nhân viên'
            }
        },
        {
            id: 'leastrecent',
            text: 'Rảnh lâu nhất',
            additional: {
                description: 'Cuộc gọi được phân bổ tới nhân viên có thời gian rảnh lâu nhất'
            }
        },
        {
            id: 'fewestcalls',
            text: 'Ít cuộc gọi nhất',
            additional: {
                description: 'Cuộc gọi phân bổ cho nhân viên có ít cuộc gọi nhất'
            }
        },
        {
            id: 'random',
            text: 'Ngẫu nhiên',
            additional: {
                description: 'Cuộc gọi được phân bổ ngẫu nhiên đến 1 nhân viên'
            }
        },
        {
            id: 'linear',
            text: 'Tuần tự',
            additional: {
                description: 'Cuộc gọi được phân bổ tuần tự đến nhân viên'
            }
        }
    ]
}



export function onGetAllDirection(): any[] | Select2OptionData[] {
    return [
        {
            id: '0',
            text: 'Gọi vào'
        },
        {
            id: '1',
            text: 'Gọi ra'
        },
        {
            id: '2',
            text: 'Cả gọi ra, gọi vào'
        }
    ]
}


export function onGetCallStatus(): any[] | Select2OptionData[] {
    return [
        {
            id: '1',
            text: 'Trả lời'
        },
        {
            id: '2',
            text: 'Không trả lời'
        },
        {
            id: '3',
            text: 'Gọi nhỡ'
        },
        {
            id: '4',
            text: 'Gọi đến'
        },
        {
            id: '5',
            text: 'Gọi đi'
        },
        {
            id: '6',
            text: 'Gọi nội bộ'
        }
    ]
}

export function onGetAllDirectionStr(type: number): any | string {
    var strDirection: string = '';
    switch (type) {
        case 0:
            strDirection = 'Gọi vào';
            break;
        case 1:
            strDirection = 'Gọi ra';
            break;
        default:
            strDirection = 'Gọi vào & gọi ra';
            break;
    }
    return strDirection;
}

export function onGetDateTimeFType(type: string): IDateSelectFilter {
    let dateSelect: IDateSelectFilter = {} as IDateSelectFilter;
    switch (type) {
        case 'today':
            dateSelect.dateFrom = Date.today().toString('dd/MM/yyyy');
            dateSelect.dateTo = Date.today().toString('dd/MM/yyyy');
            dateSelect.dateChoose = Date.today().toString('dddd, Ngày dd MMMM, yyyy');
            break;
        case 'yesterday':
            dateSelect.dateFrom = Date.today().addDays(-1).toString('dd/MM/yyyy');
            dateSelect.dateTo = Date.today().addDays(-1).toString('dd/MM/yyyy');
            dateSelect.dateChoose = Date.today().addDays(-1).toString('dddd, Ngày dd MMMM, yyyy');
            break;
        case 'week':
            dateSelect.dateFrom = Date.today().addDays(-7).toString('dd/MM/yyyy');
            dateSelect.dateTo = Date.today().toString('dd/MM/yyyy');
            dateSelect.dateChoose = `Từ ${Date.today().addDays(-7).toString('dd/MM/yyyy')} đến ${Date.today().toString('dd/MM/yyyy')}`;
            break;
        case 'month':
            dateSelect.dateFrom = Date.today().moveToFirstDayOfMonth().toString('dd/MM/yyyy');
            dateSelect.dateTo = Date.today().moveToLastDayOfMonth().toString('dd/MM/yyyy');
            dateSelect.dateChoose = `Từ ${Date.today().moveToFirstDayOfMonth().toString('dd/MM/yyyy')} đến ${Date.today().moveToLastDayOfMonth().toString('dd/MM/yyyy')}`;
            break;
    }

    return dateSelect;
}

export function removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|-|{|}|\||\\/g, " ");
    str = str.replace(/\s+/g, "_");
    return str.toLowerCase();
}

export function getRandomColor(length: number): string[] | any[] {
    var letters = '0123456789ABCDEF';
    var color = '';
    var _colors: string[] | any[] = [];

    for (var i = 0; i < length; i++) {

        const randomArray = Array.from(
            { length: 6 },
            (v, k) => letters[Math.floor(Math.random() * letters.length)]
        );
        color = "#" + randomArray.join("");
        _colors.push(color);
    }

    return _colors;
}

export function onConsoleLog(eventName: string, msg: any) {
    if (!environment.production) {
        console.log(`*** Thông báo event: ${eventName}`);
        console.log(msg);
    }
}

export function lowerFirstLetter(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

var diacritics: any =
{
    'a': ['a', 'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ā', 'ă', 'ą', 'ǎ', 'ǟ', 'ǡ', 'ǻ', 'ȁ', 'ȃ', 'ȧ', 'ɐ', 'ɑ', 'ɒ', 'ͣ', 'а', 'ӑ', 'ӓ', 'ᵃ', 'ᵄ', 'ᶏ', 'ḁ', 'ẚ', 'ạ', 'ả', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'ₐ', 'ⱥ', 'ａ'],
    'A': ['A', 'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Ā', 'Ă', 'Ą', 'Ǎ', 'Ǟ', 'Ǡ', 'Ǻ', 'Ȁ', 'Ȃ', 'Ȧ', 'Ⱥ', 'А', 'Ӑ', 'Ӓ', 'ᴀ', 'ᴬ', 'Ḁ', 'Ạ', 'Ả', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Ａ'],

    'b': ['b', 'ƀ', 'ƃ', 'ɓ', 'ᖯ', 'ᵇ', 'ᵬ', 'ᶀ', 'ḃ', 'ḅ', 'ḇ', 'ｂ'],
    'B': ['B', 'Ɓ', 'Ƃ', 'Ƀ', 'ʙ', 'ᛒ', 'ᴃ', 'ᴮ', 'ᴯ', 'Ḃ', 'Ḅ', 'Ḇ', 'Ｂ'],

    'c': ['c', 'ç', 'ć', 'ĉ', 'ċ', 'č', 'ƈ', 'ȼ', 'ɕ', 'ͨ', 'ᴄ', 'ᶜ', 'ḉ', 'ↄ', 'ｃ'],
    'C': ['C', 'Ç', 'Ć', 'Ĉ', 'Ċ', 'Č', 'Ƈ', 'Ȼ', 'ʗ', 'Ḉ', 'Ｃ'],

    'd': ['d', 'ď', 'đ', 'Ƌ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ͩ', 'ᵈ', 'ᵭ', 'ᶁ', 'ᶑ', 'ḋ', 'ḍ', 'ḏ', 'ḑ', 'ḓ', 'ｄ'],
    'D': ['D', 'Ď', 'Đ', 'Ɖ', 'Ɗ', 'ᴰ', 'Ḋ', 'Ḍ', 'Ḏ', 'Ḑ', 'Ḓ', 'Ｄ'],

    'e': ['e', 'è', 'é', 'ê', 'ë', 'ē', 'ĕ', 'ė', 'ę', 'ě', 'ǝ', 'ȅ', 'ȇ', 'ȩ', 'ɇ', 'ɘ', 'ͤ', 'ᵉ', 'ᶒ', 'ḕ', 'ḗ', 'ḙ', 'ḛ', 'ḝ', 'ẹ', 'ẻ', 'ẽ', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ₑ', 'ｅ'],
    'E': ['E', 'È', 'É', 'Ê', 'Ë', 'Ē', 'Ĕ', 'Ė', 'Ę', 'Ě', 'Œ', 'Ǝ', 'Ɛ', 'Ȅ', 'Ȇ', 'Ȩ', 'Ɇ', 'ɛ', 'ɜ', 'ɶ', 'Є', 'Э', 'э', 'є', 'Ӭ', 'ӭ', 'ᴇ', 'ᴈ', 'ᴱ', 'ᴲ', 'ᵋ', 'ᵌ', 'ᶓ', 'ᶔ', 'ᶟ', 'Ḕ', 'Ḗ', 'Ḙ', 'Ḛ', 'Ḝ', 'Ẹ', 'Ẻ', 'Ẽ', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ｅ', '𐐁', '𐐩'],

    'f': ['f', 'ƒ', 'ᵮ', 'ᶂ', 'ᶠ', 'ḟ', 'ｆ'],
    'F': ['F', 'Ƒ', 'Ḟ', 'ⅎ', 'Ｆ'],

    'g': ['g', 'ĝ', 'ğ', 'ġ', 'ģ', 'ǥ', 'ǧ', 'ǵ', 'ɠ', 'ɡ', 'ᵍ', 'ᵷ', 'ᵹ', 'ᶃ', 'ᶢ', 'ḡ', 'ｇ'],
    'G': ['G', 'Ĝ', 'Ğ', 'Ġ', 'Ģ', 'Ɠ', 'Ǥ', 'Ǧ', 'Ǵ', 'ɢ', 'ʛ', 'ᴳ', 'Ḡ', 'Ｇ'],

    'h': ['h', 'ĥ', 'ħ', 'ƕ', 'ȟ', 'ɥ', 'ɦ', 'ʮ', 'ʯ', 'ʰ', 'ʱ', 'ͪ', 'Һ', 'һ', 'ᑋ', 'ᶣ', 'ḣ', 'ḥ', 'ḧ', 'ḩ', 'ḫ', 'ⱨ', 'ｈ'],
    'H': ['H', 'Ĥ', 'Ħ', 'Ȟ', 'ʜ', 'ᕼ', 'ᚺ', 'ᚻ', 'ᴴ', 'Ḣ', 'Ḥ', 'Ḧ', 'Ḩ', 'Ḫ', 'Ⱨ', 'Ｈ'],

    'i': ['i', 'ì', 'í', 'î', 'ï', 'ĩ', 'ī', 'ĭ', 'į', 'ǐ', 'ȉ', 'ȋ', 'ɨ', 'ͥ', 'ᴉ', 'ᵎ', 'ᵢ', 'ᶖ', 'ᶤ', 'ḭ', 'ḯ', 'ỉ', 'ị', 'ｉ'],
    'I': ['I', 'Ì', 'Í', 'Î', 'Ï', 'Ĩ', 'Ī', 'Ĭ', 'Į', 'İ', 'Ǐ', 'Ȉ', 'Ȋ', 'ɪ', 'І', 'ᴵ', 'ᵻ', 'ᶦ', 'ᶧ', 'Ḭ', 'Ḯ', 'Ỉ', 'Ị', 'Ｉ'],

    'j': ['j', 'ĵ', 'ǰ', 'ɉ', 'ʝ', 'ʲ', 'ᶡ', 'ᶨ', 'ｊ'],
    'J': ['J', 'Ĵ', 'ᴊ', 'ᴶ', 'Ｊ'],

    'k': ['k', 'ķ', 'ƙ', 'ǩ', 'ʞ', 'ᵏ', 'ᶄ', 'ḱ', 'ḳ', 'ḵ', 'ⱪ', 'ｋ'],
    'K': ['K', 'Ķ', 'Ƙ', 'Ǩ', 'ᴷ', 'Ḱ', 'Ḳ', 'Ḵ', 'Ⱪ', 'Ｋ'],

    'l': ['l', 'ĺ', 'ļ', 'ľ', 'ŀ', 'ł', 'ƚ', 'ȴ', 'ɫ', 'ɬ', 'ɭ', 'ˡ', 'ᶅ', 'ᶩ', 'ᶪ', 'ḷ', 'ḹ', 'ḻ', 'ḽ', 'ℓ', 'ⱡ'],
    'L': ['L', 'Ĺ', 'Ļ', 'Ľ', 'Ŀ', 'Ł', 'Ƚ', 'ʟ', 'ᴌ', 'ᴸ', 'ᶫ', 'Ḷ', 'Ḹ', 'Ḻ', 'Ḽ', 'Ⱡ', 'Ɫ'],

    'm': ['m', 'ɯ', 'ɰ', 'ɱ', 'ͫ', 'ᴟ', 'ᵐ', 'ᵚ', 'ᵯ', 'ᶆ', 'ᶬ', 'ᶭ', 'ḿ', 'ṁ', 'ṃ', '㎡', '㎥', 'ｍ'],
    'M': ['M', 'Ɯ', 'ᴍ', 'ᴹ', 'Ḿ', 'Ṁ', 'Ṃ', 'Ｍ'],

    'n': ['n', 'ñ', 'ń', 'ņ', 'ň', 'ŉ', 'ƞ', 'ǹ', 'ȵ', 'ɲ', 'ɳ', 'ᵰ', 'ᶇ', 'ᶮ', 'ᶯ', 'ṅ', 'ṇ', 'ṉ', 'ṋ', 'ⁿ', 'ｎ'],
    'N': ['N', 'Ñ', 'Ń', 'Ņ', 'Ň', 'Ɲ', 'Ǹ', 'Ƞ', 'ɴ', 'ᴎ', 'ᴺ', 'ᴻ', 'ᶰ', 'Ṅ', 'Ṇ', 'Ṉ', 'Ṋ', 'Ｎ'],

    'o': ['o', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ō', 'ŏ', 'ő', 'ơ', 'ǒ', 'ǫ', 'ǭ', 'ǿ', 'ȍ', 'ȏ', 'ȫ', 'ȭ', 'ȯ', 'ȱ', 'ɵ', 'ͦ', 'о', 'ӧ', 'ө', 'ᴏ', 'ᴑ', 'ᴓ', 'ᴼ', 'ᵒ', 'ᶱ', 'ṍ', 'ṏ', 'ṑ', 'ṓ', 'ọ', 'ỏ', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ₒ', 'ｏ', '𐐬'],
    'O': ['O', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'Ō', 'Ŏ', 'Ő', 'Ɵ', 'Ơ', 'Ǒ', 'Ǫ', 'Ǭ', 'Ǿ', 'Ȍ', 'Ȏ', 'Ȫ', 'Ȭ', 'Ȯ', 'Ȱ', 'О', 'Ӧ', 'Ө', 'Ṍ', 'Ṏ', 'Ṑ', 'Ṓ', 'Ọ', 'Ỏ', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ｏ', '𐐄'],

    'p': ['p', 'ᵖ', 'ᵱ', 'ᵽ', 'ᶈ', 'ṕ', 'ṗ', 'ｐ'],
    'P': ['P', 'Ƥ', 'ᴘ', 'ᴾ', 'Ṕ', 'Ṗ', 'Ᵽ', 'Ｐ'],

    'q': ['q', 'ɋ', 'ʠ', 'ᛩ', 'ｑ'],
    'Q': ['Q', 'Ɋ', 'Ｑ'],

    'r': ['r', 'ŕ', 'ŗ', 'ř', 'ȑ', 'ȓ', 'ɍ', 'ɹ', 'ɻ', 'ʳ', 'ʴ', 'ʵ', 'ͬ', 'ᵣ', 'ᵲ', 'ᶉ', 'ṙ', 'ṛ', 'ṝ', 'ṟ'],
    'R': ['R', 'Ŕ', 'Ŗ', 'Ř', 'Ʀ', 'Ȑ', 'Ȓ', 'Ɍ', 'ʀ', 'ʁ', 'ʶ', 'ᚱ', 'ᴙ', 'ᴚ', 'ᴿ', 'Ṙ', 'Ṛ', 'Ṝ', 'Ṟ', 'Ɽ'],

    's': ['s', 'ś', 'ŝ', 'ş', 'š', 'ș', 'ʂ', 'ᔆ', 'ᶊ', 'ṡ', 'ṣ', 'ṥ', 'ṧ', 'ṩ', 'ｓ'],
    'S': ['S', 'Ś', 'Ŝ', 'Ş', 'Š', 'Ș', 'ȿ', 'ˢ', 'ᵴ', 'Ṡ', 'Ṣ', 'Ṥ', 'Ṧ', 'Ṩ', 'Ｓ'],

    't': ['t', 'ţ', 'ť', 'ŧ', 'ƫ', 'ƭ', 'ț', 'ʇ', 'ͭ', 'ᵀ', 'ᵗ', 'ᵵ', 'ᶵ', 'ṫ', 'ṭ', 'ṯ', 'ṱ', 'ẗ', 'ｔ'],
    'T': ['T', 'Ţ', 'Ť', 'Ƭ', 'Ʈ', 'Ț', 'Ⱦ', 'ᴛ', 'ᵀ', 'Ṫ', 'Ṭ', 'Ṯ', 'Ṱ', 'Ｔ'],

    'u': ['u', 'ù', 'ú', 'û', 'ü', 'ũ', 'ū', 'ŭ', 'ů', 'ű', 'ų', 'ư', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'ȕ', 'ȗ', 'ͧ', 'ߎ', 'ᵘ', 'ᵤ', 'ṳ', 'ṵ', 'ṷ', 'ṹ', 'ṻ', 'ụ', 'ủ', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'ｕ'],
    'U': ['U', 'Ù', 'Ú', 'Û', 'Ü', 'Ũ', 'Ū', 'Ŭ', 'Ů', 'Ű', 'Ų', 'Ư', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ȕ', 'Ȗ', 'Ʉ', 'ᴜ', 'ᵁ', 'ᵾ', 'Ṳ', 'Ṵ', 'Ṷ', 'Ṹ', 'Ṻ', 'Ụ', 'Ủ', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Ｕ'],

    'v': ['v', 'ʋ', 'ͮ', 'ᵛ', 'ᵥ', 'ᶹ', 'ṽ', 'ṿ', 'ⱱ', 'ｖ', 'ⱴ'],
    'V': ['V', 'Ʋ', 'Ʌ', 'ʌ', 'ᴠ', 'ᶌ', 'Ṽ', 'Ṿ', 'Ｖ'],

    'w': ['w', 'ŵ', 'ʷ', 'ᵂ', 'ẁ', 'ẃ', 'ẅ', 'ẇ', 'ẉ', 'ẘ', 'ⱳ', 'ｗ'],
    'W': ['W', 'Ŵ', 'ʍ', 'ᴡ', 'Ẁ', 'Ẃ', 'Ẅ', 'Ẇ', 'Ẉ', 'Ⱳ', 'Ｗ'],

    'x': ['x', '̽', '͓', 'ᶍ', 'ͯ', 'ẋ', 'ẍ', 'ₓ', 'ｘ'],
    'X': ['X', 'ˣ', 'ͯ', 'Ẋ', 'Ẍ', '☒', '✕', '✖', '✗', '✘', 'Ｘ'],

    'y': ['y', 'ý', 'ÿ', 'ŷ', 'ȳ', 'ɏ', 'ʸ', 'ẏ', 'ỳ', 'ỵ', 'ỷ', 'ỹ', 'ｙ'],
    'Y': ['Y', 'Ý', 'Ŷ', 'Ÿ', 'Ƴ', 'ƴ', 'Ȳ', 'Ɏ', 'ʎ', 'ʏ', 'Ẏ', 'Ỳ', 'Ỵ', 'Ỷ', 'Ỹ', 'Ｙ'],

    'z': ['z', 'ź', 'ż', 'ž', 'ƶ', 'ȥ', 'ɀ', 'ʐ', 'ʑ', 'ᙆ', 'ᙇ', 'ᶻ', 'ᶼ', 'ᶽ', 'ẑ', 'ẓ', 'ẕ', 'ⱬ', 'ｚ'],
    'Z': ['Z', 'Ź', 'Ż', 'Ž', 'Ƶ', 'Ȥ', 'ᴢ', 'ᵶ', 'Ẑ', 'Ẓ', 'Ẕ', 'Ⱬ', 'Ｚ']
};

/*
 * Main function of the module which removes all diacritics from the received text
 */
export function diacriticsFn(text: string) {
    var result = [];

    // iterate over all the characters of the received text
    for (var i = 0; i < text.length; i++) {
        var searchChar = text.charAt(i);
        var foundChar = false;

        // iterate over all the diacritics
        for (var key in diacritics) {
            var indexChar = diacritics[key].indexOf(searchChar);

            // check if the current character is a diacritic
            if (indexChar !== -1) {
                // as the character is a diacritic, adds into the result array, the key of the found diacritic
                result.push(key);
                foundChar = true;
                break;
            }
        }

        // check if the character was not found
        if (!foundChar) {
            // as the character was not found, returns it
            result.push(searchChar);
        }
    }

    return result.join("");
};

export class GenerateClient {
    static start() {
        var clientId = ZaloState.start();
        if (!localStorage.getItem('clientId')) {
            localStorage.setItem('clientId', clientId);
        }
        return localStorage.getItem('clientId') ?? ZaloState.start();
    }
}

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: number | string): string {
      if (value === null || value === undefined) {
        return '';
      }
  
      // Convert to a number if it's a string
      const num = typeof value === 'string' ? parseFloat(value) : value;
      if (isNaN(num)) {
        return '';
      }
  
      // Split the number into integer and decimal parts
      const [integerPart, decimalPart] = num.toFixed(3).split('.');
      
      // Format the integer part with dots for thousands
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
      // Return the formatted string
      if (decimalPart && parseFloat(decimalPart) !== 0) {
        // Remove trailing zeros from decimal part
        const trimmedDecimalPart = decimalPart.replace(/0+$/, '');
        return `${formattedIntegerPart},${trimmedDecimalPart}`;
      } else {
        return formattedIntegerPart;
      }
    }
  }