import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from "@angular/platform-browser";

declare var Date: any;

@Pipe({ name: 'TypeVbPipe', pure: false })
export class TypeVbPipe implements PipeTransform {

    transform(type: string | any): string {
        let dow: string = 'Không xác định';
        switch (type) {
            case 1:
                dow = 'Giờ';
                break;
            case 2:
                dow = 'Ngày';
                break;
            case 3:
                dow = 'Tháng';
                break;
            case 4:
                dow = 'Năm';
                break;
        }
        return dow;
    }
}

@Pipe({ name: 'V9DateStr', pure: false })
export class V9DateStrPipe implements PipeTransform {

    transform(type: string | any): string {
        var _date = Date.parse(type).toString('dddd ngày dd/MM/yyyy vào lúc HH:mm');
        return _date;
    }
}

@Pipe({ name: 'V9DurationTime', pure: false })
export class V9DurationTimePipe implements PipeTransform {

    transform(type: number): string {
        var duration = (type / 60 >= 10 ? Math.floor(type / 60).toString() : "0".concat(Math.floor(type / 60).toString()))
            .concat(":")
            .concat(type % 60 >= 10 ? Math.floor(type % 60).toString() : "0".concat(Math.floor(type % 60).toString()))
        return duration;
    }
}

@Pipe({ name: 'V9StrExtension', pure: false })
export class V9StrExtensionPipe implements PipeTransform {

    transform(type: string): string {
        return type.replace('PJSIP/', '').replace('SIP/', '').toString();
    }
}



@Pipe({ name: 'V9Date', pure: false })
export class V9DatePipe implements PipeTransform {

    transform(date: Date, type: string | any): string {
        return Date.parse(date).toString(type);
    }
}

@Pipe({ name: 'V9Hourly', pure: false })
export class V9HourlyPipe implements PipeTransform {

    transform(hour: number): string {
        return Date.today().addHours(hour).toString('HH:mm:ss');
    }
}

@Pipe({
    name: 'V9Safe'
})
export class V9SafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) { }

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}

@Pipe({
    name: 'HTMLSafe'
})
export class V9HTMLSafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) { }

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}
