import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { IAuthApplicationConfig } from "../models/agent.models";
import { IRequestSigninToken, IRequestTokenRf, ITokenRefresh, LoginModel, LoginResult } from "../models/auth.models";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient
    ) { }

    ApplicationConfig(): Observable<IAuthApplicationConfig> {
        return this.http.get<IAuthApplicationConfig>(CommandURL.API_APPLICATION_CONFIG).pipe(catchError(errorMgmt));
    }

    LoginAction(data: LoginModel): Observable<LoginResult> {
        return this.http.post<LoginResult>(CommandURL.API_LOGIN, data).pipe(catchError(errorMgmt));
    }

    RequestLogoutAction(id: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_REQUEST_LOGOUT}/${id}`).pipe(catchError(errorMgmt));
    }

    RequestFotgotPassword(userName: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_REQUEST_FOTGOT}/${userName}`).pipe(catchError(errorMgmt));
    }

    RequestRecoveryPassword(userName: string, code: string, newPwd: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_REQUEST_RECOVERY}/${userName}/${code}/${newPwd}`).pipe(catchError(errorMgmt));
    }

    RequestTokenRefres(data: IRequestTokenRf): Observable<ITokenRefresh> {
        return this.http.post<ITokenRefresh>(CommandURL.API_AGENT_REFRESH_TOKEN, data).pipe(catchError(errorMgmt));
    }

    RequestSigninToken(data: IRequestSigninToken): Observable<any> {
        return this.http.post<any>(CommandURL.API_REQUEST_TOKEN_SIGNIN, data).pipe(catchError(errorMgmt));
    }

}