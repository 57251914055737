export const environment = {
  production: true,
  baseURL: '',
  location: 'api',
  signalURL: 'v9hub',
  signalStatusURL: 'statushub',//'https://v9cc.v9.com.vn/v9hub'
  firebaseConfig: {
    apiKey: "AIzaSyDh6eWtCHAxCWlcV16X_W8qI1_APW0lJss",
    authDomain: "binhanh-115b5.firebaseapp.com",
    projectId: "binhanh-115b5",
    storageBucket: "binhanh-115b5.appspot.com",
    messagingSenderId: "937866647827",
    appId: "1:937866647827:web:9ecad2dc739625053322e6",
    measurementId: "G-ERJDF17CC1",
    vapidKey: "BDJQhUkfBm3A3sEkzNghTcc4esrys7IESdow0wYECoqSDY_CG1q1DP60FDHWcoH9X_kWsE8FbNdYfghiVfhBAKM"
  },
  //Lấy theo oaId
  // OAConfigs:[
  //   {
  //     id: '1958838581480438876',
  //     userName: 'v9zalo',
  //     password: '',
  //     direction: 2,
  //     ip: '2034684325205973192.zcc.openapi.zaloapp.com',
  //     port: '5060',
  //   }
  // ]
};
