import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IExtensionCreate, IResponseExtension, IResponseExtensionRequest } from '../models/extension.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class ExtensionService {


    constructor(
        private http: HttpClient
    ) { }

    onGetAllExtensions(search: ISearch) : Observable<IResponseExtension> {
        return this.http.post<IResponseExtension>(CommandURL.API_INTERNAL_NUMBER_GETALL, search).pipe(catchError(errorMgmt));
    }
    onGetRequestEdit(search: ISearch) : Observable<IResponseExtensionRequest> {
        return this.http.post<IResponseExtensionRequest>(CommandURL.API_INTERNAL_NUMBER_GETREQUESTEDIT, search).pipe(catchError(errorMgmt));
    }
    onGetExtensionsByUser() : Observable<IResponseExtension> {
        return this.http.get<IResponseExtension>(CommandURL.API_INTERNAL_NUMBER_BY_USER).pipe(catchError(errorMgmt));
    }

    onGetAllExtensionsavailable(search: ISearch) : Observable<IResponseExtension> {
        return this.http.post<IResponseExtension>(CommandURL.API_INTERNAL_NUMBER_GETALLavailable, search).pipe(catchError(errorMgmt));
    }

    TestRabbitMQ() : Observable<any> {
        return this.http.get<any>(CommandURL.API_INTERNAL_NUMBER_TestRabbitMQ).pipe(catchError(errorMgmt));
    }

    onCreateExtension(data: IExtensionCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_INTERNAL_NUMBER_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateExtension(data: IExtensionCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_INTERNAL_NUMBER_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteExtension(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_INTERNAL_NUMBER_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

    onGetAllByIsRecordActiveExtensions(search: ISearch) : Observable<any> {
        return this.http.post<any>(CommandURL.API_INTERNAL_NUMBER_GETBYISRECORDACTIVE, search).pipe(catchError(errorMgmt));
    }
    onGetHistoryInternalNumber(search: ISearch) : Observable<any> {
        return this.http.post<any>(CommandURL.API_INTERNAL_NUMBER_HISTORY, search).pipe(catchError(errorMgmt));
    }

    onChangeRecordActiveExtension(id: any) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_INTERNAL_NUMBER_CHANGERECORDACTIVE}/${id}`, null).pipe(catchError(errorMgmt));
    }

    ChangeRecordActiveExtensionAll(active: any) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_INTERNAL_NUMBER_CHANGERECORDACTIVEALL}/${active}`, null).pipe(catchError(errorMgmt));
    }
}
