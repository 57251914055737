import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IAgentDeviceStateChange, IAgentTransferResponse } from '../models/agent.models';
import { ISearch } from '../models/search.models';
import { IAgentCallInQueue, IAgentChangeStatusData, IAgentLogoutEvent, IAgentQueueData, IAgentQueueSearch, IQueueList, IRealTimeChart, ISelectQueue, IStopACDMissCall, ISupBox, ISupChangeStatusAgent, ISupQueues, ISupRemoveAgentQueue } from '../models/supervisor.models';
import { CallDataEvent } from '../models/calldata.models';
import { IQueueChangeIndexResponse } from '../models/workgroup.models';

@Injectable({
    providedIn: 'root'
})
export class SupervisorService {

    public $dataQueueEventChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $dataQueueEventChangeData: BehaviorSubject<ISupQueues[]> = new BehaviorSubject<ISupQueues[]>([]);
    public $supQueueAddDataEventChange: BehaviorSubject<ISupQueues | any> = new BehaviorSubject<ISupQueues | any>(null);
    public $supQueueAddSelectDataEventChange: BehaviorSubject<ISelectQueue | any> = new BehaviorSubject<ISelectQueue | any>(null);
    public $supQueueDeleteDataEventChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public $supQueueUpdateDataEventChange: BehaviorSubject<ISelectQueue | any> = new BehaviorSubject<ISelectQueue | any>(null);
    public $supBoxEventChange: BehaviorSubject<ISupBox | any> = new BehaviorSubject<ISupBox | any>(null);
    public $supBoxIVREventChange: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public $supBoxACDMissEventChange: BehaviorSubject<IStopACDMissCall | any> = new BehaviorSubject<IStopACDMissCall | any>(null);
    public $supRealTimeChartEventChange: BehaviorSubject<IRealTimeChart | any> = new BehaviorSubject<IRealTimeChart | any>(null);
    public $supAgentCallInQueueEvent: BehaviorSubject<IAgentCallInQueue | any> = new BehaviorSubject<IAgentCallInQueue | any>(null);
    public $supAgentCalleEvent: BehaviorSubject<IAgentCallInQueue | any> = new BehaviorSubject<IAgentCallInQueue | any>(null);
    public $supAgentHangUp: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public $supAgentLogin: BehaviorSubject<IAgentQueueData | any> = new BehaviorSubject<IAgentQueueData | any>(null);
    public $supQueueAgentLogin: BehaviorSubject<IAgentQueueData | any> = new BehaviorSubject<IAgentQueueData | any>(null);
    public $supAgentLogout: BehaviorSubject<IAgentLogoutEvent | any> = new BehaviorSubject<IAgentLogoutEvent | any>(null);
    public $supQueueAgentLogout: BehaviorSubject<IAgentLogoutEvent | any> = new BehaviorSubject<IAgentLogoutEvent | any>(null);
    public $supAgentChangeStatus: BehaviorSubject<IAgentChangeStatusData[]> = new BehaviorSubject<IAgentChangeStatusData[]>([]);
    public $supAgentLeaveQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $supAgentJoinQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $supChangeTimeCallRefresh: BehaviorSubject<IAgentQueueData | any> = new BehaviorSubject<IAgentQueueData | any>(null);
    public $dataQueueEventCallData: BehaviorSubject<CallDataEvent | any> = new BehaviorSubject<CallDataEvent | any>(null);
    public $dataHangupEventCallData: BehaviorSubject<CallDataEvent | any> = new BehaviorSubject<CallDataEvent | any>(null);
    public $dataQueueEventCallDataHangup: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $supHangupClear: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public $supDeviceState: BehaviorSubject<IAgentDeviceStateChange | any> = new BehaviorSubject<IAgentDeviceStateChange | any>(null);

    constructor(
        private http: HttpClient
    ) { }

    onGetAllQueues(): Observable<ISupQueues[]> {
        return this.http.get<ISupQueues[]>(CommandURL.API_SUP_ALL_QUEUE).pipe(catchError(errorMgmt));
    }

    onGetSelectQueues(): Observable<ISelectQueue[]> {
        return this.http.get<ISelectQueue[]>(CommandURL.API_SUP_SELECT_QUEUE).pipe(catchError(errorMgmt));
    }

    onGetAllAgents(search: IAgentQueueSearch): Observable<IAgentQueueData[]> {
        return this.http.post<IAgentQueueData[]>(CommandURL.API_SUP_ALL_AGENT, search).pipe(catchError(errorMgmt));
    }

    onGetRealtimeChart(): Observable<IRealTimeChart> {
        return this.http.get<IRealTimeChart>(CommandURL.API_SUP_REAL_TIME).pipe(catchError(errorMgmt));
    }

    onGerStopACDAndMissCall(): Observable<IStopACDMissCall> {
        return this.http.get<IStopACDMissCall>(CommandURL.API_SUP_MISS_CALL).pipe(catchError(errorMgmt));
    }

    onGetTotalCall(): Observable<ISupBox> {
        return this.http.get<ISupBox>(CommandURL.API_SUP_GET_TOTAL_CALL).pipe(catchError(errorMgmt));
    }

    onSupChangeStatusAgentInQueue(data: ISupChangeStatusAgent): Observable<any> {
        return this.http.post<any>(CommandURL.API_SUP_CHANGE_STATUS_AGENT, data).pipe(catchError(errorMgmt));
    }

    onSupRemoveAgentInQueue(data: ISupRemoveAgentQueue): Observable<any> {
        return this.http.post<any>(CommandURL.API_SUP_ADD_REMOVE_QUEUE, data).pipe(catchError(errorMgmt));
    }

    onSupChanspy(exten: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_CHANSPY}/${exten}`).pipe(catchError(errorMgmt));
    }

    onSupPickup(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_SUP_PICKUP, data).pipe(catchError(errorMgmt));
    }

    onSupRemind(exten: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_REMIND}/${exten}`).pipe(catchError(errorMgmt));
    }

    onSupConference(exten: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_CONFERENCE}/${exten}`).pipe(catchError(errorMgmt));
    }

    onSupTransfer(exten: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_CONFERENCE}/${exten}`).pipe(catchError(errorMgmt));
    }

    onSupHangup(channel: string, cusphone: string = '', extension: string = ''): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_HANGUP}?channel=${channel}&cusphone=${cusphone}&extension=${extension}`).pipe(catchError(errorMgmt));
    }

    onSupGetAgentTransfer(exten: string, search: ISearch): Observable<IAgentTransferResponse> {
        return this.http.post<IAgentTransferResponse>(`${CommandURL.API_SUP_GET_AGENT_TF}/${exten}`, search).pipe(catchError(errorMgmt));
    }

    onActionBlindTransfer(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_SUP_BLIND_TRANSFER, data).pipe(catchError(errorMgmt));
    }

    onActionTransfer(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_SUP_TRANSFER, data).pipe(catchError(errorMgmt));
    }

    onQueueChangeIndex(data: any): Observable<IQueueChangeIndexResponse> {
        return this.http.post<IQueueChangeIndexResponse>(CommandURL.API_SUP_CHANGE_ORDER_INDEX, data).pipe(catchError(errorMgmt));
    }

    onHangupClearAgent(agentId: any): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_HANGUP_CLEAR}?agentId=${agentId}`).pipe(catchError(errorMgmt));
    }

    onSupChangeQueueStatus(queueId: string, statusId: string, agentId: string, isPrimary: boolean): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_SUP_CHANGE_AGENT_STATUS}/${queueId}/${statusId}/${agentId}/${isPrimary}`).pipe(catchError(errorMgmt));
    }
}
