import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IHotlineCreate, IRes_Hotline, IResponseHotline } from '../models/hotline.models';
import { ISearch } from '../models/search.models';

@Injectable({
    providedIn: 'root'
})
export class HotlineService {


    constructor(
        private http: HttpClient
    ) { }

    onGetAllHotline(search: ISearch) : Observable<IResponseHotline> {
        return this.http.post<IResponseHotline>(CommandURL.API_HOTLINE_GETALL, search).pipe(catchError(errorMgmt));
    }
    onGetAllHotlineByUser() : Observable<IRes_Hotline> {
        return this.http.get<IRes_Hotline>(CommandURL.API_HOTLINE_BY_USER).pipe(catchError(errorMgmt));
    }

    onCreateHotline(data: IHotlineCreate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_HOTLINE_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateHotline(data: IHotlineCreate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_HOTLINE_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteHotline(id: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_HOTLINE_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

    // onUpdateHotline(data: IHotlineUpdate) : Observable<any> {
    //     return this.http.post<any>(CommandURL.API_HOTLINE_UPDATE, data).pipe(catchError(errorMgmt));
    // }

}
