import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { _Constants } from './constant';
import { LANGUAGE } from './enum';

export class BaseModule {
  constructor(
    router: Router,
    private translate: TranslateService
  ) {
    this.setDefaultLanguge();

    /* use language on routing */
    router.events.subscribe(events => {
      if (events instanceof NavigationStart || events instanceof NavigationEnd) {
        this.translate.use(this.getLanguage());
      }
    });

    /* update language */
    translate.onLangChange.subscribe(res => {
      this.translate.currentLang = res.lang;
    });
  }

  setDefaultLanguge() {
    this.translate.setDefaultLang(LANGUAGE.DEFAULT);
    this.translate.use(this.getLanguage());
  }

  getLanguage() {
    const currentLanguage = localStorage.getItem(_Constants.storage.language);
    return !!currentLanguage ? currentLanguage : LANGUAGE.DEFAULT;
  }
}
