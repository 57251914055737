import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Select2OptionData } from "ng-select2";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { CommandURL } from "src/app/utils/constant";
import { errorMgmt } from "src/app/utils/helpers";
import { IAgentActivityStatusLog, IAgentCalendarResponse, IAgentContactsResponse, IAgentCurrentCall, IAgentDeviceStateChange, IAgentEventCallWithTicket, IAgentEventHangup, IAgentEventRingCall, IAgentLockPhone, IAgentMissCallResponse, IAgentQueueList, IAgentQueueOutBound, IAgentTransferResponse, IAuthApplicationConfig, ICalendarCreate, IExtensionAgent, IExtensionDataStorage, IGeneralNotice, IGeneralNotices, IObjectCallOut, IObjectCallOutOMS, IResponseAgent, IResponseAgentCallHistory } from "../models/agent.models";
import { IUserData } from "../models/auth.models";
import { ISearch } from "../models/search.models";
import { IAgentQueueData, IQueueList } from "../models/supervisor.models";

@Injectable({
    providedIn: 'root'
})
export class AgentService {


    public requestDataAgent$ = new BehaviorSubject<IResponseAgent>({} as IResponseAgent);
    public requestDataExtAgent$ = new BehaviorSubject<IExtensionDataStorage>({} as IExtensionDataStorage);
    public requestUserData$ = new BehaviorSubject<IUserData>({} as IUserData);
    public requestDataCallHistory$ = new BehaviorSubject<IResponseAgentCallHistory>({} as IResponseAgentCallHistory);
    public $supChangeStatusAgentInQueue: BehaviorSubject<IQueueList | any> = new BehaviorSubject<IQueueList | any>(null);
    public $agentRingingEvent: BehaviorSubject<IAgentEventRingCall | any> = new BehaviorSubject<IAgentEventRingCall | any>(null);
    public $agentRingingEventSub: BehaviorSubject<IAgentEventRingCall | any> = new BehaviorSubject<IAgentEventRingCall | any>(null);
    public $agentCallingEvent: BehaviorSubject<IAgentEventRingCall | any> = new BehaviorSubject<IAgentEventRingCall | any>(null);
    public $agentCallingEventSub: BehaviorSubject<IAgentEventRingCall | any> = new BehaviorSubject<IAgentEventRingCall | any>(null);
    public $agentCallingTicketEvent: BehaviorSubject<IAgentEventCallWithTicket | any> = new BehaviorSubject<IAgentEventCallWithTicket | any>(null);
    public $agentCallingTicketEventSub: BehaviorSubject<IAgentEventCallWithTicket | any> = new BehaviorSubject<IAgentEventCallWithTicket | any>(null);
    public $agentHangupEvent: BehaviorSubject<IAgentEventHangup | any> = new BehaviorSubject<IAgentEventHangup | any>(null);
    public $agentAppConfig: BehaviorSubject<IAuthApplicationConfig | any> = new BehaviorSubject<IAuthApplicationConfig | any>(null);
    public $agentAppConfigActivity: BehaviorSubject<IAuthApplicationConfig | any> = new BehaviorSubject<IAuthApplicationConfig | any>(null);
    public $agentQueueOBExten: BehaviorSubject<Select2OptionData[]> = new BehaviorSubject<Select2OptionData[]>([]);
    public $agentQueueOBExten2: BehaviorSubject<Select2OptionData[]> = new BehaviorSubject<Select2OptionData[]>([]);
    public $agentMainOpenTicket: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentSubOpenTicket: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentMainDataTicket: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentSubDataTicket: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentSendOpenTicket: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentMissCallEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentMissCallEventDeleted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentCheckLockPhone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentCallCheck: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentNotifyRemoveQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentNotifyUpdateQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentCallOut: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentSupRemoveQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $AgentMissCallStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public $agentDeviceState: BehaviorSubject<IAgentDeviceStateChange | any> = new BehaviorSubject<IAgentDeviceStateChange | any>(null);
    public $agentDeviceStatus: BehaviorSubject<IAgentQueueData[] | any[]> = new BehaviorSubject<IAgentQueueData[] | any[]>([]);

    public $agentApiReceiveMessageRemoveQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null); // test
    public $agentApiReceiveMessageUpdateQueue: BehaviorSubject<any> = new BehaviorSubject<any>(null); // test

    constructor(
        private http: HttpClient,
        private dbService: NgxIndexedDBService
    ) { }

    onAgentLogout(): Observable<any> {
        return this.http.get<any>(CommandURL.API_AGENT_LOGOUT).pipe(catchError(errorMgmt));
    }

    onAgentGetAllAccount(search: ISearch): Observable<IResponseAgent> {
        return this.http.post<IResponseAgent>(CommandURL.API_AGENT_GET_ALL, search).pipe(catchError(errorMgmt));
    }

    onAgentGetActivityLog(): Observable<IAgentActivityStatusLog[]> {
        return this.http.get<IAgentActivityStatusLog[]>(CommandURL.API_AGENT_ACTIVITY_LOG).pipe(catchError(errorMgmt));
    }

    onAgentGetCallHistory(search: ISearch): Observable<IResponseAgentCallHistory> {
        return this.http.post<IResponseAgentCallHistory>(CommandURL.API_AGENT_CALL_HISTORY, search).pipe(catchError(errorMgmt));
    }

    onAgentGetQueueList(isPrimary: boolean): Observable<IAgentQueueList[]> {
        return this.http.get<IAgentQueueList[]>(`${CommandURL.API_AGENT_QUEUE_AGENT}/${isPrimary}`).pipe(catchError(errorMgmt));
    }

    onAgentQueueOutBound(): Observable<IAgentQueueOutBound[]> {
        return this.http.get<IAgentQueueOutBound[]>(CommandURL.API_AGENT_LQUEUE_TO_OB).pipe(catchError(errorMgmt));
    }

    onAgentLeaveQueue(queueId: string, isPrimary: boolean): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_LEAVE_QUEUE}/${queueId}/${isPrimary}`).pipe(catchError(errorMgmt));
    }

    onAgentJoinQueue(queueId: string, isPrimary: boolean): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_JOIN_QUEUE}/${queueId}/${isPrimary}`).pipe(catchError(errorMgmt));
    }

    onChangeAllStatus(statusId: string, isPrimary: boolean): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_CS_ALL_QUEUE}/${statusId}/${isPrimary}`).pipe(catchError(errorMgmt));
    }

    onChangeQueueStatus(queueId: string, statusId: string, isPrimary: boolean): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_CHANGE_STATUS}/${queueId}/${statusId}/${isPrimary}`).pipe(catchError(errorMgmt));
    }

    onCalendarGetAll(data: ISearch): Observable<IAgentCalendarResponse> {
        return this.http.post<IAgentCalendarResponse>(CommandURL.API_AGENT_CALENDAR_GETALL, data).pipe(catchError(errorMgmt));
    }

    onCalendarCreate(data: ICalendarCreate): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_CALENDAR_CREATE, data).pipe(catchError(errorMgmt));
    }

    onCalendarUpdate(data: ICalendarCreate): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_CALENDAR_UPDATE, data).pipe(catchError(errorMgmt));
    }

    onCalendarDelete(callId: string): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_AGENT_CALENDAR_DELETE}/${callId}`).pipe(catchError(errorMgmt));
    }

    onGetRecordingFile(callId: string): Observable<Blob> {
        return this.http.get<Blob>(`${CommandURL.API_AGENT_RECORDING_FILE}/${callId}`, { responseType: 'blob' as 'json' }).pipe(catchError(errorMgmt));
    }

    onCallOutBound(data: IObjectCallOut): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_OUTBOUND, data).pipe(catchError(errorMgmt));
    }
    onCallOutBoundOMS(data: IObjectCallOutOMS): Observable<any> {
      return this.http.post<any>(CommandURL.API_AGENT_OUTBOUND_OMS, data).pipe(catchError(errorMgmt));
  }

    onGetCurrentCall(agentId: string): Observable<IAgentCurrentCall> {
        return this.http.get<IAgentCurrentCall>(`${CommandURL.API_AGENT_CURRENT_CALL}/${agentId}`).pipe(catchError(errorMgmt));
    }

    onGetAllContacts(data: ISearch): Observable<IAgentContactsResponse> {
        return this.http.post<IAgentContactsResponse>(CommandURL.API_AGENT_CONTACT_GETALL, data).pipe(catchError(errorMgmt));
    }

    onCallMute(channel: string, isPrimary: boolean): Observable<any> {
        const data = {
            channel: channel,
            isPrimary: isPrimary
        }
        return this.http.post<any>(CommandURL.API_AGENT_CALL_MUTE, data).pipe(catchError(errorMgmt));
    }

    onCallUnMute(channel: string, isPrimary: boolean): Observable<any> {
        const data = {
            channel: channel,
            isPrimary: isPrimary
        }
        return this.http.post<any>(CommandURL.API_AGENT_CALL_UNMUTE, data).pipe(catchError(errorMgmt));
    }

    onAgentHangup(channel: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_HANGUP}?channel=${channel}`).pipe(catchError(errorMgmt));
    }

    onGetAgentsToTrans(search: ISearch): Observable<IAgentTransferResponse> {
        return this.http.post<IAgentTransferResponse>(CommandURL.API_AGENT_GET_AGENT_TF, search).pipe(catchError(errorMgmt));
    }

    onActionBlindTransfer(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_BLIND_TRANSFER, data).pipe(catchError(errorMgmt));
    }

    onActionTransfer(data: any): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_TRANSFER, data).pipe(catchError(errorMgmt));
    }

    onGetAgentMissCall(search: ISearch): Observable<IAgentMissCallResponse> {
        return this.http.post<IAgentMissCallResponse>(CommandURL.API_AGENT_NOTI_MISS_CALL, search).pipe(catchError(errorMgmt));
    }

    onUpdateViewAgentMissCall(ids: string[] | any[]): Observable<any> {
        return this.http.post<any>(CommandURL.API_AGENT_NOTI_VIEW_MISS_CALL, ids).pipe(catchError(errorMgmt));
    }

    onGetHistoryCallById(ids: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_GET_CALL_HISTORY}/${ids}`).pipe(catchError(errorMgmt));
    }

    onUpdateStatusMissCall(id: string): Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_UPDATE_STATUS_MISS_CALL}/${id}`).pipe(catchError(errorMgmt));
    }

    onGetNotifyAgentMissCallId(ids: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_NOTI_VIEW_MISS_CALL_ID}/${ids}`).pipe(catchError(errorMgmt));
    }
    onGetNotifyAgentMissCallPhone(ids: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_NOTI_VIEW_MISS_CALL_PHONE}/${ids}`).pipe(catchError(errorMgmt));
    }
    onGetNotifyAgentMissCallPhoneReprot(ids: string): Observable<any> {
        return this.http.get<any>(`${CommandURL.API_AGENT_NOTI_VIEW_MISS_CALL_PHONE_REPORT}/${ids}`).pipe(catchError(errorMgmt));
    }
    //   onGetNotifyAgentMissCallId(search: ISearch) : Observable<IAgentMissCallResponse> {
    //     return this.http.post<IAgentMissCallResponse>(CommandURL.API_AGENT_NOTI_VIEW_MISS_CALL_ID, search).pipe(catchError(errorMgmt));
    // }

    onCheckExtension(): Observable<any> {
        return this.http.get<any>(CommandURL.API_AGENT_CHECK_ENDPOINT).pipe(catchError(errorMgmt));
    }

    onGetAgentLockPhone(search: ISearch): Observable<IAgentLockPhone> {
      return this.http.post<IAgentLockPhone>(CommandURL.API_AGENT_NOTI_LOCK_PHONE, search).pipe(catchError(errorMgmt));
  }
  onGetGeneralNotice(search: ISearch): Observable<IGeneralNotice> {
    return this.http.post<IGeneralNotice>(CommandURL.API_AGENT_NOTI_GENERALNOTICES, search).pipe(catchError(errorMgmt));
  }

  onUpdateAgentLockPhone(id: string): Observable<any> {
    return this.http.get<any>(`${CommandURL.API_UPDATE_STATUS_LOCKPHONE}/${id}`).pipe(catchError(errorMgmt));

  }

  onApiReceiveMessage(): Observable<any> {
    return this.http.post<any>(CommandURL.API_RECEIVE_MESSAGE, null).pipe(catchError(errorMgmt));
  }
}
