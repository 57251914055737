import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
selector: 'app-dialog-notice',
templateUrl: './dialog-notice.component.html',
styleUrls: ['./dialog-notice.component.css']
})
export class DialogNoticeComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogNoticeComponent>,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }
    ngOnInit(): void {
    }

    getTranlateService() {
        return this.translateService;
    }

    onCancel() {
        this.dialogRef.close(false);
    }
}
