import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { IHotlineCreate, IResponseHotline } from '../models/hotline.models';
import { ISearch } from '../models/search.models';
import { ITagsCreateUpdate, ITagsData } from '../models/tags.models';

@Injectable({
    providedIn: 'root'
})
export class TagsService {

    
    constructor(
        private http: HttpClient
    ) { }
    
    onGetAllTags() : Observable<ITagsData[]> {
        return this.http.get<ITagsData[]>(CommandURL.API_TAGS_GETALL).pipe(catchError(errorMgmt));
    }

    onCreateTags(data: ITagsCreateUpdate) : Observable<any> {
        return this.http.post<any>(CommandURL.API_TAGS_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateTags(data: ITagsCreateUpdate) : Observable<any> {
        return this.http.put<any>(`${CommandURL.API_TAGS_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteTags(tagsId: string) : Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_TAGS_DELETE}/${tagsId}`).pipe(catchError(errorMgmt));
    }
}
