import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommandURL } from 'src/app/utils/constant';
import { errorMgmt } from 'src/app/utils/helpers';
import { ISearch } from '../models/search.models';
import { IQueueReponse, IWorkGroupModel } from '../models/workgroup.models';

@Injectable({
    providedIn: 'root'
})
export class QueueService {

    constructor(
        private http: HttpClient
    ) { }

    onGetAllQueues(search: ISearch): Observable<IQueueReponse> {
        return this.http.post<IQueueReponse>(CommandURL.API_QUEUE_GETALL, search).pipe(catchError(errorMgmt));
    }

    onCreateQueue(data: IWorkGroupModel):Observable<any> {
        return this.http.post<any>(CommandURL.API_QUEUE_CREATE, data).pipe(catchError(errorMgmt));
    }

    onUpdateQueue(data: IWorkGroupModel):Observable<any> {
        return this.http.put<any>(`${CommandURL.API_QUEUE_UPDATE}/${data.id}`, data).pipe(catchError(errorMgmt));
    }

    onDeleteQueue(id: string):Observable<any> {
        return this.http.delete<any>(`${CommandURL.API_QUEUE_DELETE}/${id}`).pipe(catchError(errorMgmt));
    }

}
