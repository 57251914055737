import { ChangeDetectorRef, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { IHttpConnectionOptions } from "@microsoft/signalr";
import { Observable } from "rxjs";
import { EventService } from "src/app/shared/services/event.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { SupervisorService } from "src/app/shared/services/supervisor.service";
import { BaseURL, CookieName, _Constants } from "src/app/utils/constant";
import { LoadListMenu, LoadListMenuSupervisorPage, MenuItems } from "src/app/utils/menu";
import * as signalR from '@microsoft/signalr';
import { IAuthApplicationConfig } from "src/app/shared/models/agent.models";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({ providedIn: 'root' })
export class SupervisorRouteResolve implements Resolve<Observable<any>> {

    listMenus: MenuItems[] = LoadListMenuSupervisorPage();

    constructor(
        private cookieService: StorageService,
        private _event: EventService,
        private _sup: SupervisorService,
        private _auth: AuthService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> | any {

        var permissions = this.cookieService.userData?.permissions;

        if (!permissions.some((x: any) => x.includes(route.data?.permission)))
            this.router.navigate(['/']);

        this._event.requestMenuData$.next([]);

        var agent = permissions.includes('ZALO_AGENT_ALLOW') ? 'AGENT' : 'DISCONNECT';
        var Supagent = permissions.includes('SUP_ZALO_AGENT_ALLOW') ? 'AGENT' : 'DISCONNECT';



        this._auth.ApplicationConfig().subscribe((dt) => {
            var _appConfig = dt as IAuthApplicationConfig;
            this.listMenus.map(r => {
                if (r.name.toLowerCase().includes('ticket')) r.isUse = _appConfig.useTicket;
                return r;
            });
            var listMenus = this.listMenus.filter(x => x.isUse && (permissions.some((p: any) => x.permission == p) || x.permission.includes('DASHBOARD') || x.permission.includes(agent) || x.permission.includes(Supagent)));
            this._event.requestMenuData$.next(listMenus);
        });
    }
}
